import React, { useState } from 'react';
import { HeaderBar, Sidebar, Breadcrumbs } from '../../components';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { AlertTemplate } from '../alert-template';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../error-fallback';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useSidebarStyles, useStyles } from './app-layout-styles';
import { UtilityContext } from 'modeling-tool';
import { useStyleProps } from '../../hooks';
import { StyleContext } from 'modeling-tool';
import { useLocation } from 'react-router-dom';
import { mergeClasses } from '@fluentui/react-components';

interface AppLayoutProps {
  children: JSX.Element[];
}

type ContentItems = { list: JSX.Element[] };

const AppLayout = (props: AppLayoutProps) => {
  const appInsights = useAppInsightsContext();

  const [utilitySideBar, setUtilitySideBar] = useState<ContentItems>({ list: [] });
  const [breadcrumbButtons, setBreadcrumbButtons] = useState<ContentItems>({ list: [] });
  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

  const classes = useStyles();
  const sidebarStyles = useSidebarStyles();
  const location = useLocation();

  const utilityValue = {
    utilitySideBar: utilitySideBar,
    setUtilitySideBar: setUtilitySideBar,
    breadcrumbButtons: breadcrumbButtons,
    setBreadcrumbButtons: setBreadcrumbButtons,
    fullScreenMode: fullScreenMode,
    setFullScreenMode: setFullScreenMode,
  };

  const errorHandler = (error: Error) => {
    try {
      appInsights.trackException({ exception: error });
    } catch (e) {
      console.warn('Azure Application Insights is not correctly initialized: ' + e);
    }

    console.log(error.stack);
  };

  return (
    <UtilityContext.Provider value={utilityValue}>
      <StyleContext.Provider value={{ useStyleProps }}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_CENTER}
          transition={transitions.FADE}
          timeout={5000}
          offset={'10px'}
        >
          <div className={classes.appLayout}>
            <div className={classes.topSection}>
              <HeaderBar />
            </div>
            <div className={classes.mainBackground}>
              <div style={{ flexShrink: 0 }}>
                <Sidebar />
              </div>
              <div className={fullScreenMode ? classes.fullScreenMainLayout : classes.mainLayout}>
                <div className={!!utilitySideBar.list.length ? classes.containerWithSidebarOpen : classes.container}>
                  <div className={classes.breadCrumbWrapper}>
                    <div className={classes.breadCrumbStack}>
                      <Breadcrumbs />
                    </div>
                    <div className={classes.buttonSection}>{breadcrumbButtons && <>{breadcrumbButtons.list}</>}</div>
                  </div>
                  <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} resetKeys={[location]}>
                    <div className={classes.componentContainer}>{props.children}</div>
                  </ErrorBoundary>
                </div>
                {utilitySideBar && (
                  <div
                    className={mergeClasses(
                      sidebarStyles,
                      fullScreenMode ? classes.fullScreenStyles : classes.utilitySideBar,
                    )}
                  >
                    {utilitySideBar.list}
                  </div>
                )}
              </div>
            </div>
          </div>
        </AlertProvider>
      </StyleContext.Provider>
    </UtilityContext.Provider>
  );
};

export default AppLayout;
