import { Person } from '@microsoft/mgt-react';
import React, { useState, FormEvent } from 'react';
import { GetAppLightThemeFluentV9, __ } from 'modeling-tool';
import { PersonDisplayName } from '../../../components';
import { Modal } from 'modeling-tool';
import { ProcessInstance, UserComment, RatingBy } from '../../../ts/interfaces';
import {
  PrimaryButton,
  List,
  Stack,
  DocumentCard,
  Rating,
  RatingSize,
  Checkbox,
  DirectionalHint,
  TooltipHost,
  DefaultButton,
  IStackTokens,
  Text,
  Icon,
} from '@fluentui/react';
import { CommentsForm } from './comments-form';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { addCommentAction, updateCommentRatingAction } from '../../../store/actions/comment-actions';
import { getStyles, ratingStarFront } from './comments-styles';
import { useStyleProps } from '../../../hooks';
import { FluentProvider, Persona } from '@fluentui/react-components';

interface CommentsProps extends PropsFromRedux {
  selectedProcessInstance?: ProcessInstance;
  closeComments: () => void;
}

const Comments = (props: CommentsProps) => {
  const {
    selectedProcessInstance,
    UserReducer: { username },
    UserCommentReducer: { comments },
    addCommentAction,
    updateCommentRatingAction,
    closeComments,
  } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRating, setSelectedRating] = useState<number>(0);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<UserComment>();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [isRated, setIsRated] = React.useState<any>(['', false]);

  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 5,
  };

  const showProcessModal = () => {
    setModalVisible(true);
  };

  const onChange = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setIsChecked(!!checked);
    checked ? setSelectedOption(true) : setSelectedOption(false);
  }, []);

  const onChangeRating = (event: FormEvent<HTMLElement>, rating?: number): void => {
    setSelectedRating(Number(rating));
  };

  const updateComment = (): UserComment => {
    const ratings = selectedComment?.ratingBy || [];
    const newRating = {
      rating: selectedRating,
      isAnonymous: selectedOption,
    };
    if (selectedComment && selectedComment.userRating) {
      const existingRating = ratings?.find((rating) => rating.id == selectedComment.userRating?.id);
      if (existingRating) {
        existingRating.rating = selectedRating;
        existingRating.isAnonymous = selectedOption;
      } else {
        ratings?.push(newRating);
      }
    } else {
      ratings?.push(newRating);
    }

    const comment: UserComment = {
      id: selectedComment?.id,
      averageRating: selectedRating,
      instance: selectedProcessInstance?.resourceUri,
      ratingBy: ratings,
      isUrgent: false,
    };

    return comment;
  };

  const onSave = () => {
    const comment = updateComment();
    updateCommentRatingAction(comment);
    setPopupVisible(false);
    setSelectedOption(false);
    setIsChecked(false);
    setIsRated([comment.id, true]);
  };

  const onCancel = () => {
    setPopupVisible(false);
    setSelectedOption(false);
    setIsChecked(false);
    setIsRated(['', false]);
  };

  const onRenderPersons = (comment: UserComment) => {
    return comment.ratingBy && comment.ratingBy.length ? <div>{renderPersonsWithPopover(comment)}</div> : <div>0</div>;
  };

  const getPopoverContentAnonyme = (userAnonyme: number) => {
    return userAnonyme > 0 ? (
      <Stack wrap horizontal>
        {/* FluentProvider is necessary here to apply the Fluent2 theme.
            Without FluentProvider, the Fluent2 styles won't be applied to the components. */}
        <FluentProvider theme={GetAppLightThemeFluentV9()}>
          <Persona size="medium" primaryText={__('anonyme')} textAlignment="center" />
        </FluentProvider>
        <div>X {userAnonyme}</div>
      </Stack>
    ) : (
      <></>
    );
  };
  const getPopoverContentWithName = (comment: UserComment) => {
    return (
      comment.ratingBy &&
      comment.ratingBy.map((item: RatingBy, i: number) =>
        item.isAnonymous === false ? <Person key={i} userId={item.username} /> : <></>,
      )
    );
  };

  const getPopoverContent = (comment: UserComment, userAnonyme: number) => {
    return (
      <Stack>
        {getPopoverContentAnonyme(userAnonyme)}
        {getPopoverContentWithName(comment)}
      </Stack>
    );
  };

  const renderPersonsWithPopover = (comment: UserComment) => {
    const userWithName = comment.ratingBy
      ? comment.ratingBy.filter((user: RatingBy) => user.isAnonymous === false)?.length
      : 0;
    const userAnonyme = comment.ratingBy
      ? comment.ratingBy.filter((user: RatingBy) => user.isAnonymous === true)?.length
      : 0;
    const popoverContent =
      comment.ratingBy && comment.ratingBy.length > 0 ? getPopoverContent(comment, userAnonyme) : <></>;

    return [
      popoverContent ? (
        <Stack>
          <TooltipHost content={popoverContent} directionalHint={DirectionalHint.bottomCenter}>
            <div>{userWithName + userAnonyme}</div>
          </TooltipHost>
        </Stack>
      ) : null,
    ];
  };

  function calculateMedian(values: number[]) {
    if (!values.length) return 0;

    const half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  const renderMedianRating = (comment: UserComment) => {
    let ratings: number[] = [];
    comment?.ratingBy && comment?.ratingBy.length
      ? comment?.ratingBy.map((item: any) => {
          ratings.push(item.rating);
        })
      : (ratings = []);
    const median = calculateMedian(ratings);
    return median;
  };

  const renderCommentList = () => {
    return (
      <div className={styles.wrapper}>
        <List
          items={comments}
          onRenderCell={(item: any) => {
            if (item.isUrgent) {
              return <></>;
            }
            return (
              <>
                <Stack>
                  <Stack wrap horizontal>
                    <Stack.Item grow={10} className={styles.comment}>
                      {item.isAnonymous === false && (
                        <Stack wrap horizontal tokens={stackTokens} className={styles.personStyle}>
                          <Person userId={item.createdBy}></Person>
                          <Person userId={item.createdBy}>
                            <PersonDisplayName />
                          </Person>
                        </Stack>
                      )}
                      {item.isAnonymous === true && (
                        <div className={styles.personStyle}>
                          <Persona size="medium" primaryText={__('anonyme')} textAlignment="center" />
                        </div>
                      )}
                    </Stack.Item>
                  </Stack>
                  <Stack className={styles.description}>{item.description}</Stack>
                  <Stack wrap horizontal>
                    <Rating
                      max={5}
                      styles={ratingStarFront}
                      className={styles.rating}
                      allowZeroStars={true}
                      size={RatingSize.Large}
                      defaultRating={renderMedianRating(item)}
                      onChange={onChangeRating}
                      ariaLabel="Large stars"
                      ariaLabelFormat="{0} of {1} stars"
                    />
                    <div>{item.ratingBy && onRenderPersons(item)}</div>
                  </Stack>

                  <DefaultButton
                    className={isRated[1] && isRated[0] === item.id ? styles.disabledButtonSend : styles.buttonSend}
                    onClick={() => OnSaveRatingButtonClick(item)}
                    disabled={isRated[1] && isRated[0] === item.id ? true : false}
                    text={isRated[1] && isRated[0] === item.id ? __('thank you for your rating') : __('send rating')}
                  />
                </Stack>
              </>
            );
          }}
        />
      </div>
    );
  };
  const OnSaveRatingButtonClick = (comment: UserComment) => {
    setSelectedComment(comment);
    setPopupVisible(true);
    setIsChecked(false);
  };
  if (!selectedProcessInstance) {
    return <></>;
  }
  return (
    <div className={styles.commentsContainer}>
      {selectedProcessInstance && (
        <>
          <DocumentCard className={styles.globalCardStyles}>
            <Stack>
              <div className={styles.titleStyles}>
                <Text className={styles.titleText} variant={'large'}>
                  {__('comments')}
                </Text>
                <Icon iconName="CaretLeftSolid8" className={styles.dismissCaret} onClick={closeComments} />
              </div>

              <PrimaryButton
                className={styles.newCommentButton}
                onClick={() => showProcessModal()}
                allowDisabledFocus
                iconProps={{ iconName: 'Add' }}
              >
                {__('create a new comment')}
              </PrimaryButton>
              {comments && renderCommentList()}
            </Stack>
          </DocumentCard>
          {modalVisible && (
            <CommentsForm
              visible={modalVisible}
              setVisible={setModalVisible}
              add={addCommentAction}
              selectedProcessInstance={selectedProcessInstance}
              username={username}
            />
          )}
          {popupVisible && (
            <Modal title={__('select an option')} isModalOpen={popupVisible} onSave={onSave} onCancel={onCancel}>
              <Checkbox label={__('anonyme')} checked={isChecked} onChange={onChange} />
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserCommentReducer, UserReducer, ProcessReducer }: RootState) => ({
  UserCommentReducer,
  UserReducer,
  ProcessReducer,
});
const connector = connect(mapStateToProps, {
  addCommentAction,
  updateCommentRatingAction,
});
export default connector(Comments);
