import { Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { __ } from 'modeling-tool';
import { cardStyles } from './tasks-styles';
import { DocumentCard, ScrollablePane, useTheme } from '@fluentui/react';
import { GLOBALENUMS } from 'modeling-tool';
import TaskCard from './task-card/task-card';
import { ProcessInstance } from '../../ts/interfaces/models';
import { checkPermission } from '../../config/permission-utils';
import { useNavigate } from 'react-router';

interface TasksCardProps extends PropsFromRedux {
  approvalState?: GLOBALENUMS.APPROVALSTATUS;
  processStates?: GLOBALENUMS.PROCESSSTATUS[];
  title: string;
}

const TasksCardArea = (props: TasksCardProps) => {
  const {
    TaskReducer: { tasks },
    ProcessInstanceReducer: { instances },
    UserReducer: { username, permissions },
    approvalState,
    processStates,
    title,
  } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = cardStyles(theme);

  const clickTask = (item: ProcessInstance) => {
    if (
      !(
        checkPermission(permissions, GLOBALENUMS.PERMISSIONS.view_task_detail) ||
        checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_approvedby)
      )
    ) {
      return;
    }
    if (approvalState) {
      navigate(`/tasks/approval/${item?.id}`);
    } else {
      if (
        [
          GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING,
          GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED,
        ].includes(item?.state?.type)
      ) {
        navigate(`/process-management/processes/${item?.sourceProcess.id}/modeling-tool/${item?.id}`);
      } else {
        navigate(`/process-management/processes/${item?.sourceProcess.id}`);
      }
    }
  };

  const onRenderItem = (item?: ProcessInstance) => {
    if (!item) return <></>;
    if (approvalState) {
      const taskToRender = item?.approvedBy?.find((approvedByUser) => {
        return approvedByUser.user?.username === username && approvedByUser.status === approvalState;
      });
      if (approvalState === GLOBALENUMS.APPROVALSTATUS.REJECTED) {
        const isRejected = item?.approvedBy?.find((approvedByUser) => {
          return (
            approvedByUser.status === GLOBALENUMS.APPROVALSTATUS.REJECTED && approvedByUser.user?.username === username
          );
        });
        const isInitialApprover = item?.approvedBy?.find((approvedByUser) => {
          return approvedByUser.isInitialApprover && approvedByUser.user?.username === username;
        });
        return !!isRejected || (!!isInitialApprover && item?.isRejected) ? (
          <TaskCard item={item} clickTask={clickTask} />
        ) : null;
      }
      return taskToRender && !item?.isRejected ? <>{<TaskCard item={item} clickTask={clickTask} />}</> : null;
    } else {
      return processStates?.includes(item?.state.type) ? (
        <TaskCard item={item} clickTask={clickTask} processRecording={true} />
      ) : null;
    }
  };

  return (
    <DocumentCard className={styles.globalCardStyles}>
      <div className={styles.globalstatus}>{__(title)}</div>
      <div className={styles.wrapper}>
        <ScrollablePane scrollContainerFocus={true} scrollContainerAriaLabel="scroll tasks list">
          <>
            {(approvalState ? instances : tasks).map((item) => {
              return <Fragment key={item.id}>{onRenderItem(item)}</Fragment>;
            })}
          </>
        </ScrollablePane>
      </div>
    </DocumentCard>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ TaskReducer, ProcessInstanceReducer, UserReducer }: RootState) => ({
  TaskReducer,
  ProcessInstanceReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {});
export default connector(TasksCardArea);
