import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    employeeListContainer: {
      paddingLeft: '15px',
      height: 'calc(100vh - 165px)',
    },

    nameTable: {
      color: 'rgb(92, 46, 145)',
    },
    customPersonName: {
      display: 'flex',
      alignItems: 'baseline',
      fontWeight: '600',
      fontSize: '14px',
      color: theme.palette.themePrimary,
    },
  });
});
