import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  modelerWrapper: {
    '& #process-modeler': {
      height: '60vh',
      breakAfter: 'page',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modelerWrapperFullscreen: {
    '& #process-modeler': {
      height: '100vh',
      breakAfter: 'page',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modelerWrapperAiPanelOpen: {
    minHeight: `${window.innerHeight * 0.6}px`,
  },
  modelerWrapperAiPanelClosed: {
    minHeight: '62vh',
  },

  detailsWrapper: {
    height: 'calc(100vh - 104px)',
    display: 'block',
    position: 'relative',
  },
  centerSection: {
    width: '1000px',
  },
  centerBtnSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerTextSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  centerBtn: {
    width: '244px',
    ...shorthands.margin(0, '4px', 0),
    ...shorthands.border('none'),
  },
  sendBtn: {
    backgroundColor: tokens.colorBrandBackground2Hover,
    color: tokens.colorNeutralForeground2BrandHover,
  },
  semiBoldText: {
    fontWeight: 600,
  },
  fontSizeSmall: {
    fontSize: '16px',
    opacity: 0.6,
    ...shorthands.padding(0, 0, '12px', 0),
  },
  fontSizeLarge: {
    fontSize: '26px',
    ...shorthands.padding('12px', 0, '12px'),
  },
});
