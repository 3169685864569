import { useStyles } from './group-membership-header-styles';
import { __ } from 'modeling-tool';
import { useNavigate } from 'react-router';
import { PaymentRegular } from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';

const GroupMemberShipHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.mainDivWrapper}>
        <div className={classes.assignLicensesDiv}>
          <p className={classes.assignLicensesP1}>{__('assign licences')}</p>
          <a href="https://www.aiio.de/preise" target="_blank" className={classes.assignLicensesP2}>
            {__('learn more about licence types')}
          </a>
        </div>
        <div className={classes.aiioShopDiv}>
          <Button
            appearance="primary"
            onClick={() => {
              navigate(`/settings/checkout`);
            }}
            icon={<PaymentRegular />}
          >
            {__('Manage licences')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default GroupMemberShipHeader;
