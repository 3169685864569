import { connect, ConnectedProps } from 'react-redux';
import { Label } from '@fluentui/react';
import { RootState } from '../../../config/store';
import { __ } from 'modeling-tool';
import { SettingGroup } from '../../../ts/interfaces';
import { useStyles } from './tenant-settings-styles';
import { DropdownProps } from '@fluentui/react-components';
import { SettingsGroup } from './settings-group';

const TenantSettings = (props: PropsFromRedux & Partial<DropdownProps>) => {
  const {
    UserReducer: { settingsGroups },
  } = props;

  const classes = useStyles();

  const renderSettingsGroups = () => {
    if (settingsGroups && settingsGroups.length > 0) {
      return settingsGroups.map((group: SettingGroup) => (
        <div key={group.id} className={classes.settingItemsHolder}>
          <Label>{__(group.title)}</Label>
          <SettingsGroup group={group} />
        </div>
      ));
    }
    return null;
  };

  return <>{renderSettingsGroups()}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps);
export default connector(TenantSettings);
