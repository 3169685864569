import { TeamsFx } from '@microsoft/teamsfx';
import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';
/*
  action fuction to get advertisers list from server and dispatch it to redux
  reducer
*/
export const getCurrentUserAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: 'me/',
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_CURRENT_USER',
    cb,
    ecb,
  });
export const setRemindPermissionRequestAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: 'me/remind_permission_request/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'REMIND_PERMISSION_REQUEST',
    cb,
    ecb,
  });
export const setNotifyPermissionRequestAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: 'me/notify_permission_request/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'NOTIFY_PERMISSION_REQUEST',
    cb,
    ecb,
  });
export const getCurrentUserLicenseExpiredAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: 'licenseexpiry/get_user_me/',
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_CURRENT_USER',
    cb,
    ecb,
  });

export const saveProfileAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'profile/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'SAVE_PROFILE',
    reqPayload: data,
    cb,
  });

export const getNotificationsAction = () =>
  UseAction({
    url: 'notification/',
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_NOTIFICATIONS',
  });

export const addNotificationAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'ADD_NOTIFICATION',
    payload: data,
  });

export const readNotificationAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `notification/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    action: 'UPDATE_NOTIFICATION',
    reqPayload: data,
    cb,
  });
export const readAllNotifications = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `notification/all_seen/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'UPDATE_NOTIFICATION_ALL',
    cb,
  });

export const changeLanguageAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'CHANGE_LANGUAGE',
    payload: data,
  });

export const updateSettingAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `setting/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_SETTING',
    reqPayload: data,
    cb,
  });
export const increaseAiRequestAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `setting/addrequests/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_REQUESTS',
    cb,
  });
export const updateTenantAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `tenant/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_TENANT',
    reqPayload: data,
    cb,
  });
export const setUserAdminsAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SET_USER_ADMINS',
    payload: data,
    cb,
  });

export const setTeamsFx = (teamsfx?: TeamsFx, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'Set_TEAMS_FX',
    payload: teamsfx,
    cb,
  });
