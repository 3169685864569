import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ProcessRecordModeler } from '../../components';
import { RootState } from '../../config/store';
import { getProcessInstanceAction } from '../../store/actions/process-instance-actions';
import { useParams } from 'react-router';
import { __ } from 'modeling-tool';

const TasksApproval = (props: PropsFromRedux) => {
  const {
    ProcessInstanceReducer: { selectedInstance },
    getProcessInstanceAction,
  } = props;

  const params = useParams();

  useEffect(() => {
    if (params && params.id) {
      getProcessInstanceAction(parseInt(params.id as string));
    }
  }, []);

  return <>{selectedInstance && <ProcessRecordModeler isTaskDetailsView={true} />}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessInstanceReducer, ProcessReducer }: RootState) => ({
  ProcessInstanceReducer,
  ProcessReducer,
});
const connector = connect(mapStateToProps, {
  getProcessInstanceAction,
});
export default connector(TasksApproval);
