import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConnectedProps, connect } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import { Theme, useTheme } from '@fluentui/react';
import { RootState } from '../../../../config/store';
import { GLOBALENUMS, ProcessMapModeler, ProcessMapModelerContext } from 'modeling-tool';
import { checkPermission } from '../../../../config/permission-utils';
import { fetchDropdownDataByType } from '../../../../store/actions/dropdown-data-actions';
import {
  selectProcessAction,
  fetchAllChildProcesses,
  fetchProcessesAction,
  searchProcessesAction,
} from '../../../../store/actions/process-actions';
import {
  fetchLibrariesAction,
  fetchLibraryPictureAction,
  fetchSitesAction,
} from '../../../../store/actions/file-actions';
import {
  addProcessMapFullModel,
  fetchProcessMapsAction,
  getProcessMapFullModelByParamsAction,
  selectProcessMapAction,
  toggleEditMode,
  updateProcessMapGraphData,
} from '../../../../store/actions/process-map-actions';

const ProcessMapModelerProvider = (props: PropsFromRedux) => {
  const {
    UserReducer: { permissions },
    ProcessMapReducer: { editMode },
    addProcessMapFullModel,
    fetchAllChildProcesses,
    fetchDropdownDataByType,
    fetchLibrariesAction,
    searchProcessesAction,
    fetchProcessMapsAction,
    fetchSitesAction,
    getProcessMapFullModelByParamsAction,
    selectProcessAction,
    selectProcessMapAction,
    toggleEditMode,
    updateProcessMapGraphData,
  } = props;

  const theme: Theme = useTheme();
  const params = useParams();
  const wildcard = params['*'];

  const [processMapFullModel, setProcessMapFullModel] = useState<any>({});
  const [readonly, setReadonly] = useState<boolean | undefined>(true);
  const [hasBeenSaved, { toggle: toggleHasBeenSaved }] = useBoolean(false);
  const userHasPermission = checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_processmap);
  const navigate = useNavigate();

  useEffect(() => {
    editMode ? setReadonly(!userHasPermission) : setReadonly(true);
  }, [editMode]);

  useEffect(() => {
    if (params.processMapId) {
      if (wildcard) {
        const lastId = getWildcardId();
        if (lastId) {
          getOrCreateProcessMapModel(lastId, false);
        }
      } else {
        getOrCreateProcessMapModel(parseInt(params.processMapId), true);
      }
    }
  }, []);

  const getWildcardId = () => {
    if (wildcard) {
      const ids = wildcard.split('/');
      const lastId = ids[ids.length - 1];
      return parseInt(lastId);
    }
  };

  const assignFullModelWithData = (data: any) => {
    // for the pictures of the model, we need to fetch it from sharepoint
    // to make sure the load times are not than long, the pictures are fetched in parallel
    const fullModel = data;
    const nodes = fullModel.nodes;

    // create a promise function for getting the picture, other nodes are immediately resolved
    const getPicturesForNodes = (node: any) => {
      return new Promise((resolve, reject) => {
        if (
          node.nodeType === GLOBALENUMS.ORGANIZATIONCHART.PICTURE &&
          node.nodeAppData &&
          node.nodeAppData.length > 0 &&
          node.nodeAppData[0].picture
        ) {
          try {
            const ids = node.nodeAppData[0].picture.split('|');
            fetchLibraryPictureAction(ids[0], ids[1], ids[2], (res: any, err: any) => {
              node.nodeAppData[0].localPicture = res;
              resolve(node);
            });
          } catch {
            // picture is not available, resolve the node
            resolve(node);
          }
        } else {
          resolve(node);
        }
      });
    };

    // create the promises for each node
    const promises: any = nodes.map((node: any) => getPicturesForNodes(node));

    // when all promisses are fulfilled, continue
    const processedModel = Promise.all(promises);
    processedModel.then((res) => {
      const result = { ...fullModel, nodes: res };
      setProcessMapFullModel(result as any);
    });
  };

  const getOrCreateProcessMapModel = (id: number, isProcessMap?: boolean) => {
    getProcessMapFullModelByParamsAction({ id: id, isProcessMap: isProcessMap }, (res: any) => {
      const buildUrl = (baseId: any, additionalIds: number[] = []) => {
        return `/process-management/processmap/${baseId}${additionalIds.map((id) => `/${id}`).join('')}`;
      };
      if (res?.data?.objects?.length) {
        const [firstObject] = res.data.objects;
        const { fromProcessGroup, fromProcessMap } = firstObject;
        const processGroupId = fromProcessGroup?.id || null;
        const processMapId = fromProcessMap?.id || null;

        if (processGroupId) {
          selectProcessAction(fromProcessGroup);

          if (!wildcard) {
            const url = buildUrl(params.processMapId, [processGroupId]);
            navigate(url);
          } else if (getWildcardId() !== id) {
            const ids = [params.processMapId, ...wildcard.split('/').map(Number), processGroupId];
            const url = buildUrl(ids.shift()!, ids);
            navigate(url);
          }
        } else if (processMapId && processMapId !== id) {
          selectProcessMapAction(firstObject.fromProcessMap);
          const url = buildUrl(processMapId);
          navigate(url);
        }
        assignFullModelWithData(res.data.objects[0]);
        console.log('processGroupId', processGroupId);
        searchProcessesAction('?process_map_type=' + GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP);
        fetchProcessMapsAction();
      } else if (isProcessMap) {
        addProcessMapFullModel({ fromProcessMap: `/api/v1/processmap/${id}/` }, (res: any) => {
          if (res?.data?.fromProcessMap) {
            setProcessMapFullModel(res.data);
            selectProcessMapAction(res.data.fromProcessMap);
            searchProcessesAction('?process_map_type=' + GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP);
            fetchProcessMapsAction();
          }
        });
      } else {
        addProcessMapFullModel({ fromProcessGroup: `/api/v1/process/${id}/` }, (res: any) => {
          if (res?.data?.fromProcessGroup?.id) {
            selectProcessAction(res.data.fromProcessGroup);
            if (wildcard) {
              const ids = [params.processMapId, ...wildcard.split('/').map(Number), res.data.fromProcessGroup.id];
              const url = buildUrl(ids.shift()!, ids);
              navigate(url);
            } else {
              const url = buildUrl(params.processMapId, [res.data.fromProcessGroup.id]);
              navigate(url);
            }
          }
          setProcessMapFullModel(res.data);
          searchProcessesAction('?process_map_type=' + GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP);
          fetchProcessMapsAction();
        });
      }
    });
  };

  const processMapModelerContextValues = {
    editMode,
    hasBeenSaved,
    processMapFullModel,
    readonly,
    theme,
    assignFullModelWithData,
    fetchAllChildProcesses,
    fetchDropdownDataByType,
    fetchLibrariesAction,
    fetchLibraryPictureAction,
    fetchSitesAction,
    getOrCreateProcessMapModel,
    navigate,
    toggleEditMode,
    toggleHasBeenSaved,
    updateProcessMapGraphData,
    userHasPermission,
  };

  return (
    <ProcessMapModelerContext.Provider value={processMapModelerContextValues}>
      <ProcessMapModeler />
    </ProcessMapModelerContext.Provider>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, ProcessMapReducer }: RootState) => ({ UserReducer, ProcessMapReducer });

const connector = connect(mapStateToProps, {
  addProcessMapFullModel,
  fetchAllChildProcesses,
  fetchSitesAction,
  fetchLibrariesAction,
  fetchDropdownDataByType,
  searchProcessesAction,
  fetchProcessMapsAction,
  getProcessMapFullModelByParamsAction,
  selectProcessMapAction,
  selectProcessAction,
  toggleEditMode,
  updateProcessMapGraphData,
});

export default connector(ProcessMapModelerProvider);
