import React, { useEffect, useRef, useState } from 'react';
import {
  CheckboxVisibility,
  DetailsRow,
  DirectionalHint,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  Label,
  Link,
  Stack,
  Text,
} from '@fluentui/react';
import { getGridStyle, getStyles } from './process-instance-information-section-styles';
import { Icon } from '@fluentui/react/lib/Icon';
import { FileProps, Process, ProcessInstance, Requirement, RequirementChapterData } from '../../../ts/interfaces';
import { BasicList, DetailsTag, GLOBALENUMS, IconSvg, PeopleWithPopover, __ } from 'modeling-tool';
import { File, MgtTemplateProps } from '@microsoft/mgt-react';
import { useStyleProps } from '../../../hooks';
import { getDateFormatted } from '../../../config/utils';
import { Person, PersonCardInteraction, ViewType } from '@microsoft/mgt-react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { clearProcessAction, fetchProcessChildrenAction } from '../../../store/actions/process-actions';
import { RenderDynamicContent } from '../../../components';
import { useNavigate } from 'react-router';
import { Parser } from 'html-to-react';
import { fetchRequirementsAction } from '../../../store/actions/requirement-action';
import { ElementInformationsSection } from './element-informations-section';
import { findRequirementName } from '../../../utils';
import { SVGNameEnum } from '../../../ts/enums/svg-name-enum';
import { useBoolean } from '@fluentui/react-hooks';

interface ProcessInstanceInformationSectionProps {
  processInstanceData?: ProcessInstance | null;
  processData: Process;
}

const iconSize = 24;

const ClickableDocument = (props: MgtTemplateProps & FileProps) => {
  const { siteId, driveId, itemId } = props;
  const { file } = props.dataContext;
  return (
    <Stack onClick={() => window.open(file['webUrl'])}>
      <File siteId={siteId} driveId={driveId} itemId={itemId}></File>
    </Stack>
  );
};

const Wrapper = (props: any): JSX.Element => {
  const { title, children } = props;
  const [show, { toggle: toggleShow }] = useBoolean(true);
  const styles = getStyles(useStyleProps());
  return (
    <Stack tokens={{ childrenGap: 16 }} className={styles.sectionStyles}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <div className={styles.title}> {title}</div>
        <Icon
          iconName={show ? 'ChevronUp' : 'ChevronDown'}
          onClick={toggleShow}
          style={{ cursor: 'pointer', color: '#424242' }}
        />
      </Stack>
      <Stack style={{ display: show ? 'block' : 'none' }}>{children}</Stack>
    </Stack>
  );
};

const ProcessInstanceInformationSection = (props: PropsFromRedux & ProcessInstanceInformationSectionProps) => {
  const {
    DropdownDataReducer: { requirements, requirementChapters },
    ProcessReducer: { processChildren },
    processInstanceData,
    processData,
    fetchProcessChildrenAction,
    clearProcessAction,
  } = props;

  const listStyles = getGridStyle();

  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const navigate = useNavigate();

  const onClickLink = (link?: string) => {
    window.open(link?.toString(), '_blank')?.focus();
  };

  const [chapterData, setChapterData] = useState<RequirementChapterData[]>([]);
  const [fileList, setFileList] = useState<string[] | undefined>([]);

  const departmentCellWidthRef = useRef<number>(250);
  const locationCellWidthRef = useRef<number>(250);

  useEffect(() => {
    processData.children?.length && fetchProcessChildrenAction(processData.id);
    return () => {
      clearProcessAction();
    };
  }, []);

  useEffect(() => {
    if (processData && processData.attachments) {
      const files = processData.attachments?.split('|');
      if (files.length > 0 && files[0] != '') {
        setFileList(files);
      }
    }
  }, [processData]);

  useEffect(() => {
    if (requirements && requirements.length > 0) {
      getRequirementChapters();
    }
  }, [requirements]);

  const getRequirementChapters = () => {
    // temporary solution to quicken up details page
    const tmpChapterData: any = [];
    requirements &&
      requirements.forEach((requirement: Requirement) => {
        tmpChapterData.push({
          resourceUri: requirement.resourceUri,
          name: requirement.name,
        });
      });
    requirementChapters &&
      requirementChapters.forEach((chapter: any) => {
        tmpChapterData.push({
          resourceUri: chapter.resourceUri.replace('requirementchapter', 'requirementchapter_min'),
          name: chapter.name,
        });
      });
    setChapterData(tmpChapterData);
  };

  const renderMasterData = () => {
    const masterDataTokens = { childrenGap: 10 };
    return (
      <Stack horizontal wrap>
        <Stack tokens={masterDataTokens}>
          <Stack.Item>{__('content type')} </Stack.Item>
          <Stack.Item className={styles.masterDataValues}>{__('process')}</Stack.Item>
        </Stack>
        <Stack tokens={masterDataTokens}>
          <Stack.Item>{__('actual version')}</Stack.Item>
          <Stack.Item className={styles.masterDataValues}>{processData.versionNumber}</Stack.Item>
        </Stack>
        <Stack tokens={masterDataTokens}>
          <Stack.Item>{__('created on')}</Stack.Item>
          <Stack.Item className={styles.masterDataValues}>{getDateFormatted(processData.dateCreated)}</Stack.Item>
        </Stack>
        <Stack tokens={masterDataTokens}>
          <Stack.Item>{__('actually created by')}</Stack.Item>
          <Stack.Item>
            <Person
              key={processData.createdBy.username}
              userId={processData.createdBy.username}
              view={ViewType.oneline}
              personCardInteraction={PersonCardInteraction.click}
              className={styles.masterDataValues}
            ></Person>
          </Stack.Item>
        </Stack>
        <Stack tokens={{ childrenGap: 2 }}>
          <Stack.Item>{__('approved by')}</Stack.Item>
          <Stack.Item className={styles.masterDataValues}>
            <PeopleWithPopover
              employees={processData.approvedBy?.employees}
              hoverable={false}
              id={0}
              direction={DirectionalHint.topCenter}
              showNames={true}
            />
          </Stack.Item>
        </Stack>
        <Stack tokens={masterDataTokens}>
          <Stack.Item>{__('last changed')}</Stack.Item>
          <Stack.Item className={styles.masterDataValues}>{getDateFormatted(processData.lastChanged)}</Stack.Item>
        </Stack>
        <Stack tokens={masterDataTokens}>
          <Stack.Item>{__('last changed by')}</Stack.Item>
          <Stack.Item>
            <Person
              key={processData.lastModifiedBy}
              userId={processData.lastModifiedBy}
              view={ViewType.oneline}
              personCardInteraction={PersonCardInteraction.click}
            ></Person>
          </Stack.Item>
        </Stack>
      </Stack>
    );
  };

  const renderItemWithIcon = (items: any, itemName: string, svgName: string) => {
    return (
      <>
        {items?.map((item: any, index: number) => {
          return (
            <Stack key={index} horizontal verticalAlign="center">
              <Stack.Item style={{ width: 31, display: 'flex' }}>
                <IconSvg
                  svgName={svgName}
                  width={iconSize}
                  height={iconSize}
                  text={item[itemName]}
                  color={styleProps.theme.palette.themePrimary}
                />
              </Stack.Item>
              <Stack.Item>{item[itemName]}</Stack.Item>
            </Stack>
          );
        })}
      </>
    );
  };

  const renderRaciFields = () => {
    const { owner, accountable, responsible, consulted, informed } = processData;

    const sections = [
      { key: 'departments', name: 'departmentName', svgName: SVGNameEnum.FIRSTLETTERCIRCLE },
      { key: 'roles', name: 'roleName', svgName: SVGNameEnum.ROLEHAT },
      { key: 'staffPositions', name: 'staffPositionName', svgName: SVGNameEnum.FIRSTLETTERCIRCLE },
      { key: 'externalPartners', name: 'externalPartnerName', svgName: SVGNameEnum.FIRSTLETTERCIRCLE },
    ];

    const renderEmployees = (items: string, label: string) => {
      return (
        <Stack.Item className={styles.raciFieldCell}>
          <Label> {`${label}`}</Label>
          <PeopleWithPopover employees={items} hoverable={true} id={0} showNames={true} />
        </Stack.Item>
      );
    };
    return (
      <Stack horizontal tokens={{ childrenGap: 24 }}>
        <Stack wrap horizontal>
          {
            <Stack verticalAlign="start" className={styles.raciFieldCell} tokens={{}}>
              <Label> {__('process owner')}</Label>
              <Stack horizontal tokens={{ childrenGap: 15, padding: 5 }}>
                {owner?.employees && (
                  <PeopleWithPopover employees={owner.employees} hoverable={true} id={0} showNames={true} />
                )}
                {sections.map(({ key, name, svgName }) => {
                  const item = owner?.[key];
                  return item && !!item.length && renderItemWithIcon(item, name, svgName);
                })}
              </Stack>
            </Stack>
          }
          {!!accountable?.employees?.length && renderEmployees(accountable?.employees, __('accountable'))}
          {!!responsible?.employees?.length && renderEmployees(responsible?.employees, __('responsible'))}
          {!!consulted?.employees?.length && renderEmployees(consulted?.employees, __('consulted'))}
          {!!informed?.employees?.length && renderEmployees(informed?.employees, __('informed'))}
        </Stack>
      </Stack>
    );
  };

  const renderFileList = () => {
    return (
      <Stack>
        {fileList && fileList.length && (
          <Stack horizontal tokens={{ childrenGap: 24 }} wrap>
            {fileList
              ?.map((file) => file.split('::'))
              .map((fileData) => (
                <div className="file-item" key={`${fileData}`}>
                  <Stack
                    horizontal
                    tokens={{
                      childrenGap: 10,
                      padding: 5,
                    }}
                  >
                    <Stack.Item grow={3}>
                      <File siteId={fileData[0]} driveId={fileData[1]} itemId={fileData[2]}>
                        <ClickableDocument siteId={fileData[0]} driveId={fileData[1]} itemId={fileData[2]} />
                      </File>
                    </Stack.Item>
                  </Stack>
                </div>
              ))}
          </Stack>
        )}
      </Stack>
    );
  };

  const columns: IColumn[] = [
    {
      name: __('title'),
      fieldName: 'process_name',
      key: 'title',
      minWidth: 100,
      maxWidth: 400,
      isResizable: false,
      onRender: (record: Process) => {
        return (
          <>
            {record && (
              <Stack horizontal verticalAlign="center" className={styles.cellText}>
                <Link
                  onClick={() => {
                    navigate(`/process-management/processes/${record.id}`);
                  }}
                >
                  {record.processName}
                </Link>
              </Stack>
            )}
          </>
        );
      },
    },
    {
      name: __('group'),
      fieldName: 'group',
      key: 'group_name',
      minWidth: 90,
      maxWidth: 250,
      isResizable: false,
      onRender: (record: Process) =>
        record.group &&
        record.group.groupName.trim().length && <div className={styles.cellText}>{record.group.groupName}</div>,
    },
    {
      name: __('assigned to'),
      fieldName: 'department',
      key: 'department',
      minWidth: 90,
      maxWidth: 250,
      calculatedWidth: 250,
      isResizable: true,
      onColumnResize: (width: number | undefined) => {
        if (width) {
          departmentCellWidthRef.current = width;
        }
      },
      onRender: (record: Process & { orgUnitsAndStaffPositions: any } & { departmentCellWidth: number }) => (
        <RenderDynamicContent
          id={record.id}
          record={record.orgUnitsAndStaffPositions}
          cellWidth={departmentCellWidthRef}
          isDepartment={true}
        />
      ),
    },
    {
      name: __('owner'),
      fieldName: 'processOwner',
      key: 'processOwner',
      minWidth: 90,
      maxWidth: 250,
      isResizable: false,
      onRender: ({ owner, id }: Process) =>
        owner && owner.length ? <PeopleWithPopover employees={owner.employees} id={id} field={'owner'} /> : '',
    },
    {
      name: __('location'),
      fieldName: 'location',
      key: 'processLocations',
      minWidth: 90,
      maxWidth: 250,
      isResizable: false,
      onColumnResize: (width: number | undefined) => {
        if (width) {
          locationCellWidthRef.current = width;
        }
      },
      onRender: (record: Process) => (
        <RenderDynamicContent
          id={record.id}
          record={record.processLocations}
          cellWidth={locationCellWidthRef}
          isDepartment={false}
        />
      ),
    },
    {
      name: __('status'),
      fieldName: 'state',
      key: 'state__project_status_name',
      minWidth: 185,
      maxWidth: 200,
      isResizable: false,
      onRender: (record: Process) => renderStatus(record),
    },
  ];

  const renderStatus = (record: Process) => {
    return (
      <Stack.Item className={styles.tagStyles} style={{ background: record.state.color }}>
        <Text variant={'smallPlus'}>{__(record.state.projectStatusName)}</Text>
      </Stack.Item>
    );
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props: any): JSX.Element | null => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = {
        zIndex: 10000 - props.itemIndex,
        transition: 'z-index 0s',
        height: '48px',
        borderTop: props.itemIndex === 0 ? 'none' : `1px solid ${styleProps.theme.palette.neutralLight}`,
        borderBottom: 'none',
        selectors: {
          ':hover': {
            backgroundColor: `${styleProps.theme.palette.white}`,
          },
        },
      };
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  return (
    <Stack className={styles.cardBackground} tokens={{ childrenGap: 16 }}>
      {!!processData.description?.length && (
        <Wrapper title={__('process description')}>
          <Stack className={styles.description}>{Parser().parse(processData.description)}</Stack>
        </Wrapper>
      )}

      {processChildren && processChildren.objects && processChildren.objects.length > 0 && (
        <Wrapper title={__('subordinate processes')}>
          <BasicList
            setKey={GLOBALENUMS.BASICLISTTYPES.PROCESSES_CHILDREN_LIST}
            items={processChildren.objects}
            columns={columns}
            className={listStyles}
            checkboxVisibility={CheckboxVisibility.hidden}
            isResizable={true}
            onRenderRow={onRenderRow}
          />
        </Wrapper>
      )}

      {(processData.owner?.employees?.length ||
        processData.owner?.departments?.length ||
        processData.owner?.staffPositions?.length ||
        processData.owner?.externalPartners?.length ||
        processData.owner?.roles?.length ||
        processData.responsible ||
        processData.accountable ||
        processData.consulted ||
        processData.informed) && (
        <div className={styles.pageBreakAvoid}>
          <Wrapper title={__('responsibilities')}>{renderRaciFields()}</Wrapper>
        </div>
      )}

      {!!fileList?.length && fileList.length > 0 && (
        <div className={styles.pageBreakAvoid}>
          <Wrapper title={__('documents')}>{renderFileList()}</Wrapper>
        </div>
      )}

      {!!processData.linkText?.length && (
        <div className={styles.pageBreakAvoid}>
          <Wrapper title={__('external links')}>
            <div>
              <Link variant="medium" onClick={() => onClickLink(processData?.linkUrl)}>
                <DetailsTag
                  pillView={true}
                  isLink={true}
                  key={1}
                  disabled={true}
                  item={{ key: 1, name: processData.linkText as string, styleProps: styleProps }}
                  index={1}
                  icon={'Go'}
                />
              </Link>
            </div>
          </Wrapper>
        </div>
      )}

      {!!processData.requirementsList?.length && (
        <div className={styles.pageBreakAvoid}>
          <Wrapper title={__('requirements')}>
            <Stack horizontal tokens={{ childrenGap: 24 }} wrap>
              {processData.requirementsList?.map((uri: any, i: number) => (
                <DetailsTag
                  pillView={true}
                  key={i}
                  disabled={true}
                  item={{ key: 1, name: findRequirementName(uri, chapterData) as string, styleProps: styleProps }}
                  index={i}
                />
              ))}
            </Stack>
          </Wrapper>
        </div>
      )}

      {!!processData.resources?.length && (
        <div className={styles.pageBreakAvoid}>
          <Wrapper title={__('resources')}>
            <Stack horizontal tokens={{ childrenGap: 24 }} wrap>
              {processData.resources?.map((resource, i) => (
                <DetailsTag
                  pillView={true}
                  key={i}
                  disabled={true}
                  item={{ key: 1, name: resource.nameExtended, styleProps: styleProps }}
                  index={i}
                />
              ))}
            </Stack>
          </Wrapper>
        </div>
      )}

      {!!processData.processLocations?.length && (
        <div className={styles.pageBreakAvoid}>
          <Wrapper title={__('locations')}>
            <Stack horizontal tokens={{ childrenGap: 24 }} wrap>
              {processData.processLocations?.map((location, i) => (
                <DetailsTag
                  pillView={true}
                  key={i}
                  disabled={true}
                  item={{ key: 1, name: location.locationName, styleProps: styleProps }}
                  index={i}
                />
              ))}
            </Stack>
          </Wrapper>
        </div>
      )}
      {processInstanceData?.id && (
        <Wrapper title={__('process elements')}>
          <ElementInformationsSection instanceId={processInstanceData.id} chapterData={chapterData} />
        </Wrapper>
      )}
      {!!processData.versionNumber && (
        <div className={styles.pageBreakAvoid}>
          <Wrapper title={__('Master data')}>{renderMasterData()}</Wrapper>
        </div>
      )}
    </Stack>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessReducer, UserReducer, DropdownDataReducer }: RootState) => ({
  DropdownDataReducer,
  ProcessReducer,
  UserReducer,
});

const connector = connect(mapStateToProps, {
  fetchRequirementsAction,
  fetchProcessChildrenAction,
  clearProcessAction,
});
export default connector(ProcessInstanceInformationSection);
