import { Person, ViewType } from '@microsoft/mgt-react';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { searchTasksAction } from '../../../store/actions/dashboard-actions';
import { RootState } from '../../../config/store';
import { getDateFormatted } from '../../../config/utils';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { ProcessInstance } from '../../../ts/interfaces';
import { useStyles } from './open-tasks-card-styles';
import { useNavigate } from 'react-router';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnSizingOptions,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from '@fluentui/react-components';

const OpenTasksCard = (props: PropsFromRedux) => {
  const {
    DashboardReducer: { tasks },
    UserReducer: { username },
    searchTasksAction,
  } = props;

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if (username) {
      const search = `?order_by=-id&dashboard_tasks`;
      searchTasksAction(search);
    }
  }, [props.UserReducer.username]);

  const renderCardTitle = () => <div className={classes.openTaskCardTitle}>{__('my open tasks')}</div>;

  const renderCardExtra = () => (
    <Link
      onClick={() => {
        navigate(`/tasks`);
      }}
    >
      {__('view all')}
    </Link>
  );

  const renderTaskState = (state: GLOBALENUMS.PROCESSSTATUS) => {
    switch (state) {
      case GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_APPROVAL:
      case GLOBALENUMS.PROCESSSTATUS.INSTANCE_MODELING_IN_APPROVAL:
        return __('Process approval');
      case GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING:
        return __('Process recording');
      default:
        return state;
    }
  };

  const onRowClick = (item: ProcessInstance) => {
    navigate(`/process-management/processes/${item.sourceProcess.id}/modeling-tool/${item.id}`);
  };

  const renderUserName = (userId: string) => <Person userId={userId} view={ViewType.oneline}></Person>;

  const columnsDef = [
    createTableColumn({
      columnId: 'instanceName',
      renderHeaderCell: () => <>{__('process')}</>,
    }),
    createTableColumn({
      columnId: 'state',
      renderHeaderCell: () => <>{__('task')}</>,
    }),
    createTableColumn({
      columnId: 'createdBy',
      renderHeaderCell: () => <>{__('create')}</>,
    }),
    createTableColumn({
      columnId: 'dateCreated',
      renderHeaderCell: () => <>{__('date')}</>,
    }),
  ];

  const [columns] = useState(columnsDef);
  const [columnSizingOptions] = useState<TableColumnSizingOptions>({
    instanceName: {
      minWidth: 80,
      defaultWidth: 250,
    },
    state: {
      minWidth: 80,
      defaultWidth: 160,
    },
    createdBy: {
      minWidth: 80,
      defaultWidth: 160,
    },
    dateCreated: {
      minWidth: 80,
      defaultWidth: 80,
    },
  });

  const {
    getRows,
    columnSizing_unstable,
    tableRef,
    selection: { isRowSelected },
  } = useTableFeatures(
    {
      columns,
      items: tasks,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      selected,
      appearance: selected ? ('brand' as const) : ('none' as const),
    };
  });

  const renderOpenTaskList = () => {
    return (
      <Table sortable aria-label="Table with sort" ref={tableRef} {...columnSizing_unstable.getTableProps()}>
        <TableHeader>
          <TableRow>
            {columns?.map((column) => (
              <TableHeaderCell
                key={column.columnId}
                {...columnSizing_unstable.getTableHeaderCellProps(column.columnId)}
                className={classes.tableHeaderCell}
              >
                {column.renderHeaderCell()}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map(({ item, appearance, selected }) => (
            <TableRow
              key={item.id}
              aria-selected={selected}
              appearance={appearance}
              onClick={() => onRowClick(item)}
              className={classes.tableRow}
            >
              <TableCell {...columnSizing_unstable.getTableCellProps('instanceName')}>
                <TableCellLayout>{item.instanceName}</TableCellLayout>
              </TableCell>
              <TableCell {...columnSizing_unstable.getTableCellProps('state')}>
                <TableCellLayout>{renderTaskState(item.state?.type)}</TableCellLayout>
              </TableCell>
              <TableCell {...columnSizing_unstable.getTableCellProps('createdBy')}>
                <TableCellLayout>{renderUserName(item.sourceProcess.createdBy.username)}</TableCellLayout>
              </TableCell>
              <TableCell {...columnSizing_unstable.getTableCellProps('dateCreated')}>
                <TableCellLayout>{getDateFormatted(item.dateCreated, 'DD.MM.YY')}</TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className={classes.openTaskCard}>
      <div className={classes.headerTaskCard}>
        <div>{renderCardTitle()}</div>
        <div>{renderCardExtra()}</div>
      </div>
      <div className={classes.tasksListWrapper}>
        <div className={classes.tasksList}>{renderOpenTaskList()}</div>
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, DashboardReducer }: RootState) => ({
  UserReducer,
  DashboardReducer,
});
const connector = connect(mapStateToProps, {
  searchTasksAction,
});
export default connector(OpenTasksCard);
