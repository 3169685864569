import { Theme, memoizeFunction, mergeStyleSets } from '@fluentui/react';

export const getStyles = memoizeFunction((theme?: Theme) => {
  return mergeStyleSets({
    horizontalHalfStack: {
      width: '48%',
      maxWidth: '240px',
    },
    headerContainer: {
      marginTop: 5,
      marginBottom: 2,
    },
    dropdownStyles: {
      marginLeft: 0,
      color: '#F6F6F6',
      fontSize: '14px',
      background: `${theme?.palette.neutralLighter}`,
      width: 130,

      '.ms-Dropdown-title': {
        border: 'none',
        background: `${theme?.palette.neutralLighter}`,
        padding: '4px 28px 4px 14.91px',
        justifyContent: 'center',
        height: 38,
      },

      '.ms-Dropdown-caretDownWrapper': {
        justifyContent: 'center',
        paddingTop: 6,
      },
    },
    dropdownWithSearch: {
      flex: '2 1 auto',
      marginLeft: 12,
      '.ms-Label': {
        display: 'none',
      },
    },
    ownerWrapper: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'space-between',
    },
  });
});
