import { INavStyles, mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme, isTeamsApp } = props;
  return mergeStyleSets({
    sidebar: {
      position: 'fixed',
      zIndex: 2000,
      top: isTeamsApp ? 0 : 48,
      height: isTeamsApp ? '100vh' : `calc(100vh - 48)`,
      maxHeight: '100%',
      width: 50,
      left: '0px',
      bottom: '0px',
      background: '#F6f6f6',
      borderRight: `px solid ${theme.palette.neutralLight}`,
      transition: '0.2s',
      transitionDelay: '0.3s',
      ':hover': { width: '220px' },
    },
    badgeContainer: {
      postition: 'relative',
      top: 0,
      left: 0,
      width: '0px',
      height: '0px',
      background: 'red',
    },
    notificationBadge: {
      position: 'absolute',
      top: 5,
      left: '30px',
      zIndex: 4000,
      minWidth: 18,
      height: 18,
      backgroundColor: theme.palette.red,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.white,
      fontSize: theme.fonts.small.fontSize,
      lineHeight: '14px',
      textAlign: 'center',
    },
  });
});

export const getNavStyles = (props: StyleProp) => {
  const { theme, isTeamsApp } = props;
  return {
    root: {
      overflowY: 'auto',
      marginTop: '1px',
    },
    navItem: {
      i: {
        marginLeft: isTeamsApp ? '5px' : '9px',
        marginRight: isTeamsApp ? '15px' : '5px',
        fontSize: '23px',
        color: theme.palette.themeDarker,
      },
    },
    link: {
      selectors: {
        '.ms-Nav-compositeLink:hover &': {
          backgroundColor: `${theme.palette.white}`,
        },
        '.is-selected &': {
          backgroundColor: theme.palette.themeLighter,
        },
      },
      '&::after': {
        borderLeft: `4px solid ${theme.palette.themeDarker}`,
      },
      height: '48px',
      borderRadius: 0,
    },
    linkText: {
      color: 'black',
      selectors: {
        '&:active, &:focus, &:hover': { color: `${theme.palette.black}` },
      },

      marginLeft: '18px',
    },
  } as Partial<INavStyles>;
};
