import { AutoCompletePlaceAddress, LicenceCheckOut } from '../../ts/interfaces';

export interface LicenceCheckOutReducerState {
    licenceCheckOuts: LicenceCheckOut[];
    newPlanLicenceCheckOuts: LicenceCheckOut[];
    paymentIntervalType?: string;
    paymentMethodType?: string;
    placesSearchResults: AutoCompletePlaceAddress[];
}

export default function reducer(
    state: LicenceCheckOutReducerState = {
        licenceCheckOuts: [],
        newPlanLicenceCheckOuts: [],
        paymentIntervalType: "year",
        paymentMethodType: "charge_automatically",
        placesSearchResults: [],
    },
    action: any,
): LicenceCheckOutReducerState {
    switch (action.type) {
        case 'INIT_LICENCE_CHECK_OUT': {
            return Object.assign({}, state, {
                licenceCheckOuts: action.payload.categories.map((item: Omit<LicenceCheckOut, 'cartLicencesCount'>) => ({
                    ...item,
                    cartLicencesCount: item.usedLicencesCount // Assigning default value to prop4
                })),
                paymentIntervalType: action.payload.paymentIntervalType,
                paymentMethodType: action.payload.paymentMethodType,
            });
        }
        case 'UPDATE_LICENCE_CHECK_OUT': {
            const updatedLicenceCheckOut = state.licenceCheckOuts.map(item => {
                if (item.groupName === action.payload.groupName) {
                    return { ...item, cartLicencesCount: action.payload.cartCount };
                }
                return item;
            });
            return Object.assign({}, state, {
                licenceCheckOuts: updatedLicenceCheckOut,
            });
        }
        case 'INIT_NEW_PLAN_LICENCE_CHECK_OUT': {
            return Object.assign({}, state, {
                newPlanLicenceCheckOuts: action.payload.categories.map((item: Omit<LicenceCheckOut, 'cartLicencesCount'>) => ({
                    ...item,
                    cartLicencesCount: item.totalLicencesCount < 0 ? 0 : item.totalLicencesCount // Assigning default value to prop4
                })),
                paymentIntervalType: action.payload.paymentIntervalType,
                paymentMethodType: action.payload.paymentMethodType,
            });
        }
        case 'UPDATE_NEW_PLAN_LICENCE_CHECK_OUT': {
            const updatedNewPlanLicenceCheckOut = state.newPlanLicenceCheckOuts.map(item => {
                if (item.groupName === action.payload.groupName) {
                    return { ...item, cartLicencesCount: action.payload.cartCount };
                }
                return item;
            });
            return Object.assign({}, state, {
                newPlanLicenceCheckOuts: updatedNewPlanLicenceCheckOut,
            });
        }
        case 'PAY_ONLINE_STRIPE': {
            return state;
        }
        case 'UPDATE_PAY_ONLINE_STRIPE': {
            return state;
        }
        case 'PAY_ON_ACCOUNT': {
            return state;
        }
        case 'UPDATE_PAY_ON_ACCOUNT': {
            return state;
        }
        case 'GET_AUTO_COMPLETE_PLACES': {
            return Object.assign({}, state, {
                placesSearchResults: action.payload?.results?.map((item: any) => {
                    return {
                        address: item.address.freeformAddress,
                        street: `${item.address.streetName || ''} ${item.address.streetNumber || ''}`,
                        postalCode: item.address.postalCode,
                        city: item.address.localName,
                        countryCode: item.address.countryCode,
                        country: item.address.country,
                        state: item.address.countrySubdivisionName
                    };
                }),
            });
        }
        case 'RESET_AUTO_COMPLETE_PLACES': {
            return Object.assign({}, state, {
                placesSearchResults: [],
            });
        }
        default: {
            break;
        }
    }
    return state;
}