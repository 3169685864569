import { useEffect, useRef, useState } from 'react';
import {
  ActionButton,
  CheckboxVisibility,
  IColumn,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Text,
} from '@fluentui/react';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { BasicList, GLOBALENUMS, IconSvg } from 'modeling-tool';
import { connect, ConnectedProps } from 'react-redux';
import { Popover } from 'modeling-tool';
import { RootState } from '../../../../config/store';
import { __ } from 'modeling-tool';
import {
  addADGroupBulkAction,
  addEmployeeBulkAction,
  fetchEmployeeByGroupAction,
  listMemberOfGroup,
  renderIdentites,
  sendEmail,
} from '../../../../store/actions/employee-actions';
import { Employee } from '../../../../ts/interfaces';
import { useStyles } from './member-list-styles';
import { useStyleProps } from '../../../../hooks';
import { useBoolean } from '@fluentui/react-hooks';
import MailData from './email-body.json';
import { msalInstance } from '../../../..';
import { GetPersonAvatar } from '../../../../components';
import { updateEmployeesMetadataFromMS } from '../../../../utils';

interface MemberListProps {
  permissionGroup: string;
  headerText: string;
}

interface EmployeeFormValues {
  username: string;
  group: string;
}

const MemberList = (props: PropsFromRedux & MemberListProps) => {
  const {
    fetchEmployeeByGroupAction,
    addEmployeeBulkAction,
    addADGroupBulkAction,
    listMemberOfGroup,
    sendEmail,
    renderIdentites,
    permissionGroup,
    headerText,
    UserReducer: { isLicenseTest },
  } = props;

  const [localEmployees, setLocalEmployees] = useState<Employee[]>([]);
  const [paidLicences, setPaidLicences] = useState<number>(0);
  const styleProps = useStyleProps();
  const classes = useStyles();
  const pickerUser: any = useRef();
  const pickerGroup: any = useRef();
  const [showError, { setTrue: viewError, setFalse: dismissError }] = useBoolean(false);
  const [isReloadMSPickerElement, { setTrue: reloadPicker, setFalse: initialState }] = useBoolean(false);

  useEffect(() => {
    fetchEmployeeByGroupAction(permissionGroup, (result) => {
      setPaidLicences(result.data.meta.paidLicenses);
      if (result.data.objects && result.data.objects.length > 0) {
        updateEmployeesMetadataFromMS(result.data.objects, updateLocalEmployeesCallBack);
      }
    });
  }, []);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        dismissError();
      }, 3000);
    }
  }, [showError]);

  useEffect(() => {
    if (isReloadMSPickerElement) {
      initialState();
    }
  }, [pickerUser.current, isReloadMSPickerElement]);

  useEffect(() => {
    if (isReloadMSPickerElement) {
      initialState();
    }
  }, [pickerGroup.current, isReloadMSPickerElement]);

  const columns: IColumn[] = [
    {
      key: 'name',
      name: __('name'),
      fieldName: 'username',
      minWidth: 90,
      maxWidth: 160,
      className: classes.tableRowStyle,
      onRender: (record: Employee) => record.username && renderEntireRow(record),
    },
  ];

  const onEmployeeDelete = (record: Employee) => {
    const values = [{ username: record.username, group: 'clear' }];

    const payload = { objects: values, deletedObjects: [] };

    addEmployeeBulkAction(payload, (result) => {
      // Remove the employee from the local list
      const newEmployee = result.data.objects[0];
      const newLocalEmployees = localEmployees.filter(
        (employee: Employee) => employee.username !== newEmployee.username,
      );
      setLocalEmployees(newLocalEmployees);
    });
  };

  const handleUserSelectionChanged = (event: any): void => {
    let guestUserAdded = false;

    const selectedPeople = event.detail;
    selectedPeople.forEach((person: any) => {
      if (person.userPrincipalName && person.userPrincipalName.includes('#EXT#@')) {
        alert(__('guest users are not supported yet'));
        guestUserAdded = true;
      } else {
        const values = [{ username: person.id, group: permissionGroup }];
        const payload = { objects: values, deletedObjects: [] };
        addEmployeeBulkAction(
          payload,
          (result) => {
            // Append the new employee to the local list
            let newEmployee = result.data.objects[0];
            newEmployee = {
              ...newEmployee,
              displayName: person.displayName,
              email: person.mail,
              jobTitle: person.jobTitle,
              businessPhones: person.businessPhones,
              personImage: person.personImage,
            };

            let updatedEmployees = [...localEmployees, newEmployee];

            updatedEmployees = updatedEmployees.sort((a, b) => {
              const nameA = a.displayName || '';
              const nameB = b.displayName || '';
              return nameA.localeCompare(nameB);
            });
            setLocalEmployees(updatedEmployees);

            renderIdentites(newEmployee.username, (identities) => {
              notifyNewUser(identities);
            });

            // Clear the selection
            pickerUser.current.selectedPeople = [];
            reloadPicker();
          },
          (error) => {
            console.log('error', error);
            viewError();

            // Clear the selection
            pickerUser.current.selectedPeople = [];
            reloadPicker();
          },
        );
      }
    });

    if (guestUserAdded) {
      pickerUser.current.selectedPeople = [];
      return;
    }
  };

  const handleGroupSelectionChanged = (event: any): void => {
    const values: EmployeeFormValues[] = [];

    const selectedGroup = event.detail;

    selectedGroup.forEach((group: any) => {
      listMemberOfGroup(group.id, (data) => {
        if (data) {
          data.value.map((employee: any) => {
            values.push({ username: employee.id, group: permissionGroup } as EmployeeFormValues);
            renderIdentites(employee.username, (identities) => {
              notifyNewUser(identities);
            });
          });

          const payload = { employees: values };
          addADGroupBulkAction(payload, (data) => {
            fetchEmployeeByGroupAction(permissionGroup, (result) => {
              if (result.data.objects && result.data.objects.length > 0) {
                updateEmployeesMetadataFromMS(result.data.objects, updateLocalEmployeesCallBack);
              }
            });

            // Clear the selection
            pickerGroup.current.selectedPeople = [];
            reloadPicker();
          });
        }
      });
    });
  };

  const notifyNewUser = (identities: any) => {
    const bodyEmail = renderEmailBody(identities.value[0].issuerAssignedId);
    sendEmail(bodyEmail);
  };

  const renderEmailBody = (recipient: string) => {
    const newMail = JSON.parse(JSON.stringify(MailData));
    newMail.message.subject = __(newMail.message.subject);

    const account = msalInstance.getAllAccounts();
    const name = account[0].name || '';
    const url = window.location.origin;
    let text = __(newMail.message.body.content);
    text = text.replace('$ADMIN', name).replace('$GROUP', __(permissionGroup));
    text = text.replace(/\$URL/g, url);
    newMail.message.body.content = text;

    newMail.message.toRecipients[0].emailAddress.address = recipient;
    return newMail;
  };

  const renderEntireRow = (record: Employee) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div className={classes.rowFlex} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className={classes.customPersonName}>
          <div style={{ marginRight: '9px' }}>
            <GetPersonAvatar displayName={record.displayName ?? 'U K'} personImage={record.personImage} />
          </div>
          <div>{record.displayName}</div>
        </div>
        <div>
          <Popover
            title={__('Remove employee')}
            content={__('Are you sure, you want to remove the selected employee')}
            target={`delete-employee-${record.id}`}
            enableConfirm={true}
            onOk={() => onEmployeeDelete(record)}
            onCancel={() => {
              setIsHovered(false);
            }}
          >
            <ActionButton
              style={{ visibility: isHovered ? 'visible' : 'hidden' }}
              id={`delete-employee-${record.id}`}
              iconProps={{ iconName: 'Delete' }}
            />
          </Popover>
        </div>
      </div>
    );
  };

  const updateLocalEmployeesCallBack = (employees: Employee[]) => {
    setLocalEmployees(employees);
  };

  const renderCardTitle = (): JSX.Element => {
    return (
      <>
        <div className={classes.cardTitle}>
          <div className={classes.cardTitleFlexContainer}>
            <span style={{ textAlign: 'left' }}>{__(permissionGroup)}</span>
            <span style={{ textAlign: 'right' }}>
              {localEmployees.length}/{isLicenseTest ? '~' : paidLicences < 0 ? 0 : paidLicences} <br />{' '}
              <Text variant="small" style={{ color: `${styleProps.theme.palette.themePrimary}` }}>
                {__('licences in use')}
              </Text>
            </span>
          </div>
          <p className={classes.cardTitleParagraph}>{headerText}</p>
        </div>
      </>
    );
  };

  const renderCardBody = (): JSX.Element => {
    return (
      <>
        {showError && (
          <MessageBar
            delayedRender={false}
            messageBarType={MessageBarType.error}
            styles={{
              root: {
                position: 'absolute',
                top: '10%',
                left: '30%',
                width: 'fitContentToBounds',
              },
            }}
          >
            {<div>{__('Users can only be assigned to one permission group at a time')}</div>}
          </MessageBar>
        )}
        <div className={classes.cardBody}>
          <div className={classes.cardFlex}>
            <div className={classes.peoplePickerContainer}>
              <div className={classes.peoplePickerIcon}>
                <span>
                  <IconSvg svgName="Search" height={13} width={13} />
                </span>
              </div>
              <div className={classes.peoplePickerWrapper}>
                <PeoplePicker
                  ref={pickerUser}
                  selectionMode="single"
                  selectionChanged={handleUserSelectionChanged}
                  type={PersonType.person}
                  userType={UserType.user}
                  placeholder={__('add person')}
                  disabled={isLicenseTest ? false : localEmployees.length === paidLicences}
                />
              </div>
            </div>
            <div className={classes.peoplePickerContainer} style={{ marginLeft: '10px' }}>
              <div className={classes.peoplePickerIcon}>
                <span>
                  <IconSvg svgName="Search" height={13} width={13} />
                </span>
              </div>
              <div className={classes.peoplePickerWrapper}>
                <PeoplePicker
                  ref={pickerGroup}
                  selectionMode="single"
                  selectionChanged={handleGroupSelectionChanged}
                  type={PersonType.group}
                  userType={UserType.user}
                  placeholder={__('add from AD group')}
                  disabled={isLicenseTest ? false : localEmployees.length === paidLicences}
                />
              </div>
            </div>
          </div>
          <div className={classes.basicListWrapper}>
            <BasicList
              setKey={GLOBALENUMS.BASICLISTTYPES.MEMBERS_LIST}
              items={localEmployees}
              columns={columns}
              isHeaderVisible={false}
              checkboxVisibility={CheckboxVisibility.hidden}
              selectionMode={SelectionMode.none}
            ></BasicList>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.employeeCard}>
        <div>{renderCardTitle()}</div>
        <div>{renderCardBody()}</div>
      </div>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, {
  fetchEmployeeByGroupAction,
  addEmployeeBulkAction,
  addADGroupBulkAction,
  listMemberOfGroup,
  sendEmail,
  renderIdentites,
});
export default connector(MemberList);
