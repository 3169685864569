import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';
import { PaymentSession } from '../../ts/interfaces';
import { GetAzureMapsKey } from '../../config/config';

export const initLicenceCheckOutAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: `license/get_users_count/`,
        action: 'INIT_LICENCE_CHECK_OUT',
        cb,
        ecb,
    });

export const initLicenceCheckOutLicenseExpiryAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: `licenseexpiry/get_users_count/`,
        action: 'INIT_LICENCE_CHECK_OUT',
        cb,
        ecb,
    });

export const updateLicenceCheckOutAction = (permissionGroupName: string, cartCount: number) =>
    UseAction({
        normalAction: true,
        action: 'UPDATE_LICENCE_CHECK_OUT',
        payload: {
            groupName: permissionGroupName,
            cartCount: cartCount,
        }
    });

export const initNewPlanLicenceCheckOutAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: `license/get_users_count/`,
        action: 'INIT_NEW_PLAN_LICENCE_CHECK_OUT',
        cb,
        ecb,
    });

export const initNewPlanLicenceCheckOutLicenseExpiryAction = (cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: `licenseexpiry/get_users_count/`,
        action: 'INIT_NEW_PLAN_LICENCE_CHECK_OUT',
        cb,
        ecb,
    });

export const updateNewPlanLicenceCheckOutAction = (permissionGroupName: string, cartCount: number) =>
    UseAction({
        normalAction: true,
        action: 'UPDATE_NEW_PLAN_LICENCE_CHECK_OUT',
        payload: {
            groupName: permissionGroupName,
            cartCount: cartCount,
        }
    });

export const payOnlineStripeAction = (data: PaymentSession, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: 'license/get_checkout_session/',
        method: GLOBALENUMS.HTTPREQUEST.POST,
        action: 'PAY_ONLINE_STRIPE',
        reqPayload: data,
        cb,
        ecb,
    });

export const payOnlineStripeLicenseExpiryAction = (data: PaymentSession, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: 'licenseexpiry/get_checkout_session/',
        method: GLOBALENUMS.HTTPREQUEST.POST,
        action: 'PAY_ONLINE_STRIPE',
        reqPayload: data,
        cb,
        ecb,
    });

export const updatePayOnlineStripeCheckOutAction = (data: PaymentSession, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: 'license/update_subscription/',
        method: GLOBALENUMS.HTTPREQUEST.POST,
        action: 'UPDATE_PAY_ONLINE_STRIPE',
        reqPayload: data,
        cb,
        ecb,
    });

export const payOnAccountAction = (data: PaymentSession, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: 'license/pay_by_invoice/',
        method: GLOBALENUMS.HTTPREQUEST.POST,
        action: 'PAY_ON_ACCOUNT',
        reqPayload: data,
        cb,
        ecb,
    });

export const payOnAccountActionLicenseExpiryAction = (data: PaymentSession, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: 'licenseexpiry/pay_by_invoice/',
        method: GLOBALENUMS.HTTPREQUEST.POST,
        action: 'PAY_ON_ACCOUNT',
        reqPayload: data,
        cb,
        ecb,
    });

export const updatePayOnAccountActionCheckOutAction = (data: PaymentSession, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
    UseAction({
        url: 'license/update_pay_by_invoice_subscription/',
        method: GLOBALENUMS.HTTPREQUEST.POST,
        action: 'UPDATE_PAY_ON_ACCOUNT',
        reqPayload: data,
        cb,
        ecb,
    });

export const getAutoCompletePlacesAction = (searchQearch: string, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) => {
    const azureMapsKey = GetAzureMapsKey();
    const azureMapsSearchBaseUrl = `https://atlas.microsoft.com/search/address/json?api-version=1.0&subscription-key=${azureMapsKey}&typeahead=true&lat=0&lon=0`;

    return UseAction({
        fullUrl: `${azureMapsSearchBaseUrl}&query=${searchQearch}`,
        method: GLOBALENUMS.HTTPREQUEST.GET,
        action: 'GET_AUTO_COMPLETE_PLACES',
        isTokenNotRequired: true,
        cb,
        ecb,
    });
}

export const resetAutoCompletePlacesAction = () =>
    UseAction({
        normalAction: true,
        action: 'RESET_AUTO_COMPLETE_PLACES',
    });