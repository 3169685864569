import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  buttonStyles: {
    ...shorthands.border('none'),
    '& span': {
      fontSize: '24px',
      width: '24px',
      height: '24px',
    },
  },
  statusTagNew: {
    height: '34px',
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 600,
    ...shorthands.borderRadius('2px'),
  },
});
