import { GLOBALENUMS } from 'modeling-tool';
import { UseAction, UseRequestOnlyAction } from './base-actions';

export const updateFullModelAction = (data: any, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: `modelerfullmodel/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_FULL_MODEL',
    reqPayload: data,
    cb,
    ecb,
  });

export const getFullModelByInstanceId = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `modelerfullmodel/?from_instance=${id}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_FULL_MODEL_BY_INSTANCE_ID',
    cb,
  });

export const getFlatFullModelByInstanceId = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `modelerfullmodel_mini?from_instance=${id}&element_informations=true`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_FLAT_FULL_MODEL_BY_INSTANCE_ID',
    cb,
  });

export const getFullModelByProcessId = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `modelerfullmodel?from_instance__source_process=${id}&from_instance__state__type=${GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_MODELING}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_FULL_MODEL_BY_PROCESS_ID',
    cb,
  });

export const updateGraphData = (data: any, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: `modelerfullmodel/updategraphdata/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_GRAPH_DATA',
    reqPayload: data,
    cb,
    ecb,
  });

export const fetchModelerNodeColor = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `modelernodetypedata/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_MODELER_NODE_COLOR',
    cb,
  });

export const updateModelerNodeColor = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `modelernodetypedata/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_MODELER_NODE_COLOR',
    reqPayload: data,
    cb,
  });

export const clearModelerDataAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_MODELER_REDUCER',
  });

export const getModelOptimizationsAction = (
  id: number,
  cb?: (...args: any[]) => void,
  ecb?: (...args: any[]) => void,
  debug?: boolean,
) =>
  UseRequestOnlyAction({
    url: debug ? `modelerfullmodel/optimize/?debug` : `modelerfullmodel/optimize/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    reqPayload: { id: id },
    cb,
    ecb,
  });
