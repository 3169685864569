import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { PersonDisplayName } from '../..';
import { IColumn, SelectionMode, TooltipHost, DirectionalHint, Text } from '@fluentui/react';
import { ApprovedBy, ProcessInstance } from '../../../ts/interfaces';
import { getDateFormatted } from '../../../config/utils';
import { Person, ViewType } from '@microsoft/mgt-react';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { BasicList } from 'modeling-tool';
import { useStyles } from './approvers-list.styles';

const ApproversList = (props: PropsFromRedux & { item?: ProcessInstance }) => {
  const {
    ProcessInstanceReducer: { selectedInstance },
    item,
  } = props;

  const classes = useStyles();

  const columns: IColumn[] = [
    {
      key: 'approvedBy',
      name: __('approved by'),
      fieldName: 'user',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      onRender: ({ user }: ApprovedBy) =>
        user && (
          <Person userId={user.username}>
            <PersonDisplayName prefix={``} />
          </Person>
        ),
    },
    {
      key: 'approvedDate',
      name: __('date'),
      fieldName: 'approvedDate',
      minWidth: 90,
      maxWidth: 150,
      isResizable: true,
      onRender: ({ approvedDate }: ApprovedBy) => approvedDate && <>{getDateFormatted(approvedDate, 'lll')}</>,
    },
    {
      key: 'status',
      name: __('status'),
      fieldName: 'status',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      onRender: ({ status }: ApprovedBy) => status && <>{__(status)}</>,
    },
    {
      key: 'comment',
      name: __('comment'),
      fieldName: 'comment',
      minWidth: 90,
      maxWidth: 130,
      isResizable: true,
      onRender: (item: ApprovedBy) => renderComment(item),
    },
  ];

  const renderComment = (item?: ApprovedBy) => {
    if (item) {
      return (
        <TooltipHost id={`${item.id}`} content={item.comment} directionalHint={DirectionalHint.bottomCenter}>
          <div aria-describedby={`${item.id}`}>{item.comment}</div>
        </TooltipHost>
      );
    }
  };
  console.log('selectedInstance', selectedInstance);

  return (
    <>
      {window.location.href.includes('/approval') && (
        <div className={classes.dataArea}>
          <div className={classes.title}>{__('task from')}</div>
          <Person
            view={ViewType.oneline}
            userId={
              item
                ? item.approvedBy.filter((i) => i.isInitialApprover)[0].user?.username
                : selectedInstance?.approvedBy.filter((i) => i.isInitialApprover)[0].user?.username
            }
          ></Person>
        </div>
      )}
      <div className={classes.dataArea}>
        <div className={classes.title}>{__('changes made')}</div>
        <Text>{item ? item.sourceProcess.changesMade : selectedInstance?.sourceProcess.changesMade}</Text>
      </div>
      <div className={classes.dataArea}>
        <div className={classes.titleList}>{__('approval overview')}</div>
        <BasicList
          setKey={GLOBALENUMS.BASICLISTTYPES.APPROVERS_LIST}
          items={item ? item.approvedBy : selectedInstance?.approvedBy || []}
          columns={columns}
          autoHeight={true}
          selectionMode={SelectionMode.none}
        />
      </div>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessInstanceReducer }: RootState) => ({ ProcessInstanceReducer });
const connector = connect(mapStateToProps, {});
export default connector(ApproversList);
