import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  aiModelDiv: {
    ...shorthands.margin('25px', '0', '0px', '0'),
  },
  aiModelSpanText: {
    ...shorthands.margin('0', '25px', '0', '0'),
  },
});
