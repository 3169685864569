import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { __ } from 'modeling-tool';
import { ButtonProps, Status } from '../../../ts/interfaces';
import { useStyles } from './status-tag-split-button-styles';

interface StatusTagSplitButtonProps {
  processState: Status;
  menuButtons: ButtonProps[];
}

const StatusTagSplitButton = (props: StatusTagSplitButtonProps) => {
  const { processState, menuButtons } = props;
  const styles = useStyles();
  return menuButtons.length ? (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        <MenuButton className={styles.buttonStyles} style={{ backgroundColor: processState.color }}>
          {__(processState.projectStatusName)}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {menuButtons.map((button, index) => (
            <MenuItem icon={button.icon} key={`menu-item-${index}`} onClick={button.onClick} disabled={button.disabled}>
              {button.text}
            </MenuItem>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  ) : (
    <div
      className={styles.statusTagNew}
      style={{
        backgroundColor: processState.color,
      }}
    >
      {__(processState.projectStatusName)}
    </div>
  );
};

export default StatusTagSplitButton;
