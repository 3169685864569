import { Parser } from 'html-to-react';
import { Modal, TextField, __ } from 'modeling-tool';
import { useEffect, useState } from 'react';
import { getStyles } from './permission-request-modal-styles';
import { useBoolean } from '@fluentui/react-hooks';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../config/store';
import { useForm } from 'react-hook-form';
import { requestPermission } from '../../store/actions/request-permission';
import { Checkbox, DefaultButton, PrimaryButton } from '@fluentui/react';
import { MinorPermission, PermissionModalListItem } from '../../ts/interfaces';
import { useCheckUserPermissions } from '../../hooks/check-user-permissions';
import { setNotifyPermissionRequestAction, setRemindPermissionRequestAction } from '../../store/actions/user-actions';

const PermissionRequestModal = (props: PropsFromRedux) => {
  const { requestPermission, setRemindPermissionRequestAction, setNotifyPermissionRequestAction } = props;

  const [openEmailModal, { toggle: toggleOpenEmailModal }] = useBoolean(false);

  const [rerender, { toggle: toggleRerender }] = useBoolean(false);

  const { control, reset, handleSubmit } = useForm();
  const [ungrantedPermissions, setUngrantedPermissions] = useState<string[]>([]);
  const { permissionGroups, setOpenPermissionsModal, openPermissionsModal } = useCheckUserPermissions();

  const styles = getStyles();

  useEffect(() => {
    reset({ emailAddress: '' });
  }, [reset]);

  useEffect(() => {
    const ungrantedPermissions = permissionGroups.flatMap((group) =>
      group.permissions
        .filter((permission) => !permission.granted && permission.checked)
        .map((permission) => permission.permission),
    );
    setUngrantedPermissions(ungrantedPermissions);
  }, [rerender]);

  const onSubmit = () => {
    handleSubmit(
      (data) => {
        data = { ...data, permissions: ungrantedPermissions };
        requestPermission(data);
        toggleOpenEmailModal();
        setOpenPermissionsModal(false);
        setNotifyPermissionRequestAction();
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const _onChange = (isChecked: boolean, permission: MinorPermission) => {
    if (!permission.granted) {
      permission.checked = isChecked;
      toggleRerender();
    }
  };

  const onClickRemindMeLater = () => {
    setRemindPermissionRequestAction();
    setOpenPermissionsModal(false);
  };

  const renderFooter = () => {
    return (
      <>
        <DefaultButton
          onClick={openEmailModal ? () => toggleOpenEmailModal() : () => onClickRemindMeLater()}
          text={openEmailModal ? __('back') : __('remind me later')}
          className={styles.footerButtons}
        />
        {!openEmailModal && (
          <DefaultButton
            disabled={ungrantedPermissions.length ? false : true}
            onClick={() => toggleOpenEmailModal()}
            text={__('next')}
            className={styles.footerButtons}
          />
        )}
        {openEmailModal && (
          <PrimaryButton text={__('request permission')} onClick={() => onSubmit()} className={styles.footerButtons} />
        )}
      </>
    );
  };

  const onCancel = () => {
    setOpenPermissionsModal(false);
    setNotifyPermissionRequestAction();
  };

  return (
    <Modal
      isModalOpen={openPermissionsModal}
      title={__('welcome to aiio')}
      onCancel={openEmailModal ? () => toggleOpenEmailModal() : () => onCancel()}
      customFooter={renderFooter()}
      hideFooter={true}
    >
      {openEmailModal ? (
        <TextField
          label={__('please tell us the email address of your M365 administrator')}
          control={control}
          name={'emailAddress'}
          required={true}
          rules={{
            required: __('This field is required'),
            maxLength: { value: 150, message: __('too many characters') },
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: __('Please enter a valid email address'),
            },
          }}
        />
      ) : (
        <>
          <div>{Parser().parse(__('missing permission modal text'))}</div>
          <div className={styles.permissionRequirementsList}>
            {permissionGroups.map((item: PermissionModalListItem) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={styles.groupHeader}>{__(item.permissionType)}</div>
                {item.permissions.map((permission: MinorPermission) => (
                  <div className={styles.infoTextDiv}>
                    <Checkbox
                      className={styles.checkMarkIcon}
                      onChange={(e, isChecked) => _onChange(!!isChecked, permission)}
                      checked={permission.checked}
                      disabled={permission.granted}
                    />
                    {__(`${permission.infoText}`)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </Modal>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, {
  requestPermission,
  setRemindPermissionRequestAction,
  setNotifyPermissionRequestAction,
});
export default connector(PermissionRequestModal);
