import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal, TextField, __, GLOBALENUMS } from 'modeling-tool';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { DefaultButton } from '@fluentui/react';
import { ProcessInstance } from '../../../../ts/interfaces';
import { RootState } from '../../../../config/store';
import {
  selectProcessInstanceAction,
  updateProcessInstanceAction,
} from '../../../../store/actions/process-instance-actions';
import { getRACIRelationsObject } from '../../../../config/utils';
import { useStyles } from './recording-button-section-styles';
import { setNewProcessInstanceStateAction } from '../../../../store/actions/process-state-actions';
import { useNavigate } from 'react-router';

interface CommentModalFormProps {
  comment: string;
}

interface CommentModalProps extends PropsFromRedux {
  selectedProcessInstance: ProcessInstance;
}

const CommentModal = (props: CommentModalProps) => {
  const { selectedProcessInstance, setNewProcessInstanceStateAction } = props;
  const {
    UserReducer: { username },
  } = props;
  const [status, setStatus] = useState<GLOBALENUMS.APPROVALSTATUS>(GLOBALENUMS.APPROVALSTATUS.IN_APPROVAL);
  const [isCommentRequired, setIsCommentRequired] = useState<boolean>(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const styles = useStyles();

  const alert = useAlert();
  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const hasApprovedUser = () => {
    return selectedProcessInstance?.approvedBy?.some(
      (item) => item.user?.username === username && item.status === GLOBALENUMS.APPROVALSTATUS.IN_APPROVAL,
    );
  };

  const handleApproveModel = (status: GLOBALENUMS.APPROVALSTATUS) => {
    setIsCommentModalVisible(true);
    setStatus(status);
    status === GLOBALENUMS.APPROVALSTATUS.REJECTED ? setIsCommentRequired(true) : setIsCommentRequired(false);
    reset({});
  };

  const handleCommentModalOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onCommentFinish(data as CommentModalFormProps);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const getStateToSet = (): GLOBALENUMS.PROCESSSTATUS | null => {
    switch (selectedProcessInstance?.state.type) {
      case GLOBALENUMS.PROCESSSTATUS.INSTANCE_MODELING_IN_APPROVAL: {
        return status === GLOBALENUMS.APPROVALSTATUS.APPROVED
          ? GLOBALENUMS.PROCESSSTATUS.MODELING_APPROVED
          : GLOBALENUMS.PROCESSSTATUS.INSTANCE_REJECTED;
      }
      case GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_APPROVAL: {
        return status === GLOBALENUMS.APPROVALSTATUS.APPROVED
          ? GLOBALENUMS.PROCESSSTATUS.RECORDING_APPROVED
          : GLOBALENUMS.PROCESSSTATUS.INSTANCE_REJECTED;
      }
      default:
        return null;
    }
  };

  const onCommentFinish = (values: CommentModalFormProps) => {
    const approvedBy = selectedProcessInstance?.approvedBy?.map((item) => {
      if (item.user?.username == username) {
        return {
          id: item.id,
          resourceUri: item.resourceUri,
          status: status,
          comment: values['comment'],
        };
      } else
        return {
          resourceUri: item.resourceUri,
        };
    });

    const raciValues = getRACIRelationsObject(selectedProcessInstance);
    const instance: ProcessInstance = {
      ...selectedProcessInstance,
      ...raciValues,
      approvedBy: approvedBy,
    };
    const newState = getStateToSet();
    if (newState) {
      setNewProcessInstanceStateAction(instance, newState, (res: any) => {
        alert.success(__(`process instance was ${status.toString().toLowerCase()} successfully!`));
        setIsCommentModalVisible(false);
        props.selectProcessInstanceAction(res.data);
        navigate(`/tasks/approval`);
      });
    }
  };

  return (
    <>
      {selectedProcessInstance && hasApprovedUser() && (
        <>
          <DefaultButton
            iconProps={{ iconName: 'Blocked12' }}
            title={__('reject')}
            onClick={() => handleApproveModel(GLOBALENUMS.APPROVALSTATUS.REJECTED)}
            className={styles.blinkHint}
          />
          <DefaultButton
            iconProps={{ iconName: 'CheckMark' }}
            title={__('approve')}
            onClick={() => handleApproveModel(GLOBALENUMS.APPROVALSTATUS.APPROVED)}
            className={styles.blinkHint}
          />
        </>
      )}
      {selectedProcessInstance && (
        <>
          <Modal
            title={isCommentRequired ? __('reject') : __('accept')}
            isModalOpen={isCommentModalVisible}
            onSave={handleCommentModalOk}
            onCancel={() => setIsCommentModalVisible(false)}
            saveButtonText={isCommentRequired ? __('send') : __('ok')}
            enableProgress={showLoading}
          >
            {isCommentRequired ? (
              <form>
                <TextField
                  control={control}
                  name={'comment'}
                  required={true}
                  rules={{ required: __('This field is required') }}
                  multiline
                  rows={3}
                  label={__('comment')}
                ></TextField>
              </form>
            ) : (
              <>{__('are you sure, you want to accept the model?')}</>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, {
  updateProcessInstanceAction,
  selectProcessInstanceAction,
  setNewProcessInstanceStateAction,
});
export default connector(CommentModal);
