/* eslint-disable @typescript-eslint/no-explicit-any */
import { prepScopes, Providers } from '@microsoft/mgt-element';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { apiAuthority, apiRequest } from '../../config/authConfig';
import { GetApiUrl, IsTeamsApp } from '../../config/config';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { msalInstance } from '../..';
import { store } from '../../AppTeams';
import { mapToCamel, mapToSnake, GLOBALENUMS, getCurrentLanguage } from 'modeling-tool';

axios.defaults.headers.common['Authorization'] = `Bearer`;

interface RequestOptions {
  normalAction?: boolean;
  action?: string;
  fullUrl?: string;
  url?: string;
  scopes?: string;
  method?: GLOBALENUMS.HTTPREQUEST;
  reqPayload?: any | undefined;
  payload?: any;
  isTokenNotRequired?: boolean
  cb?: (...args: any[]) => void;
  ecb?: (...args: any[]) => void;
  mgtBulkRequests?: any;
}

// get a token before every API call. If the token exisits and a is still valid, it won't be reissued.
// otherwise the token will be renewd
const getToken = (cb: any) => {
  const account = msalInstance.getAllAccounts();
  const request = {
    ...apiRequest,
    authority: apiAuthority,
    account: account[0] || undefined,
  };

  msalInstance
    .acquireTokenSilent(request)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      const accessToken = accessTokenResponse.accessToken;
      // Call your API with token
      cb(accessToken);
    })
    .catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance
          .acquireTokenPopup(request)
          .then(function (accessTokenResponse) {
            // Acquire token interactive success
            const accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            cb(accessToken);
          })
          .catch(function (error) {
            // Acquire token interactive failure
            console.log(error);
          });
      }
      console.log(error);
    });
};

// Same function in context of teams using teams fx
const getTokenTeams = (cb: any) => {
  const state = store.getState();
  if (state.UserReducer.teamsfx) {
    const credentials = state.UserReducer.teamsfx.getCredential();
    credentials.getToken(apiRequest.scopes).then(function (data) {
      if (data) {
        cb(data.token);
      }
    });
  }
};

// handle the calls where token is not necessary
const sendRequestNoToken = (cb: any) => {
  cb();
}

const sendRequest = (options: RequestOptions, successCallback: (...args: any[]) => void) => {
  const { fullUrl, url, method, reqPayload, ecb, isTokenNotRequired } = options;
  const mapReqPayload = mapToSnake(reqPayload);

  const requestUrl = fullUrl ? fullUrl : `${GetApiUrl()}v1/${url}`;
  let req;

  const tokenFunction: (cb: any) => void = isTokenNotRequired ? sendRequestNoToken : (IsTeamsApp() ? getTokenTeams : getToken);
  tokenFunction((accessToken?: string) => {
    let config = {};
    if (accessToken) {
      config = {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          Language: getCurrentLanguage(),
        },
      };
    }

    switch (method) {
      case GLOBALENUMS.HTTPREQUEST.POST: {
        req = axios.post(requestUrl, mapReqPayload, config);
        break;
      }
      case GLOBALENUMS.HTTPREQUEST.PUT: {
        req = axios.put(requestUrl, mapReqPayload, config);
        break;
      }
      case GLOBALENUMS.HTTPREQUEST.PATCH: {
        req = axios.patch(requestUrl, mapReqPayload, config);
        break;
      }
      case GLOBALENUMS.HTTPREQUEST.DELETE: {
        req = axios.delete(requestUrl, config);
        break;
      }
      default: {
        req = axios.get(requestUrl, config);
      }
    }
    req
      .then((response: AxiosResponse<any>) => {
        successCallback && successCallback(response);
      })
      .catch((error: AxiosError) => {
        ecb && ecb(error, true);
      });
  });
};

export const UseAction = (options: RequestOptions): Dispatch<any> => {
  const { normalAction, action, payload, cb } = options;

  return (dispatch) => {
    if (normalAction) {
      action && dispatch({ type: action, payload: payload });
      cb && cb();
      return;
    }
    sendRequest(options, (response) => {
      const mapResponse = mapToCamel(response);
      action && dispatch({ type: action, payload: mapResponse.data || payload });
      cb && cb(mapResponse);
    });
  };
};

export const UseRequestOnlyAction = (options: RequestOptions) => {
  sendRequest(options, (response) => {
    const mapResponse = mapToCamel(response.data.objects ? response.data.objects : response.data);

    if (mapResponse) {
      options.cb && options.cb(mapResponse);
    } else {
      options.cb && options.cb(response);
    }
  });
};

export const UseGraphAction = (options: RequestOptions): Dispatch<any> => {
  const { action, method, scopes, url, payload, cb, ecb } = options;
  return (dispatch) => {
    const provider = Providers.globalProvider;
    if (provider && url && scopes) {
      const graphClient = provider.graph.client;
      let graphRequest;
      switch (method) {
        case GLOBALENUMS.HTTPREQUEST.GET:
          graphRequest = graphClient.api(url).middlewareOptions(prepScopes(scopes)).get();
          break;

        case GLOBALENUMS.HTTPREQUEST.POST:
          graphRequest = graphClient.api(url).middlewareOptions(prepScopes(scopes)).post(payload);
          break;

        default:
          graphRequest = graphClient.api(url).middlewareOptions(prepScopes(scopes)).get();
          break;
      }

      graphRequest
        .then((response) => {
          action && dispatch({ type: action, payload: response || payload });
          cb && cb(response);
        })
        .catch((error) => {
          ecb && ecb(error, true);
        });
    }
  };
};

export const UserBulkGraphAction = (options: RequestOptions): void => {
  const { method, scopes, cb, ecb, mgtBulkRequests } = options;
  const provider = Providers.globalProvider;

  if (provider && scopes && mgtBulkRequests) {
    const graphClient = provider.graph.client;
    const batchPayload = { requests: mgtBulkRequests };

    let graphBulkRequest;
    switch (method) {
      case GLOBALENUMS.HTTPREQUEST.POST:
        graphBulkRequest = graphClient.api('/$batch').middlewareOptions(prepScopes(scopes)).post(batchPayload);
        break;
    }

    if (graphBulkRequest) {
      graphBulkRequest
        .then((response) => {
          cb && cb(response);
        })
        .catch((error) => {
          console.log('error', error);
          ecb && ecb(error, true);
        });
    }
  }
};