import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  card: {
    height: '100%',
    width: '100%',
    ...shorthands.border(0, 'solid', '#ccc'),
    ...shorthands.borderRadius('2px'),
  },
  caretContainer: {
    cursor: 'pointer',
    color: tokens.colorNeutralForeground2BrandHover,
    backgroundColor: 'transparent',
    zIndex: 300,
    position: 'absolute',
    ...shorthands.border('0px'),
  },
  processTreeContainer: {
    width: '100%',
    height: '100%',
  },
  detailsContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  instanceListContainer: {
    width: '400px',
    maxWidth: '400px',
    height: '100%',
    backgroundColor: tokens.colorStrokeFocus1,
    zIndex: 2,
    bottom: 0,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground1Selected),
  },
});
