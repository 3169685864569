import React, { useEffect, useState } from 'react';
import { __ } from 'modeling-tool';
import {
  IColumn,
  TooltipHost,
  DirectionalHint,
  IconButton,
  SelectionMode,
  Stack,
  ActionButton,
  Label,
  Pivot,
  PivotItem,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { connect, ConnectedProps } from 'react-redux';
import {
  fetchTenantsAction,
  fetchTenantsBuyerAction,
  fetchTenantsExpiredAction,
  fetchTenantsTerminatedAction,
} from '../../store/actions/tenant-dasboard-actions';
import { RootState } from '../../config/store';
import { Tenant } from '../../ts/interfaces';
import { getStyles } from './vendor-dashboard-styles';
import { getDateFormatted } from '../../config/utils';
import { TenantEditForm } from './tenant-edit-form';
import moment from 'moment';
import { Popover } from 'modeling-tool';
import { BasicList } from 'modeling-tool';

const VendorDashboard = (props: PropsFromRedux) => {
  const {
    TenantDashboardReducer: { tenant, buyers, expired, terminated },
    fetchTenantsAction,
    fetchTenantsBuyerAction,
    fetchTenantsExpiredAction,
    fetchTenantsTerminatedAction,
  } = props;
  const styles = getStyles();
  const [selectedTenant, setSelectedTenant] = useState<Tenant>();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [orderByColumns, setOrderByColumns] = useState<string>();
  const [isBuyerLoading, setIsBuyerLoading] = useState<boolean>(false);
  const [isTrialLoading, setIsTrialLoading] = useState<boolean>(false);
  const [isExpiredLoading, setIsExpiredLoading] = useState<boolean>(false);
  const [isTerminatedLoading, setIsTerminatedLoading] = useState<boolean>(false);

  const columns: IColumn[] = [
    {
      key: 'id',
      name: __('ID'),
      fieldName: 'id',
      minWidth: 20,
      maxWidth: 50,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ id }: Tenant) => <div className={styles.cellText}>{id}</div>,
    },
    {
      key: 'license__title',
      name: __('name'),
      fieldName: 'title',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ title }: Tenant) => <div className={styles.cellText}>{title}</div>,
    },
    {
      key: 'license__description',
      name: __('description'),
      fieldName: 'description',
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { description } }: Tenant) => (
        <div className={styles.cellText} title={description}>
          {description}
        </div>
      ),
    },
    {
      name: __('is active'),
      fieldName: 'license',
      key: 'license__is_active',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { isActive } }: Tenant) => <div className={styles.cellText}>{isActive ? 'Yes' : 'No'}</div>,
    },
    {
      name: __('purchased'),
      fieldName: 'purchased',
      key: 'license__purchased',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { purchased } }: Tenant) => (
        <div className={styles.cellText}>{purchased ? 'Yes' : 'No'}</div>
      ),
    },
    {
      name: __('terminated'),
      fieldName: 'terminated',
      key: 'license__terminated',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { terminated } }: Tenant) => (
        <div className={styles.cellText}>{terminated ? 'Yes' : 'No'}</div>
      ),
    },
    {
      name: __('valid until'),
      fieldName: 'license',
      key: 'license__valid_until',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { validUntil } }: Tenant) => (
        <div className={isBeforeToday(validUntil) ? styles.cellText : styles.highlightedText}>
          {validUntil && getDateFormatted(validUntil, 'DD.MM.YYYY')}
        </div>
      ),
    },
    {
      name: __('Viewer limit'),
      fieldName: 'license',
      key: 'license__viewer_limit',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { viewerLimit, viewerCount } }: Tenant) => (
        <div className={viewerCount > viewerLimit && viewerLimit != -1 ? styles.highlightedText : styles.cellText}>
          {viewerLimit} / {viewerCount}
        </div>
      ),
    },
    {
      name: __('Contributor limit'),
      fieldName: 'license',
      key: 'license__contributor_limit',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { contributorLimit, contributorCount } }: Tenant) => (
        <div
          className={
            contributorCount > contributorLimit && contributorLimit != -1 ? styles.highlightedText : styles.cellText
          }
        >
          {contributorLimit} / {contributorCount}
        </div>
      ),
    },
    {
      name: __('Manager limit'),
      fieldName: 'license',
      key: 'license__manager_limit',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { managerLimit, managerCount } }: Tenant) => (
        <div className={managerCount > managerLimit && managerLimit != -1 ? styles.highlightedText : styles.cellText}>
          {managerLimit} / {managerCount}
        </div>
      ),
    },
    {
      name: __('Administrator limit'),
      fieldName: 'license',
      key: 'license__administrator_limit',
      minWidth: 90,
      maxWidth: 120,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { administratorLimit, administratorCount } }: Tenant) => (
        <div
          className={
            administratorCount > administratorLimit && administratorLimit != -1
              ? styles.highlightedText
              : styles.cellText
          }
        >
          {administratorLimit} / {administratorCount}
        </div>
      ),
    },
    {
      name: __('process optimization'),
      fieldName: 'license',
      key: 'license__ai_limit',
      minWidth: 90,
      maxWidth: 120,
      onRender: ({ license: { aiLimitValue, aiLimitDefault } }: Tenant) => (
        <div className={styles.cellText}>
          {aiLimitValue} / {aiLimitDefault}
        </div>
      ),
    },
    {
      name: __('contract start date'),
      fieldName: 'license',
      key: 'license__contract_start_date',
      minWidth: 90,
      maxWidth: 150,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { contractStartDate } }: Tenant) => (
        <div className={styles.cellText}>{contractStartDate && getDateFormatted(contractStartDate, 'DD.MM.YYYY')}</div>
      ),
    },
    {
      name: __('hotness'),
      fieldName: 'stats',
      key: 'stats',
      minWidth: 90,
      maxWidth: 100,
      onRender: (tenant: Tenant) => (
        <Popover content={renderPopoverContent(tenant)} target={`send-process-${tenant.id}`}>
          <ActionButton id={`send-process-${tenant.id}`} iconProps={{ iconName: 'Frigid' }}>
            {tenant.license.stats.hotnessFactor}
          </ActionButton>
        </Popover>
      ),
    },
    {
      name: __('last login'),
      fieldName: 'lastLogin',
      key: 'license__last_login',
      minWidth: 90,
      maxWidth: 100,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ license: { lastLogin } }: Tenant) => (
        <div className={styles.cellText}>{lastLogin && getDateFormatted(lastLogin, 'DD.MM.YYYY HH:mm')}</div>
      ),
    },
    {
      name: '',
      key: 'actions',
      minWidth: 30,
      maxWidth: 30,
      onRender: (record: Tenant) => {
        return (
          <TooltipHost content={__('edit tenant')} directionalHint={DirectionalHint.bottomCenter}>
            <IconButton onClick={() => onTenantEdit(record)} iconProps={{ iconName: 'Edit' }} />
          </TooltipHost>
        );
      },
    },
  ];
  const [tableColumns, setTableColumns] = useState<IColumn[]>(columns);

  const isBeforeToday = (date?: Date): boolean => {
    const today = moment();
    const day = moment(date);
    return today.isBefore(day);
  };

  useEffect(() => {
    loadData(undefined);
  }, []);

  useEffect(() => {
    if (orderByColumns) {
      loadData(orderByColumns);
    }
  }, [orderByColumns]);

  const loadData = (orderByColumns?: string) => {
    setIsBuyerLoading(true);
    setIsTrialLoading(true);
    setIsExpiredLoading(true);
    setIsTerminatedLoading(true);

    fetchTenantsBuyerAction(orderByColumns, () => {
      setIsBuyerLoading(false);
    });
    fetchTenantsAction(orderByColumns, () => {
      setIsTrialLoading(false);
    });
    fetchTenantsExpiredAction(orderByColumns, () => {
      setIsExpiredLoading(false);
    });
    fetchTenantsTerminatedAction(orderByColumns, () => {
      setIsTerminatedLoading(false);
    });
  };

  const onTenantEdit = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setEditModalVisible(true);
  };

  const renderPopoverContent = (tenant: Tenant) => {
    return (
      <Stack>
        <Stack>
          <Label>{__('process count')}</Label>
          <Label>{tenant.license.stats.processCount}</Label>
        </Stack>
        <Stack>
          <Label>{__('process element count')}</Label>
          <Label>{tenant.license.stats.processElementCount}</Label>
        </Stack>
        <Stack>
          <Label>{__('employees count')}</Label>
          <Label>{tenant.license.stats.employeesCount}</Label>
        </Stack>
        <Stack>
          <Label>{__('comments count')}</Label>
          <Label>{tenant.license.stats.commentsCount}</Label>
        </Stack>
        <Stack>
          <Label>{__('login count')}</Label>
          <Label>{tenant.license.stats.loginCounter}</Label>
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <Stack>
        <Pivot>
          <PivotItem headerText={__('purchased') + ' (' + buyers.length + ')'}>
            {isBuyerLoading ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <BasicList
                items={buyers}
                columns={tableColumns}
                setTableColumns={setTableColumns}
                setOrderByColumns={setOrderByColumns}
                selectionMode={SelectionMode.none}
                className={styles.listContainer}
              />
            )}
          </PivotItem>
          <PivotItem headerText={__('trial') + ' (' + tenant.length + ')'}>
            {isTrialLoading ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <BasicList
                items={tenant}
                columns={tableColumns}
                setTableColumns={setTableColumns}
                setOrderByColumns={setOrderByColumns}
                selectionMode={SelectionMode.none}
                className={styles.listContainer}
              />
            )}
          </PivotItem>
          <PivotItem headerText={__('expired') + ' (' + expired.length + ')'}>
            {isExpiredLoading ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <BasicList
                items={expired}
                columns={tableColumns}
                setTableColumns={setTableColumns}
                setOrderByColumns={setOrderByColumns}
                selectionMode={SelectionMode.none}
                className={styles.listContainer}
              />
            )}
          </PivotItem>
          <PivotItem headerText={__('terminated') + ' (' + terminated.length + ')'}>
            {isTerminatedLoading ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <BasicList
                items={terminated}
                columns={tableColumns}
                setTableColumns={setTableColumns}
                setOrderByColumns={setOrderByColumns}
                selectionMode={SelectionMode.none}
                className={styles.listContainer}
              />
            )}
          </PivotItem>
        </Pivot>
      </Stack>

      <TenantEditForm tenant={selectedTenant} visible={editModalVisible} setVisible={setEditModalVisible} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ TenantDashboardReducer }: RootState) => ({ TenantDashboardReducer });
const connector = connect(mapStateToProps, {
  fetchTenantsBuyerAction,
  fetchTenantsAction,
  fetchTenantsExpiredAction,
  fetchTenantsTerminatedAction,
});
export default connector(VendorDashboard);
