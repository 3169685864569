import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Checkbox } from '@fluentui/react';
import { __ } from 'modeling-tool';
import { Dropdown, DropdownProps, Option, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';
import { useStyles } from './settings-group-styles';
import { RootState } from '../../../../config/store';
import { getCurrentUserAction, updateSettingAction } from '../../../../store/actions/user-actions';
import { Setting, SettingGroup } from '../../../../ts/interfaces';

interface SettingsGroupProps {
  group: SettingGroup;
}

const SettingsGroup = (props: PropsFromRedux & SettingsGroupProps & Partial<DropdownProps>) => {
  const { updateSettingAction, getCurrentUserAction, group } = props;

  const isChecked = group.settings?.find((setting) => setting.type === 'checkbox')?.value === 'true';

  const [isCheckedBox, setIsCheckedBox] = React.useState<boolean>(isChecked);

  const classes = useStyles();

  const handleSettingChange = <T extends boolean | string>(setting: Setting, value: T) => {
    const data = { ...setting, value: value.toString() } as Setting;
    updateSettingAction(data, () => {
      getCurrentUserAction();
    });
  };

  const renderSettings = () => {
    const { settings } = group;
    if (settings && settings.length > 0) {
      return settings.map((setting: Setting) => {
        if (setting.type === 'checkbox') {
          return (
            <Checkbox
              key={`${setting.id}-checkbox-group`}
              checked={isCheckedBox}
              label={__(setting.title)}
              onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                handleSettingChange(setting, !!isChecked);
                setIsCheckedBox(!!isChecked);
              }}
            />
          );
        } else if (setting.type === 'text') {
          return <div key={`${setting.id}`}>{`${setting.value}/${setting.default} ${__(setting.title)}`}</div>;
        } else if (setting.type === 'dropdown') {
          const dropDownOptions: {
            key: string;
            value: string;
          }[] = [
            { key: 'anthropic_claude3', value: 'Anthropic (Claude 3.5)' },
            { key: 'openai_gpt4', value: 'OpenAI (GPT 4o)' },
          ];
          const defaultValue = dropDownOptions.find((option) => option.key === setting.value) ?? dropDownOptions[1];
          return (
            <div key={`${setting.id}`} className={classes.aiModelDiv}>
              <span className={classes.aiModelSpanText}>{__('Which AI model should be used?')}</span>
              <Dropdown
                placeholder="Select a model"
                {...props}
                defaultValue={defaultValue.value}
                disabled={!isCheckedBox}
                onOptionSelect={(ev?: SelectionEvents, option?: OptionOnSelectData) => {
                  handleSettingChange(setting, option?.optionValue ?? 'openai_gpt4');
                }}
              >
                {dropDownOptions.map((option) => (
                  <Option key={option.key} value={option.key}>
                    {option.value}
                  </Option>
                ))}
              </Dropdown>
            </div>
          );
        }
      });
    }
    return null;
  };

  return <>{renderSettings()}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, { updateSettingAction, getCurrentUserAction });
export default connector(SettingsGroup);
