import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const requestPermission = (data?: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `mailnotification/request_permission/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    reqPayload: data,
    cb,
  });
