import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchStatusAction } from '../../store/actions/status-actions';
import { clearProcessesAction } from '../../store/actions/process-actions';
import { Processes } from './processes';
import { ProcessMap } from './process-map';
import { RootState } from '../../config/store';
import { __ } from 'modeling-tool';
import './process-management.less';
import { Pivot, PivotItem } from '@fluentui/react';
import { getStyles } from './process-management-styles';
import { useLocation, useNavigate } from 'react-router';

const ProcessManagement = (props: PropsFromRedux) => {
  const { fetchStatusAction, clearProcessesAction } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const styles = getStyles();

  const [selectedKey, setSelectedKey] = useState<string>('0');

  useEffect(() => {
    fetchStatusAction();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('processes')) {
      setSelectedKey('1');
    } else {
      setSelectedKey('0');
      clearProcessesAction();
    }
  }, [location.pathname]);

  const onTabClick = (key?: string) => {
    if (key == __('process map')) {
      navigate('/process-management/processmap');
    } else {
      navigate('/process-management/processes');
    }
  };
  return (
    <>
      <div className={styles.processManagementPage} id="process-management-page">
        <Pivot onLinkClick={(item, _) => onTabClick(item?.props.headerText)} selectedKey={selectedKey}>
          <PivotItem headerText={__('process map')} itemKey="0">
            <ProcessMap />
          </PivotItem>
          <PivotItem headerText={__('processes')} itemKey="1">
            <Processes hasReminder={false} />
          </PivotItem>
        </Pivot>
      </div>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({}: RootState) => ({});
const connector = connect(mapStateToProps, {
  fetchStatusAction,
  clearProcessesAction,
});
export default connector(ProcessManagement);
