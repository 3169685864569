import { Person } from '@microsoft/mgt-react';
import React, { useState } from 'react';
import { GetAppLightThemeFluentV9, __ } from 'modeling-tool';
import { useForm } from 'react-hook-form';
import { PersonDisplayName } from '../../../../components';
import { Modal, TextField } from 'modeling-tool';
import { Stack, IStackTokens, Checkbox } from '@fluentui/react';
import { getStyles } from './comments-form-styles';
import { FluentProvider, Persona } from '@fluentui/react-components';

interface CommentsFormProps {
  visible: boolean;
  setVisible: (arg: boolean) => void;
  selectedProcessInstance: any;
  add: (group: Comment, cb: () => void) => void;
  username: string;
}
const CommentsForm = (props: CommentsFormProps) => {
  const { visible, setVisible, selectedProcessInstance, add, username } = props;
  const [selectedOption, setSelectedOption] = useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const styles = getStyles();

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 5,
  };
  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const onChange = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setIsChecked(!!checked);
    checked ? setSelectedOption(true) : setSelectedOption(false);
  }, []);

  const onFinish = (values: any) => {
    values = {
      ...values,
      isAnonymous: selectedOption,
      averageRating: 0,
      instance: selectedProcessInstance.resourceUri,
      createdBy: username,
    };
    add(values, () => {
      setVisible(false);
      reset();
    });
    setVisible(false);
    setIsChecked(false);
  };
  const handleProcessOk = () => {
    handleSubmit(
      (data) => {
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleProcessCancel = () => {
    setVisible(false);
    setIsChecked(false);
    reset();
  };

  if (!selectedProcessInstance) {
    return <></>;
  }
  return (
    <div>
      {selectedProcessInstance && (
        <>
          <Modal
            title={__('create a new comment')}
            isModalOpen={visible}
            onSave={handleProcessOk}
            onCancel={handleProcessCancel}
          >
            <form>
              <Checkbox label={__('anonyme')} checked={isChecked} onChange={onChange} />
              <TextField
                label={__('enter a comment here')}
                control={control}
                name="description"
                required={true}
                multiline
                rules={{ required: __('this field is required') }}
              />
              {selectedOption === false && (
                <Stack wrap horizontal tokens={stackTokens} className={styles.personStyle}>
                  <Person userId={username}></Person>
                  <Person userId={username}>
                    <PersonDisplayName />
                  </Person>
                </Stack>
              )}
              {selectedOption === true && (
                <div className={styles.personStyle}>
                  {/* FluentProvider is necessary here to apply the Fluent2 theme.
                      Without FluentProvider, the Fluent2 styles won't be applied to the components. */}
                  <FluentProvider theme={GetAppLightThemeFluentV9()}>
                    <Persona size="medium" primaryText={__('anonyme')} textAlignment="center" />
                  </FluentProvider>
                </div>
              )}
            </form>
          </Modal>
        </>
      )}
    </div>
  );
};

export default CommentsForm;
