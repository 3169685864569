import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../config/store';
import { PersonDisplayName } from '../../..';
import { IColumn, Selection, SelectionMode, CheckboxVisibility } from '@fluentui/react';
import { Process, ProcessInstance } from '../../../../ts/interfaces';
import { getDateFormatted } from '../../../../config/utils';
import { Person } from '@microsoft/mgt-react';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { BasicList } from 'modeling-tool';
import { attachmentStyle, headerStyle, statusStyle } from './process-review-list-styles';
import {
  clearProcessInstanceAction,
  selectProcessInstanceByIdAction,
} from '../../../../store/actions/process-instance-actions';

interface ProcessReviewListProps {
  items?: ProcessInstance[] | Process[];
  tasksModal?: boolean;
}

const ProcessReviewList = (props: PropsFromRedux & ProcessReviewListProps) => {
  const { items, selectProcessInstanceByIdAction, clearProcessInstanceAction, tasksModal } = props;

  const columns: IColumn[] = [
    {
      key: 'participant',
      name: __('participant'),
      fieldName: 'participant',
      minWidth: 120,
      styles: headerStyle,
      onRender: ({ participant }: ProcessInstance) =>
        participant && (
          <>
            <Person userId={participant} />
            <>&nbsp;&nbsp;</>
            <Person userId={participant}>
              <PersonDisplayName />
            </Person>
          </>
        ),
    },
    {
      name: __('status'),
      fieldName: 'state',
      key: 'state',
      minWidth: 100,
      styles: attachmentStyle,
      onRender: ({ state }: ProcessInstance) => {
        switch (state.type) {
          case GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_ACCEPTED:
            return __('accepted');
          case GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED:
            return __('in process');
          case GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING:
            return __('in process recording');
          case GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_MODELING:
            return __('in modeling');
          default:
            return state && <>{__(state.projectStatusName)}</>;
        }
      },
    },
    {
      name: __('sent date'),
      fieldName: 'sentDate',
      key: 'sentDate',
      minWidth: 70,
      styles: statusStyle,
      onRender: ({ sendBackDate }: ProcessInstance) => sendBackDate && <>{getDateFormatted(sendBackDate, 'l')}</>,
    },
  ];

  const _rowsSelected: Selection = new Selection({
    onSelectionChanged: () => _getSelectionDetails(),
  });

  const _getSelectionDetails = () => {
    const selectionCount = _rowsSelected.getSelectedCount();
    if (selectionCount > 0) {
      const selectedProcessInstance = _rowsSelected.getSelection()[0] as ProcessInstance;
      selectProcessInstanceByIdAction(selectedProcessInstance.id);
    } else {
      clearProcessInstanceAction();
    }
  };

  return (
    <>
      <BasicList
        setKey={GLOBALENUMS.BASICLISTTYPES.PROCESSINSTANCES_LIST}
        items={items}
        columns={columns}
        autoHeight={true}
        selectionMode={!tasksModal ? SelectionMode.single : SelectionMode.none}
        selection={!tasksModal && _rowsSelected}
        checkboxVisibility={CheckboxVisibility.hidden}
      />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessInstanceReducer }: RootState) => ({ ProcessInstanceReducer });
const connector = connect(mapStateToProps, { selectProcessInstanceByIdAction, clearProcessInstanceAction });
export default connector(ProcessReviewList);
