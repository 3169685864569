import { GLOBALENUMS } from 'modeling-tool';
import { UseAction, UseGraphAction, UserBulkGraphAction } from './base-actions';

export const fetchEmployeeAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `employee/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_EMPLOYEES',
    cb,
  });

export const searchEmployeeAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `employee/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_EMPLOYEES',
    cb,
  });

export const clearEmployeeAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_EMPLOYEES',
    cb,
  });

export const fetchEmployeeByGroupAction = (title: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `employee/?permission_group=${title}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_EMPLOYEES_BY_GROUP',
    cb,
  });

export const addEmployeeAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'employee/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_EMPLOYEE',
    reqPayload: data,
    cb,
  });

export const addEmployeeBulkAction = (data: any, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: 'employee/',
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'ADD_EMPLOYEE_BULK',
    reqPayload: data,
    cb,
    ecb,
  });

export const addADGroupBulkAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'employee/add_ad_group/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_EMPLOYEE_BULK',
    reqPayload: data,
    cb,
  });

export const updateEmployeeAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `employee/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_EMPLOYEE',
    reqPayload: data,
    cb,
  });

export const selectEmployeeByIdAction = (id: number) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_EMPLOYEE_BY_ID',
    payload: id,
  });

export const deleteEmployeeAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `employee/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_EMPLOYEE',
    payload: data.id,
    cb,
  });

export const sendEmail = (body: any, cb?: (...args: any[]) => void) =>
  UseGraphAction({
    url: `/me/sendMail`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    scopes: 'Mail.Send',
    payload: body,
    cb,
  });

export const renderIdentites = (id: string, cb?: (...args: any[]) => void) =>
  UseGraphAction({
    url: `users/${id}/identities`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    scopes: 'User.ReadBasic.All',
    payload: id,
    cb,
  });

export const listMemberOfGroup = (id: string, cb?: (...args: any[]) => void) =>
  UseGraphAction({
    url: `groups/${id}/members//microsoft.graph.user?$select=id&$top=999`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    scopes: 'GroupMember.Read.All',
    payload: id,
    cb,
  });

export const fetchEmployeeMetaDataFromMS = (userId: string, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) => {

  const bulkRequests = [
    {
      "url": `/users/${userId}?$select=businessPhones,displayName,givenName,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,id,displayName,jobTitle`,
      "method": "GET",
      "id": 0
    },
    {
      "url": `/users/${userId}/photo/$value`,
      "method": "GET",
      "id": 1
    }
  ];

  return UserBulkGraphAction({
    method: GLOBALENUMS.HTTPREQUEST.POST,
    scopes: 'User.ReadBasic.All',
    payload: userId,
    mgtBulkRequests: bulkRequests,
    cb,
    ecb,
  });
}
