import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { GLOBALENUMS, UtilityContext, __ } from 'modeling-tool';
import { connect, ConnectedProps } from 'react-redux';
import { Comments } from '../..';
import { RootState } from '../../../config/store';
import {
  getFullModelByInstanceId,
  updateFullModelAction,
  updateGraphData,
} from '../../../store/actions/modeler-actions';
import {
  clearProcessInstanceAction,
  clearProcessInstancesAction,
  selectProcessInstanceByIdAction,
} from '../../../store/actions/process-instance-actions';
import { getProposalsForProcessAction, addProposalForProcessAction } from '../../../store/actions/process-actions';
import { useStyles } from './process-record-modeler-styles';
import { RecordingButtonSection } from './recording-buttons-section';
import { ProcessReviewList } from './process-review-list';
import {
  addCommentAction,
  clearComments,
  getCommentsByProcessInstanceIdAction,
} from '../../../store/actions/comment-actions';
import { ReportProblemModal } from '../report-problem-modal';
import { IsTeamsApp } from '../../../config/config';
import * as microsoftTeams from '@microsoft/teams-js';
import ProcessRecordModelerContextWrapper from './process-record-modeler-context-wrapper';
import { CaretLeftFilled, CaretRightFilled } from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';

interface ProcessRecordModelerProps {
  enableLoading?: boolean;
  isDetailsView?: boolean;
  isTaskDetailsView?: boolean;
  onAccept?(): void;
  onApprove?(): void;
  onSend?(): void;
  onSendBack?(rejectionMessage: string): void;
  onShowHistoryModal?(): void;
  setIsAiPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProcessRecordModeler = (props: ProcessRecordModelerProps & PropsFromRedux) => {
  const {
    ProcessInstanceReducer: { instances, selectedInstance },
    UserCommentReducer: { comments },
    isTaskDetailsView = false,
    isDetailsView = false,
    addCommentAction,
    clearComments,
    clearProcessInstancesAction,
    getCommentsByProcessInstanceIdAction,
    onAccept,
    onApprove,
    onSendBack,
    onShowHistoryModal,
    setIsAiPanelOpen,
  } = props;

  const { setBreadcrumbButtons } = useContext(UtilityContext);
  const [showInstanceListContainer, { toggle: toggleInstanceListContainer }] = useBoolean(true);
  const [showComments, { toggle: toggleShowComments, setFalse: closeComments }] = useBoolean(false);
  const [problemModalVisible, { setTrue: showProblemModal, setFalse: closeProblemModal }] = useBoolean(false);
  const [hasUrgentProblems, setHasUrgentProblems] = useState<boolean>(false);
  const [problemModalReadOnly, setProblemModalReadOnly] = useState<boolean>(false);

  const theme = useTheme();
  const styles = useStyles();

  useEffect(() => {
    !isDetailsView &&
      setBreadcrumbButtons &&
      setBreadcrumbButtons({
        list: [
          <RecordingButtonSection
            key="recordButtons"
            onSend={onSend}
            onAccept={onAccept}
            onApprove={onApprove}
            onSendBack={onSendBack}
            selectedInstance={selectedInstance}
            isTaskDetailsView={isTaskDetailsView}
          />,
        ],
      });
    return () => {
      !isDetailsView && setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
    };
  }, [selectedInstance]);

  useEffect(() => {
    return () => {
      clearProcessInstancesAction();
      clearComments();
    };
  }, []);

  useEffect(() => {
    if (isDetailsView) {
      if (selectedInstance?.resourceUri) {
        getCommentsByProcessInstanceIdAction(selectedInstance?.id);
      }
    }
  }, [selectedInstance]);

  useEffect(() => {
    if (selectedInstance && comments) {
      const urgentComments = comments.some((item) => {
        return item.isUrgent;
      });

      if (urgentComments) {
        setHasUrgentProblems(true);
      } else {
        setHasUrgentProblems(false);
      }
    }
  }, [comments]);

  const onSend = () => {
    props.onSend && props.onSend();
  };

  const isReadOnly = (): boolean => {
    return selectedInstance?.state?.type !== GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED;
  };

  const openProblemModal = (readonly: boolean) => {
    showProblemModal();
    setProblemModalReadOnly(readonly);
  };

  const renderModelerControl = () => {
    if (selectedInstance?.id) {
      return (
        <ProcessRecordModelerContextWrapper
          onApprove={onApprove}
          onShowHistoryModal={onShowHistoryModal}
          isDetailsView={isDetailsView}
          setIsAiPanelOpen={setIsAiPanelOpen}
          theme={theme}
          openProblemModal={openProblemModal}
          hasUrgentProblems={hasUrgentProblems}
          toggleShowComments={toggleShowComments}
          handleBlockTeams={handleBlockTeams}
          readOnly={isReadOnly()}
        />
      );
    }
  };

  const renderProcessRecordModeler = () => {
    return (
      <>
        <div className={styles.card}>
          <div className={styles.detailsContainer}>
            {!isTaskDetailsView && !isDetailsView && (
              <Button
                icon={showInstanceListContainer ? <CaretLeftFilled /> : <CaretRightFilled />}
                onClick={toggleInstanceListContainer}
                className={styles.caretContainer}
              />
            )}
            {showInstanceListContainer && !isTaskDetailsView && !isDetailsView && instances.length > 0 && (
              <div className={styles.instanceListContainer}>
                <ProcessReviewList items={instances} />
              </div>
            )}
            {showComments && (
              <Comments selectedProcessInstance={selectedInstance} closeComments={closeComments}></Comments>
            )}
            <div className={styles.processTreeContainer}>{renderModelerControl()}</div>
          </div>
        </div>
      </>
    );
  };

  const handleBlockTeams = () => {
    if (IsTeamsApp()) {
      microsoftTeams.teamsCore.registerBeforeUnloadHandler((readyToUnload) => {
        if (window.confirm(__('do you want to leave this page without saving? all unsaved data will be lost.'))) {
          readyToUnload();
          return true;
        } else {
          return false;
        }
      });
    }
  };
  return (
    <>
      {renderProcessRecordModeler()}
      {selectedInstance && (
        <ReportProblemModal
          readonly={problemModalReadOnly}
          instance={selectedInstance}
          visible={problemModalVisible}
          closeProblemModal={closeProblemModal}
          addProblem={addCommentAction}
        ></ReportProblemModal>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessInstanceReducer, UserCommentReducer }: RootState) => ({
  ProcessInstanceReducer,
  UserCommentReducer,
});
const connector = connect(mapStateToProps, {
  selectProcessInstanceByIdAction,
  clearProcessInstanceAction,
  getFullModelByInstanceId,
  getProposalsForProcessAction,
  addProposalForProcessAction,
  updateFullModelAction,
  updateGraphData,
  addCommentAction,
  getCommentsByProcessInstanceIdAction,
  clearProcessInstancesAction,
  clearComments,
});
export default connector(ProcessRecordModeler);
