import React, { useEffect, useState } from 'react';
import {
  FontSizes,
  FontWeights,
  IBasePickerStyles,
  Icon,
  IDropdownOption,
  IIconStyles,
  IStackTokens,
  Label,
  Stack,
  useTheme,
} from '@fluentui/react';
import { Control, useWatch } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { DropdownWithSearch, PeoplePicker } from 'modeling-tool';
import { RootState } from '../../../config/store';
import { __ } from 'modeling-tool';
import { Department, Role, StaffPosition, WorkingGroup } from '../../../ts/interfaces';

interface PeopleDeptRoleInputProps extends PropsFromRedux {
  controlId?: string;
  field: string;
  control: Control<any>;
  readOnly?: boolean;
  isFoldable?: boolean;
  showWorkingGroups?: boolean;
  userIds?: string[];
}
const stackTokens: IStackTokens = { childrenGap: 10 };
const iconStyles: IIconStyles = {
  root: {
    fontWeight: FontWeights.light,
    fontSize: FontSizes.size14,
    float: 'right',
    padding: '2px',
    cursor: 'pointer',
  },
};

const PeopleDeptRoleInput = (props: PeopleDeptRoleInputProps) => {
  const {
    UserReducer: { tenantMembers },
    DropdownDataReducer: { orgUnitsAndStaffPositions, roles, workingGroups },
    userIds,
  } = props;

  const [expand, setExpand] = useState<boolean>(false);
  const { controlId, field, control, readOnly, isFoldable, showWorkingGroups } = props;
  const departmentFieldWatch = useWatch({ control, name: `${field}Departments` });
  const roleFieldWatch = useWatch({ control, name: `${field}Roles` });
  const workingGroupFieldWatch = useWatch({ control, name: `${field}WorkingGroup` });

  const theme = useTheme();
  const dropdownStyles: Partial<IBasePickerStyles> = {
    root: { margin: '0 0 5px 0', background: theme.palette.neutralLighter },
  };
  useEffect(() => {
    (departmentFieldWatch?.length || roleFieldWatch?.length || workingGroupFieldWatch?.length) && setExpand(true);
  }, [departmentFieldWatch, roleFieldWatch]);

  const getDropDownOptions = (type: string) => {
    switch (type) {
      case 'departments':
        return orgUnitsAndStaffPositions.map(
          (item: any) =>
            ({
              key: item.resourceUri,
              text: item.departmentName ? item.departmentName : item.staffPositionName,
            }) as IDropdownOption,
        );
      case 'workingGroups':
        return workingGroups.map(
          (group: WorkingGroup) => ({ key: group.resourceUri, text: group.title }) as IDropdownOption,
        );
      default:
        return roles.map((role: Role) => ({ key: role.resourceUri, text: role.roleName }) as IDropdownOption);
    }
  };

  return (
    <>
      <Label styles={{ root: { fontWeight: 400 } }}>{__(field)}</Label>
      <Stack tokens={stackTokens}>
        <Stack.Item>
          <div style={{ pointerEvents: `${readOnly ? 'none' : 'all'}` }}>
            {tenantMembers && (
              <PeoplePicker
                controlId={controlId}
                selectionMode="multiple"
                name={field}
                control={control}
                userIds={userIds}
              />
            )}
          </div>
          {isFoldable && !expand && <Icon iconName="CaretDown8" onClick={() => setExpand(true)} styles={iconStyles} />}
          {isFoldable && expand && <Icon iconName="CaretUp8" onClick={() => setExpand(false)} styles={iconStyles} />}
        </Stack.Item>
        {((isFoldable && expand) || !isFoldable) && (
          <>
            <Stack tokens={stackTokens} style={{ pointerEvents: `${readOnly ? 'none' : 'all'}` }}>
              <Stack.Item>
                <DropdownWithSearch
                  options={getDropDownOptions('departments')}
                  control={control}
                  name={`${field}Departments`}
                  placeholder={__('please select department')}
                  multiSelect
                  styles={dropdownStyles}
                />
              </Stack.Item>
              <Stack.Item>
                <DropdownWithSearch
                  options={getDropDownOptions('roles')}
                  control={control}
                  name={`${field}Roles`}
                  placeholder={__('please select role')}
                  multiSelect
                  styles={dropdownStyles}
                />
              </Stack.Item>
              {showWorkingGroups && (
                <Stack.Item>
                  <DropdownWithSearch
                    options={getDropDownOptions('workingGroups')}
                    control={control}
                    name={`${field}WorkingGroups`}
                    placeholder={__('please select working group')}
                    multiSelect
                    styles={dropdownStyles}
                  />
                </Stack.Item>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, DropdownDataReducer }: RootState) => ({
  UserReducer,
  DropdownDataReducer,
});
const connector = connect(mapStateToProps);
export default connector(PeopleDeptRoleInput);
