import { useContext, useState } from 'react';
import { Icon } from '@fluentui/react';
import { FileListModal, IconSvg, Modal, UtilityContext, __ } from 'modeling-tool';
import { ConnectedProps, connect } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import { IProcessMap } from '../../../../ts/interfaces';
import { RootState } from '../../../../config/store';
import { selectProcessMapAction, updateProcessMapAction } from '../../../../store/actions/process-map-actions';
import { EntityCard } from '../../../../components';
import { EntityCardType } from '../../../../ts/enums';

interface ProcessMapCardProps {
  processMap: IProcessMap;
  closeSidebar: () => void;
  renderDetailsBar: (value: IProcessMap) => void;
  readonly?: boolean;
  redirectToProcessMap: (subId: number | undefined) => void;
}

const ProcessMapCard = (props: ProcessMapCardProps & PropsFromRedux) => {
  const {
    ProcessMapReducer: { selectedProcessmap },
    processMap,
    closeSidebar,
    renderDetailsBar,
    redirectToProcessMap,
    readonly,
    selectProcessMapAction,
    updateProcessMapAction,
  } = props;
  const { utilitySideBar } = useContext(UtilityContext);
  const [imageUrl, setImageUrl] = useState<any>('');
  const [showPicturePopup, { toggle: togglePicturePopup }] = useBoolean(false);
  const selectedCard = processMap.id === selectedProcessmap?.id;

  const handleOpenProcessMap = (e: any) => {
    e.stopPropagation();
    redirectToProcessMap(processMap.id);
    closeSidebar();
  };

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    renderDetailsBar(processMap);
    if (processMap.id) {
      selectProcessMapAction(processMap);
    }
  };

  const onClickAddLogo = (e: any) => {
    e.stopPropagation();
    if (processMap.id) {
      selectProcessMapAction(processMap);
      if (!!utilitySideBar.list.length) {
        renderDetailsBar(processMap);
      }
      togglePicturePopup();
    }
  };

  const handleSave = () => {
    const values = {
      ...selectedProcessmap,
      logo: imageUrl.replaceAll('|', '::'),
    };
    if (!utilitySideBar.list.length) {
      updateProcessMapAction(values);
    } else {
      renderDetailsBar({ ...processMap, logo: imageUrl.replaceAll('|', '::') });
      selectProcessMapAction(values);
    }

    togglePicturePopup();
  };

  const handleCancelAddLogoModal = () => {
    togglePicturePopup();
  };

  return (
    <EntityCard
      cardType={EntityCardType.PROCESS_MAP}
      name={processMap.processMapName}
      description={processMap.description}
      logo={processMap.logo}
      setImageUrl={setImageUrl}
      readonly={readonly}
      selected={selectedCard}
      showPicturePopup={showPicturePopup}
      onClickAddLogo={onClickAddLogo}
      handleOnClick={handleOnClick}
      handleOpenCard={handleOpenProcessMap}
      handleCancelAddLogoModal={handleCancelAddLogoModal}
      handleSave={handleSave}
    />
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, ProcessMapReducer }: RootState) => ({
  UserReducer,
  ProcessMapReducer,
});
const connector = connect(mapStateToProps, {
  selectProcessMapAction,
  updateProcessMapAction,
});
export default connector(ProcessMapCard);
