import {
  DropdownPicker,
  ExpandableSection,
  GLOBALENUMS,
  PeoplePickerWithTags,
  Sidebar,
  TextEditor,
  TextField,
  __,
  showField,
  FileList,
  LinkInput,
  RequirementsDropdownPicker,
} from 'modeling-tool';
import { useForm } from 'react-hook-form';
import { useStyles } from './organization-form-styles';
import { Department, IOrgChart, Requirement, RequirementChapter, Resource, Role } from '../../../ts/interfaces';
import { Button, Divider, Label } from '@fluentui/react-components';
import { renderSelectDropDown } from '../../../utils/process-management';
import { ConnectedProps, connect } from 'react-redux';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';
import { RootState } from '../../../config/store';
import { useEffect, useRef, useState } from 'react';
import { fetchLibrariesAction, fetchSitesAction } from '../../../store/actions/file-actions';
import { Delete16Regular } from '@fluentui/react-icons';
import { updateOrgChartAction } from '../../../store/actions/org-action';

interface IOrganizationFormProps {
  orgchart: IOrgChart;
  closeSidebar: () => void;
  readonly?: boolean;
  toggleModal: () => void;
}

const dropDownData = [
  GLOBALENUMS.DROPDOWNDATA.LOCATIONS,
  GLOBALENUMS.DROPDOWNDATA.ROLES,
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS,
  GLOBALENUMS.DROPDOWNDATA.REQUIREMENTS,
  GLOBALENUMS.DROPDOWNDATA.RESOURCES,
];
const fileExt = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

const OrganizationForm = (props: IOrganizationFormProps & PropsFromRedux) => {
  const {
    DropdownDataReducer: { locations, orgUnits, roles, requirements, resources },
    OrgChartReducer: { selectedOrgChart },
    orgchart,
    readonly,
    closeSidebar,
    fetchDropdownDataByType,
    fetchLibrariesAction,
    fetchSitesAction,
    toggleModal,
    updateOrgChartAction,
  } = props;
  const classes = useStyles();

  const [id, setId] = useState<number>(-1);
  const [ready, setReady] = useState<boolean>(false);

  const isDeletingRef = useRef(false);

  const { handleSubmit, control, reset } = useForm({ reValidateMode: 'onSubmit', mode: 'all' });

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
  }, []);

  useEffect(() => {
    return () => {
      if (!isDeletingRef.current) {
        submitData();
      }
    };
  }, [selectedOrgChart]);

  useEffect(() => {
    if (selectedOrgChart) {
      reset();
      const { responsibilities, attachments, logo, resources, requirementsList } = orgchart;
      const formData = {
        ...orgchart,
        responsibleDepartments:
          responsibilities?.departments?.map((department: Department) => department.resourceUri) || [],
        responsibleEmployees: responsibilities?.employees || '',
        responsibleRoles: responsibilities?.roles?.map((role: Role) => role.resourceUri) || [],
        resources: resources?.map((resources: Resource) => resources.resourceUri) || [],
        requirementsList:
          requirementsList?.map((requirement: Requirement | RequirementChapter) => requirement.resourceUri) || [],
        attachments: attachments?.length ? attachments?.split('|') : [],
        logo: logo?.length ? logo?.split('|') : [],
      };
      reset(formData);
      setId(Math.random());
      setReady(true);
    } else {
      setReady(false);
      reset();
    }
  }, [selectedOrgChart]);

  const onFinish = (values: IOrgChart) => {
    if (values) {
      updateOrgChartAction(values);
    }
  };

  const submitData = () => {
    if (readonly) return;
    handleSubmit(
      (data) => {
        const orgChartData: IOrgChart = {
          ...data,
          id: selectedOrgChart?.id,
          description: data.description,
          name: data.name,
          responsibilities: {
            departments: data.responsibleDepartments,
            employees: data.responsibleEmployees,
            roles: data.responsibleRoles,
          },
          requirementsList: data.requirementsList,
          attachments: data.attachments?.length ? data.attachments.join('|') : null,
          logo: data.logo?.length ? data.logo.join('|') : null,
        };

        onFinish(orgChartData);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleOnDelete = (e: any) => {
    isDeletingRef.current = true;
    e.stopPropagation();
    toggleModal();
  };

  const renderForm = () => {
    const showName = showField('name', control, readonly);
    const showDescription = showField('description', control, readonly);

    const showEmployee = showField('responsibleEmployees', control, readonly);
    const showRoles = showField('responsibleRoles', control, readonly);
    const showDepartments = showField('responsibleDepartments', control, readonly);
    const showAnyResponsibilities = showEmployee.showField || showRoles.showField || showDepartments.showField;
    const hasValueAnyResponsibilities = showEmployee.hasValue || showRoles.hasValue || showDepartments.hasValue;

    const showRequirements = showField('requirements', control, readonly);
    const showResources = showField('resources', control, readonly);
    const showLocations = showField('locations', control, readonly);
    const showAnyAssignments = showRequirements.showField || showResources.showField || showLocations.showField;
    const hasValueAnyAssignments = showRequirements.hasValue || showResources.hasValue || showLocations.hasValue;

    const showLink = showField('linkUrl', control, readonly);
    const showAttachments = showField('attachments', control, readonly);
    const showLogo = showField('logo', control, readonly);

    return (
      <form>
        {showName.showField && (
          <TextField
            labelTitle={__('title')}
            control={control}
            name={'name'}
            placeholder={__('provide title')}
            readOnly={readonly}
          />
        )}
        {showDescription.showField && (
          <ExpandableSection label={__('description')} control={control} isExpanded={showDescription.hasValue}>
            <div className={classes.wrapper}>
              <TextEditor
                control={control}
                name={'description'}
                placeholder={__('provide description')}
                readOnly={readonly}
              />
            </div>
          </ExpandableSection>
        )}
        {showAnyResponsibilities && (
          <ExpandableSection label={__('responsibilities')} control={control} isExpanded={hasValueAnyResponsibilities}>
            {showRoles.showField && (
              <>
                <Label className={classes.label}>{__('roles')}</Label>
                <DropdownPicker
                  control={control}
                  name={'responsibleRoles'}
                  placeholder={__('provide role')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ROLES, roles)}
                  multiSelect
                  disabled={readonly}
                />
              </>
            )}
            {showEmployee.showField && (
              <>
                <Label className={classes.label}>{__('employees')}</Label>
                <PeoplePickerWithTags
                  control={control}
                  selectionMode="multiple"
                  name={'responsibleEmployees'}
                  controlId={`${id}`}
                  disabled={readonly}
                />
              </>
            )}
            {showDepartments.showField && (
              <>
                <Label className={classes.label}>{__('departments')}</Label>
                <DropdownPicker
                  control={control}
                  placeholder={__('provide organizational unit')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, orgUnits)}
                  name={'responsibleDepartments'}
                  multiSelect
                  disabled={readonly}
                />
              </>
            )}
          </ExpandableSection>
        )}
        {showAnyAssignments && (
          <ExpandableSection label={__('assignments')} control={control} isExpanded={hasValueAnyAssignments}>
            {showRequirements.showField && (
              <>
                <Label className={classes.label}>{__('requirements')}</Label>
                <RequirementsDropdownPicker
                  control={control}
                  name={'requirementsList'}
                  options={requirements as any}
                  placeholder={__('please select requirements')}
                  disabled={readonly}
                  multiSelect
                />
              </>
            )}
            {showResources.showField && (
              <>
                <Label className={classes.label}>{__('resources')}</Label>
                <DropdownPicker
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.RESOURCES, resources)}
                  control={control}
                  name={'resources'}
                  placeholder={__('provide resource')}
                  multiSelect
                  disabled={readonly}
                />
              </>
            )}
            {showLocations.showField && (
              <>
                <Label className={classes.label}>{__('locations')}</Label>
                <DropdownPicker
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.LOCATIONS, locations)}
                  control={control}
                  name={'locations'}
                  placeholder={__('provide location')}
                  multiSelect
                  disabled={readonly}
                />
              </>
            )}
          </ExpandableSection>
        )}
        {showAttachments.showField && (
          <ExpandableSection label={__('documents')} control={control} isExpanded={showAttachments.hasValue}>
            <div className={classes.wrapper}>
              <FileList
                control={control}
                name={'attachments'}
                fetchLibrariesAction={fetchLibrariesAction}
                fetchSitesAction={fetchSitesAction}
                disabled={readonly}
                cutLength={true}
              />
            </div>
          </ExpandableSection>
        )}
        {showLink.showField && (
          <ExpandableSection label={__('link')} control={control} isExpanded={showLink.hasValue}>
            <div className={classes.wrapper}>
              <LinkInput control={control} readOnly={readonly} disabled={readonly} />
            </div>
          </ExpandableSection>
        )}
        {showLogo.showField && (
          <ExpandableSection label={__('logo')} control={control} isExpanded={showLogo.hasValue}>
            <div className={classes.wrapper}>
              <FileList
                control={control}
                name={'logo'}
                fileExt={fileExt}
                fetchLibrariesAction={fetchLibrariesAction}
                fetchSitesAction={fetchSitesAction}
                disabled={readonly}
              />
            </div>
          </ExpandableSection>
        )}
        {!readonly && (
          <>
            <Divider className={classes.customLineColor} />
            <Button
              appearance="subtle"
              className={classes.deleteButton}
              onClick={handleOnDelete}
              icon={<Delete16Regular />}
            >
              {__('delete')}
            </Button>
          </>
        )}
      </form>
    );
  };
  return (
    <>
      {ready && (
        <Sidebar sidebarTitle={orgchart?.name} onClick={closeSidebar}>
          {renderForm()}
        </Sidebar>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ DropdownDataReducer, UserReducer, OrgChartReducer }: RootState) => ({
  DropdownDataReducer,
  UserReducer,
  OrgChartReducer,
});

const connector = connect(mapStateToProps, {
  fetchDropdownDataByType,
  fetchLibrariesAction,
  fetchSitesAction,
  updateOrgChartAction,
});
export default connector(OrganizationForm);
