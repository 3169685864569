import { GLOBALENUMS } from 'modeling-tool';
import { UseAction, UseRequestOnlyAction } from './base-actions';
import { RACIFields } from '../../config/utils';

export const fetchProcessesAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_PROCESSES',
    cb,
  });

export const fetchProcessAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/${id}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_PROCESS',
    cb,
  });

export const searchProcessesAction = (search: string, orderBy?: string, cb?: (...args: any[]) => void) => {
  const query = search.length ? `${search}&${orderBy}` : `?${orderBy}`;
  return UseAction({
    url: `process/get_processes/${query || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_PROCESSES',
    cb,
  });
};

export const searchMinProcessesAction = (search: string, orderBy?: string, cb?: (...args: any[]) => void) => {
  const query = search.length ? `${search}&${orderBy}` : `?${orderBy}`;
  return UseAction({
    url: `process_min/${query || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_PROCESSES',
    cb,
  });
};
export const fetchProcessForListAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/${id}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_PROCESS_FOR_LIST',
    cb,
  });

export const searchOutdatedProcessesAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_OUTDATED_PROCESS',
    cb,
  });
export const clearProcessAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_PROCESS',
    cb,
  });

export const selectProcessAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS',
    payload: data,
  });

export const clearSelectProcessAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_SELECT_PROCESS',
  });

export const selectProcessByIdAction = (id: number) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS_BY_ID',
    payload: id,
  });

export const updateProcessAction = (data: any, cb?: (...args: any[]) => void) =>
  UseRequestOnlyAction({
    url: `process/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    reqPayload: data,
    cb,
  });

export const copyProcessAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/copy_process/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'COPY_PROCESS',
    reqPayload: data,
    cb,
  });

export const addProcessAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'process/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_PROCESS',
    reqPayload: data,
    cb,
  });

export const deleteProcessAction = (data: any, cb?: (...args: any[]) => void) =>
  UseRequestOnlyAction({
    url: `process/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    payload: data.id,
    cb,
  });

export const setMetaAction = (payload: any, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SET_META_PROCESS',
    payload: payload,
    cb,
  });

export const clearProcessesAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_PROCESSES',
  });

export const shareProcessRecordingAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/share_recording/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'SHARE_PROCESS_RECORDING',
    reqPayload: data,
    cb,
  });

export const startProcessModelingAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/start_modeling/${id}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'START_PROCESS_MODELLING',
    // reqPayload: data,
    cb,
  });

export const shareModelTaskAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process/share_model/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'SHARE_MODEL',
    reqPayload: data,
    cb,
  });

export const addQueryParamsAction = (payload: any, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'ADD_QUERY_PARAMS',
    payload: payload,
    cb,
  });

export const clearQueryParamsAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_QUERY_PARAMS',
  });

export const setOrderByColumnsAction = (orderByColumns: string, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SET_ORDER_BY_COLUMNS',
    payload: orderByColumns,
    cb,
  });

export const makeProcessVersionAction = (data: any, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) => {
  const reqData = Object.assign({}, data);
  Object.keys(reqData).forEach((key) => {
    if (RACIFields.indexOf(key) !== -1 && reqData[key]) {
      delete reqData[key].id;
      delete reqData[key].resourceUri;
    }
  });
  delete reqData.state;
  delete reqData.createdBy;
  delete reqData.modelingUser;
  delete reqData.rootVersion;
  return UseAction({
    url: `process/copy/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'MAKE_VERSION',
    reqPayload: reqData,
    cb,
    ecb,
  });
};

export const getProposalsForProcessAction = (id: number, cb?: (...args: any[]) => void) =>
  UseRequestOnlyAction({
    url: `proposal/?source_process=${id}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    cb,
  });

export const addProposalForProcessAction = (data: any, cb?: (...args: any[]) => void) =>
  UseRequestOnlyAction({
    url: `proposal/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    reqPayload: data,
    cb,
  });

export const fetchProcessChildrenAction = (
  id: number,
  nextLevel?: number,
  orderBy?: string,
  cb?: (...args: any[]) => void,
) =>
  UseAction({
    url: nextLevel
      ? `process/get_processes/?parent=${id}&outdated=false&next_level=${nextLevel}${'&' + orderBy || ''}`
      : `process/get_processes/?parent=${id}&outdated=false${'&' + orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_PROCESS_CHILDREN',
    cb,
  });

export const fetchAllChildProcesses = (id: number | null, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `process_min/fetchallchildprocesses/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_ALL_CHILD_PROCESSES',
    cb,
  });
