import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { RootState } from '../../../config/store';
import { __ } from 'modeling-tool';
import { getStyles } from './breadcrumbs-styles';
import { useNavigate } from 'react-router';
import './breadcrumbs-styles';

const Breadcrumbs = (props: PropsFromRedux) => {
  const {
    ProcessReducer: { processes, selectedProcess },
    ProcessMapReducer: { processmaps, selectedProcessmap },
    TaskReducer: { tasks, selectedTask },
    ProcessInstanceReducer: { instances, selectedInstance },
    RequirementReducer: { requirements, openRequirement },
    OrgChartReducer: { orgcharts, selectedOrgChart },
  } = props;

  const breadcrumbs: BreadcrumbData[] = useBreadcrumbs();
  const navigate = useNavigate();
  const styles = getStyles();

  const _onBreadcrumbItemClicked = (ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void => {
    navigate(item.key);
  };

  const getTitleFromReducer = (id: number, selected: any, list: any, titleField: string) => {
    let result = __('untitled');
    if (selected) {
      result = selected[titleField]?.length > 0 ? selected[titleField] : result;
    } else if (list?.length) {
      const item = list.find((item: any) => item.id === id);
      result = item && item[titleField] ? item[titleField] : result;
    }
    return result;
  };

  const getTitle = (id: number, list: any, titleField: string) => {
    let result = '';
    if (list?.length) {
      const item = list.find((item: any) => item.id == id);
      result = item ? item[titleField] : result;
    }
    return result;
  };

  const setDocumentTitle = (title: string) => {
    document.title = title;
    return title;
  };

  const getTitleForId = (parts: string[], id: number) => {
    const source = parts[1];
    switch (source) {
      case 'process-management':
        if (breadcrumbs.find((item) => item.key.includes('processmap')) && parts[4]) {
          const processTitle = getTitle(id, processes, 'processName');
          return setDocumentTitle(processTitle);
        }
        if (breadcrumbs.find((item) => item.key.includes('processmap'))) {
          const processMapTitle = getTitleFromReducer(id, selectedProcessmap, processmaps, 'processMapName');
          return setDocumentTitle(processMapTitle);
        }
        if (breadcrumbs.find((item) => item.key.includes('modeling-tool'))) {
          const processInstanceName = getTitleFromReducer(id, selectedInstance, instances, 'instanceName');
          return setDocumentTitle(processInstanceName);
        }
        return setDocumentTitle(getTitleFromReducer(id, selectedProcess, processes, 'processName'));

      case 'tasks':
        if (parts.includes('approval')) {
          return getTitleFromReducer(id, selectedInstance, instances, 'instanceName');
        }
        return getTitleFromReducer(id, selectedTask, tasks, 'instanceName');
      case 'requirements':
        const requirementTitle = getTitleFromReducer(id, openRequirement, requirements, 'name');
        return setDocumentTitle(requirementTitle);
      case 'organization':
        const organizationTitle = getTitleFromReducer(id, selectedOrgChart, orgcharts, 'name');
        return setDocumentTitle(organizationTitle);
    }
    return '';
  };

  const getItemText = (item: any) => {
    if (item) {
      const parts = item.key.split('/');
      const lastPart = parts[parts.length - 1];
      if (!Number.isNaN(parseInt(lastPart)) && item.breadcrumb.props.children == lastPart) {
        const itemId = parseInt(item.breadcrumb.props.children);
        return getTitleForId(parts, itemId);
      } else {
        const text = 'breadcrumb_' + item?.breadcrumb.props.children;
        return __(text);
      }
    }
  };

  const getBreadcrumbItems = () => {
    let err_url = false;
    const items: IBreadcrumbItem[] = breadcrumbs.map((item: BreadcrumbData) => {
      if (item.breadcrumb) {
        document.title = 'aiio';
        return {
          key: item.key == '/' ? '/home' : item.key,
          text: getItemText(item),
          onClick: _onBreadcrumbItemClicked,
          style: { fontSize: 14 },
        } as IBreadcrumbItem;
      } else {
        return {} as IBreadcrumbItem;
      }
    });

    if (items.length == 1) {
      return [];
    } else if (items[1].key == '/home') {
      return [];
    }

    items.forEach((val) => {
      if (val.text && val.text.includes('breadcrumb_')) {
        err_url = true;
      }
    });
    if (err_url) return [];
    return items;
  };

  return (
    <Breadcrumb
      items={getBreadcrumbItems()}
      maxDisplayedItems={10}
      overflowAriaLabel="More links"
      className={styles.breadcrumbs}
    />
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({
  ProcessReducer,
  TaskReducer,
  ProcessInstanceReducer,
  RequirementReducer,
  ProcessMapReducer,
  OrgChartReducer,
}: RootState) => ({
  ProcessReducer,
  TaskReducer,
  ProcessInstanceReducer,
  RequirementReducer,
  ProcessMapReducer,
  OrgChartReducer,
});
const connector = connect(mapStateToProps, {});
export default connector(Breadcrumbs);
