import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal } from 'modeling-tool';
import { __ } from 'modeling-tool';
import { RootState } from '../../../../config/store';
import { ApproversList } from '../../approvers-list';
import { useStyles } from './recording-button-section-styles';
import { Button } from '@fluentui/react-components';
import { TextBulletListSquarePersonRegular } from '@fluentui/react-icons';

const ApproversListModal = (props: PropsFromRedux) => {
  const [visible, setVisible] = useState<boolean>(false);

  const styles = useStyles();

  return (
    <>
      <Button
        icon={<TextBulletListSquarePersonRegular />}
        title={__('change details')}
        onClick={() => setVisible(true)}
      />
      <Modal
        title={__('change details')}
        isModalOpen={visible}
        hideFooter={true}
        onSave={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <>
          <ApproversList />
        </>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({}: RootState) => ({});
const connector = connect(mapStateToProps, {});
export default connector(ApproversListModal);
