import React from 'react';
import { initializeIcons, ThemeProvider } from '@fluentui/react';
import { Provider } from 'react-redux';
import './app.less';
import TelemetryProvider from './config/appinsights/telemetry-provider';
import { getAppInsights } from './config/appinsights/telemetry-service';
import { GetAppInsightsKey } from './config/config';
import configureStore from './config/store';
import Router from './router';
import TeamsAuthenticationProvider from './teams-auth-provider';
import { BrowserRouter } from 'react-router-dom';
import { GetAppLightThemeFluentV9, GetAppThemeFluentV8 } from 'modeling-tool';
import { FluentProvider } from '@fluentui/react-components';

export const store = configureStore();

export default function AppTeams(): JSX.Element {
  initializeIcons();

  // App Insights
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let appInsights = null;
  const instrumentationKey = GetAppInsightsKey();

  return (
    <>
      <Provider store={store}>
        <TeamsAuthenticationProvider>
          <ThemeProvider theme={GetAppThemeFluentV8()}>
            <FluentProvider theme={GetAppLightThemeFluentV9()}>
              <BrowserRouter>
                {instrumentationKey && (
                  <TelemetryProvider
                    instrumentationKey={instrumentationKey}
                    after={() => {
                      appInsights = getAppInsights();
                    }}
                  >
                    <div id="app-container">
                      <div className="content-container">
                        <Router />
                      </div>
                    </div>
                  </TelemetryProvider>
                )}
                {!instrumentationKey && (
                  <div id="app-container">
                    <div className="content-container">
                      <Router />
                    </div>
                  </div>
                )}
              </BrowserRouter>
            </FluentProvider>
          </ThemeProvider>
        </TeamsAuthenticationProvider>
      </Provider>
    </>
  );
}
