import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    infoTextDiv: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
    },
    permissionRequirementsList: {
      marginTop: 0,
    },
    checkMarkIcon: {
      marginRight: 8,
      height: 16,
      color: 'green',
      borderWidth: 'light',
    },
    groupHeader: {
      fontWeight: 600,
      margin: '16px 0px',
    },
    footerButtons: {
      margin: '5px',
    },
  });
});
