import React, { useEffect, useState } from 'react';
import { getStyles } from './report-problem-modal-styles';
import { useForm } from 'react-hook-form';
import { Modal, TextField, __ } from 'modeling-tool';
import { useStyleProps } from '../../../hooks';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { ProcessInstance, UserComment } from '../../../ts/interfaces';
import { Person, PersonCardInteraction, ViewType } from '@microsoft/mgt-react';
import { Checkbox, DefaultButton, IStackTokens, Label, PrimaryButton, Stack } from '@fluentui/react';
import { deleteCommentAction } from '../../../store/actions/comment-actions';
import { useAlert } from 'react-alert';
interface EditorsModalProps extends PropsFromRedux {
  readonly: boolean;
  instance: ProcessInstance;
  visible: boolean;
  closeProblemModal: () => void;
  addProblem: (data: any, cb?: ((...args: any[]) => void) | undefined) => void;
}

const ReportProblemModal = (props: EditorsModalProps) => {
  const {
    UserCommentReducer: { comments },
    readonly,
    instance,
    visible,
    closeProblemModal,
    addProblem,
    deleteCommentAction,
  } = props;

  const { handleSubmit, control, reset, watch } = useForm();

  const watchFields = watch(['description']);

  const [problems, setProblems] = useState<UserComment[]>();
  const [currentProblemIndex, setCurrentProblemIndex] = useState<number>(-1);
  const [isActive, setIsActive] = useState<boolean>(false);
  const alert = useAlert();

  const styleProps = useStyleProps();

  const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 0,
  };

  useEffect(() => {
    if (visible) {
      if (readonly) {
        const urgentComments = comments.filter((item) => {
          return item.isUrgent;
        });

        if (urgentComments && urgentComments.length > 0) {
          setProblems(urgentComments);
          setCurrentProblemIndex(0);
        }
      } else {
        reset({});
        setProblems([]);
        setCurrentProblemIndex(-1);
      }
    }
  }, [visible]);

  useEffect(() => {
    if (problems?.length && currentProblemIndex > -1 && currentProblemIndex < problems?.length) {
      reset({ description: problems[currentProblemIndex].description });
    } else {
      reset({ description: '' });
    }
  }, [currentProblemIndex]);

  useEffect(() => {
    if (watchFields[0] && watchFields[0] != '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [watchFields]);

  const handleCancel = () => {
    setCurrentProblemIndex(-1);
    setProblems([]);
    closeProblemModal();
    reset({});
  };

  const onSave = () => {
    handleSubmit(
      (data) => {
        const values = {
          description: data.description,
          isAnonymous: false,
          isUrgent: true,
          averageRating: 0,
          instance: instance.resourceUri,
          wasProcessed: true,
        };
        addProblem(values, () => {
          alert.success(__('Feedback was sent'));
          closeProblemModal();
          reset();
        });
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const onCheckboxChange = () => {
    if (problems) {
      const currentProblemId = problems[currentProblemIndex].id;
      if (currentProblemId) {
        deleteCommentAction(currentProblemId, () => {
          handleCancel();
        });
      }
    }
  };

  const onNextButtonClicked = () => {
    setCurrentProblemIndex(currentProblemIndex + 1);
  };

  const onPreviousButtonClicked = () => {
    setCurrentProblemIndex(currentProblemIndex - 1);
  };

  const renderModal = () => {
    if (readonly && problems?.length && currentProblemIndex > -1 && currentProblemIndex < problems?.length) {
      return (
        <>
          <Modal title={__('Feedback')} isModalOpen={visible} onCancel={handleCancel} hideFooter={true} minWidth={425}>
            <Stack tokens={stackTokens}>
              <Label>{__('reporter')}</Label>
              <Person
                userId={problems[currentProblemIndex].createdBy}
                view={ViewType.oneline}
                personCardInteraction={PersonCardInteraction.click}
              />
              <form>
                <TextField
                  labelTitle={__('message')}
                  control={control}
                  multiline
                  rows={8}
                  name={'description'}
                  defaultValue=""
                  readOnly={readonly}
                  style={{
                    overflowY: 'scroll',
                    backgroundColor: styleProps.theme.palette.white,
                    paddingLeft: 0,
                  }}
                />
              </form>
              <Stack horizontal tokens={stackTokens}>
                <Stack.Item grow align="center">
                  <Checkbox label={__('mark as solved')} checked={false} onChange={onCheckboxChange} />
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontal tokens={stackTokens}>
                    <Stack.Item>
                      {currentProblemIndex > 0 && (
                        <DefaultButton onClick={onPreviousButtonClicked} disabled={currentProblemIndex === 0}>
                          {__('previous')}
                        </DefaultButton>
                      )}
                    </Stack.Item>
                    <Stack.Item>
                      <PrimaryButton
                        onClick={() => {
                          onNextButtonClicked();
                        }}
                      >
                        {problems.length === currentProblemIndex + 1 ? __('add') : __('next')}
                      </PrimaryButton>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>
          </Modal>
        </>
      );
    } else {
      return (
        <>
          <Modal
            title={__('give feedback')}
            isModalOpen={visible}
            onSave={onSave}
            onCancel={currentProblemIndex > 0 ? onPreviousButtonClicked : handleCancel}
            saveButtonText={__('send')}
            cancelButtonText={currentProblemIndex == -1 ? __('cancel') : __('previous')}
            minWidth={425}
            enableSave={isActive}
          >
            <form>
              <TextField
                labelTitle={__('message')}
                control={control}
                multiline
                rows={10}
                name={'description'}
                defaultValue=""
                readOnly={false}
              />
            </form>
          </Modal>
        </>
      );
    }
  };

  return <>{renderModal()}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserCommentReducer }: RootState) => ({
  UserCommentReducer,
});
const connector = connect(mapStateToProps, { deleteCommentAction });
export default connector(ReportProblemModal);
