import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
  addressSearchfield: {
    marginTop: '10px',
  },
  addressSelectError: {
    fontWeight: 400,
    color: 'rgb(164, 38, 44)',
    fontSize: '12px',
  },
  postalCodeCityDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  postalCodeDiv: {
    width: '50%',
  },
  CityDiv: {
    width: '45%',
  },
});
