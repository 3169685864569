import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import {
  getAutoCompletePlacesAction,
  resetAutoCompletePlacesAction,
} from '../../../../store/actions/licence-check-out-actions';
import { debounce } from 'lodash';
import { Control, FieldValues, UseFormResetField, UseFormSetValue, useWatch } from 'react-hook-form';
import { DropdownWithSearch, TextField, __ } from 'modeling-tool';
import { AutoCompletePlaceAddress } from '../../../../ts/interfaces';
import { useStyles } from './billing-data-styles';
import { IDropdownOption } from '@fluentui/react';

interface BillingdataProps {
  formControl: Control<FieldValues, any>;
  formSetValue: UseFormSetValue<FieldValues>;
  formResetField: UseFormResetField<FieldValues>;
  selectedAddress: AutoCompletePlaceAddress | null;
  setSelectedAddress: React.Dispatch<React.SetStateAction<AutoCompletePlaceAddress | null>>;
}

const BillingData = (props: PropsFromRedux & BillingdataProps) => {
  const {
    getAutoCompletePlacesAction,
    resetAutoCompletePlacesAction,
    LicenceCheckOutReducer: { placesSearchResults },
    formControl,
    formSetValue,
    formResetField,
    selectedAddress,
    setSelectedAddress,
  } = props;

  const classes = useStyles();

  const fieldsToWatch = useWatch({
    control: formControl,
    name: ['addressSelect'],
  });

  useEffect(() => {
    const [addressSelect] = fieldsToWatch;
    if (addressSelect && addressSelect.length > 0) {
      const currentSelectedAddress = placesSearchResults.find((place) => place.address === addressSelect[0]);
      currentSelectedAddress ? setSelectedAddress(currentSelectedAddress) : setSelectedAddress(null);
    } else {
      setSelectedAddress(null);
      resetAutoCompletePlacesAction();
    }
  }, [fieldsToWatch]);

  useEffect(() => {
    if (!selectedAddress) {
      // clear the form fields
      formResetField('street');
      formResetField('postalCode');
      formResetField('city');
      formResetField('country');
      return;
    }
    formSetValue('street', selectedAddress.street);
    formSetValue('postalCode', selectedAddress.postalCode);
    formSetValue('city', selectedAddress.city);
    formSetValue('country', selectedAddress.country);
  }, [selectedAddress]);

  const fetchAddresses = async (query: string) => {
    try {
      // check if query is empty
      if (!query) {
        resetAutoCompletePlacesAction();
        return;
      }
      // Call your API to fetch addresses based on the query
      getAutoCompletePlacesAction(query);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  // In react functional components, local variables are expired inside a function after every call.
  // See . https://rajeshnaroth.medium.com/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
  // Since the user will be typing in the search field,
  // we need to debounce the fetchAddresses function to avoid making a request for every key stroke.
  // If the user is idle for 1.5 seconds, the fetchAddresses function will be called.
  const debouncedFetchAddressesFunction = React.useCallback(debounce(fetchAddresses, 300), []);

  const handleInputSelectChange = (newValue: string) => {
    debouncedFetchAddressesFunction(newValue);
  };

  return (
    <div>
      <form>
        <TextField
          control={formControl}
          name={'companyName'}
          placeholder={__('Company name')}
          rules={{ required: __('This field is required') }}
        />
        <div className={classes.addressSearchfield}>
          <DropdownWithSearch
            control={formControl}
            options={(placesSearchResults ?? []).map(
              (place: AutoCompletePlaceAddress) => ({ key: place.address, text: place.address }) as IDropdownOption,
            )}
            name={`addressSelect`}
            placeholder={__('Address')}
            rules={{
              required: __('This field is required'),
            }}
            onInputChange={(input) => {
              if (input) {
                handleInputSelectChange(input);
              }
            }}
            disableSearch={true}
          />
        </div>
        {selectedAddress ? (
          <>
            <TextField
              control={formControl}
              name={'street'}
              placeholder={__('Street')}
              rules={{ required: __('This field is required') }}
            />
            <div className={classes.postalCodeCityDiv}>
              <div className={classes.postalCodeDiv}>
                <TextField
                  control={formControl}
                  name={'postalCode'}
                  placeholder={__('Postal code')}
                  rules={{ required: __('This field is required') }}
                />
              </div>
              <div className={classes.CityDiv}>
                <TextField
                  control={formControl}
                  name={'city'}
                  placeholder={__('City')}
                  rules={{ required: __('This field is required') }}
                />
              </div>
            </div>
            <TextField
              control={formControl}
              name={'country'}
              placeholder={__('Country')}
              rules={{ required: __('This field is required') }}
            />
          </>
        ) : null}

        <TextField
          control={formControl}
          name={'vatId'}
          placeholder={__('VAT-ID')}
          rules={{
            required: __('This field is required'),
            pattern: {
              value: /^[a-zA-Z]{2}.*$/,
              message: __('VAT-ID should start with two letters'),
            },
          }}
        />
      </form>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ LicenceCheckOutReducer, UserReducer }: RootState) => ({
  LicenceCheckOutReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, { getAutoCompletePlacesAction, resetAutoCompletePlacesAction });
export default connector(BillingData);
