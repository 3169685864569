import React, { useEffect, useState } from 'react';
import { Person, ViewType } from '@microsoft/mgt-react';
import { GLOBALENUMS, mapToCamel } from 'modeling-tool';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { GetWSApiUrl } from '../../../../config/config';
import { checkPermission } from '../../../../config/permission-utils';
import { RootState } from '../../../../config/store';
import { __ } from 'modeling-tool';
import { addQueryParamsAction } from '../../../../store/actions/process-actions';
import {
  addNotificationAction,
  changeLanguageAction,
  getNotificationsAction,
  readAllNotifications,
  readNotificationAction,
  updateTenantAction,
} from '../../../../store/actions/user-actions';
import { AiioNotification } from '../../../../ts/interfaces';
import { mailCheckIconStyleProps, useBaseMessageItemClassname, useStyles } from './notifications-styles';
import { useNavigate } from 'react-router';
import { Alert24Regular, MailCheckmark20Regular } from '@fluentui/react-icons';
import { iconStyleProps, useHeaderBarIconStyles } from '../header-bar-styles';
import { Button, Persona, Popover, PopoverSurface, PopoverTrigger, mergeClasses } from '@fluentui/react-components';

const Notifications = (props: PropsFromRedux) => {
  const {
    UserReducer: { notifications, permissions, username },
    addQueryParamsAction,
  } = props;

  const classes = useStyles();
  const baseMessageItemClassname = useBaseMessageItemClassname();
  const iconHeaderBarStyleProps = useHeaderBarIconStyles();

  const navigate = useNavigate();

  const [showNotificationsDot, setShowNotificationsDot] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    if (!notifications || notifications.length === 0) {
      setUnreadCount(0);
    } else {
      const unreadNotifications = notifications.filter((item: AiioNotification) => !item.isRead);
      setUnreadCount(unreadNotifications.length);
    }
  }, [notifications]);
  interface NotificationBadgeProps {
    count: number;
  }

  const NotificationBadge = ({ count }: NotificationBadgeProps) => {
    return (
      <div className={classes.badge}>
        <span className={classes.badgeText}>{count}</span>
      </div>
    );
  };

  useEffect(() => {
    props.getNotificationsAction();
    const interval = setInterval(() => {
      props.getNotificationsAction();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (username && !window.wssocket) {
      window.wssocket = new WebSocket(`${GetWSApiUrl()}${username}/`);

      window.wssocket.onmessage = (event: MessageEvent) => {
        props.addNotificationAction(mapToCamel(JSON.parse(event.data)));
        console.log(`[message] Data received from server: ${event.data}`);
      };

      window.wssocket.onclose = (event: Event) => {
        if (event.wasClean) {
          console.log(`Realtime connection closed cleanly, code=${event.code} reason=${event.reason}...`);
        } else {
          console.log('Realtime connection died...');
        }
      };

      window.wssocket.onerror = (error: Event) => {
        console.log(error);
        console.log(`Realtime connection ${error.message}...`);
      };
    }
  }, [props.UserReducer]);

  useEffect(() => {
    if (!notifications || notifications.length === 0) {
      setShowNotificationsDot(false);
    } else {
      const allNotificationsRead: boolean = notifications.some((item: AiioNotification) => item.isRead === false);
      setShowNotificationsDot(allNotificationsRead);
    }
  }, [notifications]);

  const onShowNotification = (item: AiioNotification) => {
    let redirect: string;
    if (item.meta && item.meta.redirect) {
      redirect = item.meta.redirect;
    } else if (item.meta && item.meta instanceof String) {
      const json = JSON.parse(item.meta.replace(/'/g, '"'));
      redirect = json.redirect;
    } else {
      redirect = '/';
    }

    if (!checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_approvedby)) {
      if (redirect.indexOf('tasks/approval') !== -1) {
        redirect = '/tasks/approval';
      }
    }

    if (!checkPermission(permissions, GLOBALENUMS.PERMISSIONS.view_task_detail)) {
      if (redirect.indexOf('tasks/process-recording') !== -1) {
        redirect = '/tasks/process-recording';
      }
    }

    if (item.reminder) {
      const queryParams: any = [];
      queryParams.push({
        ['process_name__icontains']: item.reminder.process.processName,
      });
      queryParams.push({ filterValues: { processName: item.reminder.process.processName } });
      addQueryParamsAction(queryParams);
    }

    if (!item.isRead) {
      props.readNotificationAction(
        {
          id: item.id,
          isRead: 1,
        },
        () => {
          navigate(redirect);
        },
      );
    } else {
      navigate(redirect);
    }
  };

  const setAllAsSeen = () => {
    props.readAllNotifications(() => {});
  };

  const renderTranslatednMsg = (msg: string, option: string[]) => {
    return __(msg, null, option);
  };

  const renderMessage = (msgFirstPart: string, orgUnit: string[], msgSecondPart: string, option: string[]) => {
    return __(msgFirstPart) + `"${orgUnit}"` + __(msgSecondPart, null, option);
  };

  const renderMsgNotification = (item: AiioNotification) => {
    const msgCode = item.message;
    let option: any;
    let orgUnit: any;
    if (item.meta && item.meta.option) {
      option = item.meta.option;
      orgUnit = item.meta.org;
    } else if (item.meta && item.meta instanceof String) {
      const meta = JSON.parse(item.meta.replace(/'/g, '"'));
      option = meta.option;
      orgUnit = meta.org;
    } else {
      return 'Parsing error: ' + item.id + ', ' + item.message;
    }

    if (option) {
      switch (msgCode) {
        case 'employee assigned':
          return renderTranslatednMsg('has been assigned as owner to the process ""%s""', option);

        case 'department assigned':
          return renderMessage(
            'the department',
            orgUnit,
            'has been assigned as responsible to the process ""%s""',
            option,
          );

        case 'role assigned':
          return renderMessage('the role', orgUnit, 'has been assigned as responsible to the process ""%s""', option);

        case 'staff position assigned':
          return renderMessage(
            'the staff position',
            orgUnit,
            'has been assigned as responsible to the process ""%s""',
            option,
          );
        case 'process modeling assignment':
          return renderTranslatednMsg('sent you new modeling task to "%s" process', option);
        case 'process modeling assignment back':
          return renderTranslatednMsg('sent you back modeling task to "%s" process', option);

        case 'process recording':
          return renderTranslatednMsg('the process "%s" was sent to process recording', option);

        case 'process recording back':
          return renderTranslatednMsg('sent you back process recording task "%s" process', option);

        case 'process recording back all':
          return renderTranslatednMsg('All assignees have finished their process recording for process "%s%"', option);

        case 'approver notification':
          return renderTranslatednMsg('sent you new approval task to "%s" process', option);

        case 'raci notification':
          option = option.map((o: string) => __(o));
          return renderTranslatednMsg('assigned you RACI to "%s" process', option);

        case 'approved notification':
          return renderTranslatednMsg('has approved the process "%s"', option);

        case 'rejected notification':
          return renderTranslatednMsg('rejected the process "%s"', option);

        case 'modeler editor assigned':
          return renderTranslatednMsg('assigned you as editor for the process modeling of process "%s"', option);

        case 'editor assigned':
          return renderTranslatednMsg('assigned you as editor for the process map', option);

        case 'process reminder':
          return renderTranslatednMsg('please check if the process "%s" needs an update', option);

        case 'feedback given':
          return renderTranslatednMsg('has left a feedback for process "%s" ', option);

        case 'comment created':
          return renderTranslatednMsg('has left a comment for process "%s" ', option);
      }
    }
  };

  const NotificationsPopover = () => {
    return (
      <Popover>
        <PopoverTrigger>
          <div className={iconHeaderBarStyleProps}>
            <Alert24Regular {...iconStyleProps} />
            {showNotificationsDot && <NotificationBadge count={unreadCount} />}
          </div>
        </PopoverTrigger>

        <PopoverSurface className={classes.popoverSurface}>
          <div className={classes.notificationMessages}>
            <div className={classes.scrollableContainer}>
              {notifications.map((item: AiioNotification) => {
                return (
                  <div
                    key={`${item.id}-activity-content`}
                    className={mergeClasses(
                      baseMessageItemClassname,
                      item.isRead ? classes.messageItem : classes.messageItemNotSeen,
                    )}
                    onClick={() => onShowNotification(item)}
                  >
                    <div className={mergeClasses(classes.noticationContent, classes.horizontal)}>
                      <div className={classes.horizontal}>
                        {item.meta && !item.meta.hideUser && item.meta?.fromUser !== null && (
                          <Person userId={item.sender.username} view={ViewType.oneline} />
                        )}
                        {item.meta && !item.meta.hideUser && item.meta?.fromUser === null && (
                          <Persona size="medium" primaryText={__('anonyme')} textAlignment="center" />
                        )}
                        {item.meta && item.meta.hideUser && (
                          <div className={classes.iconAiio}>
                            <img src="/images/aiio-whopper.svg" />
                          </div>
                        )}
                        <div className={classes.messageText} id="MsgNotification">
                          {renderMsgNotification(item)}
                        </div>
                      </div>
                      <div className={classes.messageItemDate}>
                        {item.reminder
                          ? moment.utc(item.reminder.startDate).local().fromNow()
                          : moment.utc(item.createdAt).local().fromNow()}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.buttonMessages}>
            <Button
              onClick={() => setAllAsSeen()}
              icon={<MailCheckmark20Regular {...mailCheckIconStyleProps} />}
              title={__('all seen')}
              className={classes.buttonMailCheckmark}
            />
          </div>
        </PopoverSurface>
      </Popover>
    );
  };
  return <NotificationsPopover />;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, {
  getNotificationsAction,
  addNotificationAction,
  readNotificationAction,
  changeLanguageAction,
  updateTenantAction,
  addQueryParamsAction,
  readAllNotifications,
});
export default connector(Notifications);
