import { useState } from 'react';
import { Stack } from '@fluentui/react';
import { __, Popover, Modal, TextField, GLOBALENUMS } from 'modeling-tool';
import { useStyles } from './recording-button-section-styles';
import { ProcessInstance } from '../../../../ts/interfaces';
import { useForm } from 'react-hook-form';
import CommentModal from './comment-modal';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../config/store';
import ApproversListModal from './approvers-list-modal';
import { Button } from '@fluentui/react-components';
import { CheckmarkRegular, DismissRegular } from '@fluentui/react-icons';

interface RecordingButtonSectionProps {
  onSend: () => void;
  onAccept?: () => void;
  onSendBack?: (rejectionMessage: string) => void;
  onStartModelling?: () => void;
  onApprove?: () => void;
  selectedInstance?: ProcessInstance;
  isTaskDetailsView: boolean;
}

enum ButtonType {
  SEND,
  ACCEPT,
  APPROVE,
  START_MODELING,
  APPROVE_OR_REJECT,
}

const RecordingButtonSection = (props: RecordingButtonSectionProps & PropsFromRedux) => {
  const {
    UserReducer: { username },
  } = props;
  const { onSend, onAccept, onSendBack, selectedInstance, isTaskDetailsView } = props;

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const styles = useStyles();

  const handleOk = () => {
    handleSubmit(
      (data) => {
        onSendBack && onSendBack(data.message);
        handleCancel();
      },
      (err) => {
        console.log(err);
      },
    )();
    onSendBack && onSendBack;
  };

  const handleCancel = () => {
    setModalVisible(false);
    reset();
  };

  const shouldRenderButton = (type: ButtonType): boolean => {
    if (!selectedInstance || !selectedInstance.id) return false;
    switch (type) {
      case ButtonType.SEND: {
        return isTaskDetailsView && selectedInstance.state.type === GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING;
      }
      case ButtonType.ACCEPT: {
        return !!(
          !isTaskDetailsView &&
          [
            GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_REVIEW,
            GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_MODELING,
          ].includes(selectedInstance.state.type)
        );
      }
      case ButtonType.APPROVE_OR_REJECT: {
        return selectedInstance?.approvedBy?.some((item) => {
          return item.user?.username === username && item.status === GLOBALENUMS.APPROVALSTATUS.IN_APPROVAL;
        });
      }
      default:
        return false;
    }
  };

  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
        {shouldRenderButton(ButtonType.ACCEPT) && (
          <>
            <Button
              icon={<DismissRegular />}
              title={__('send back')}
              onClick={() => {
                setModalVisible(true);
              }}
              className={styles.blinkHint}
            />
            <Button
              icon={<CheckmarkRegular />}
              title={__('accept')}
              onClick={() => {
                onAccept && onAccept();
              }}
              className={styles.blinkHint}
            />
          </>
        )}
        {shouldRenderButton(ButtonType.SEND) && (
          <Popover
            content={__('are you sure? if you send this task, it will be saved and you will not be able to edit it.')}
            enableConfirm={true}
            onOk={onSend}
            target={'send-process'}
          >
            <Button id={'send-process'} icon={<DismissRegular />} title={__('send')} />
          </Popover>
        )}
        {shouldRenderButton(ButtonType.APPROVE_OR_REJECT) && selectedInstance && (
          <>
            <ApproversListModal />
            <CommentModal selectedProcessInstance={selectedInstance}></CommentModal>
          </>
        )}
      </Stack>
      <Modal
        title={`${__('process recording')} - ${selectedInstance && selectedInstance.instanceName}`}
        saveButtonText={__('send')}
        isModalOpen={modalVisible}
        onSave={handleOk}
        onCancel={handleCancel}
      >
        <form>
          <TextField label={__('message')} control={control} name={'message'} multiline rows={3} />
        </form>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, {});
export default connector(RecordingButtonSection);
