import { Avatar } from '@fluentui/react-components';

interface IGetPersonAvatarProps {
  displayName: string;
  personImage?: string;
}

const GetPersonAvatar = (props: IGetPersonAvatarProps) => {
  const { displayName, personImage } = props;

  return (
    <Avatar
      color="colorful"
      name={displayName}
      image={{
        src: personImage ? `data:image/png;base64,${personImage}` : undefined,
      }}
    />
  );
};

export default GetPersonAvatar;
