import { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApprovedByModal, ApproversList, ProcessRecordModeler } from '../../components';
import { RootState } from '../../config/store';
import { GLOBALENUMS, UtilityContext, __ } from 'modeling-tool';
import {
  fetchProcessAction,
  clearProcessAction,
  startProcessModelingAction,
  makeProcessVersionAction,
} from '../../store/actions/process-actions';
import {
  clearProcessInstanceAction,
  searchProcessInstancesAction,
  setProcessInstancesFromTaskAction,
} from '../../store/actions/process-instance-actions';
import { ButtonProps, Process, ProcessInstance } from '../../ts/interfaces';
import { History } from './History';
import { Modal } from 'modeling-tool';
import { ProcessInstanceInformationSection } from './process-instance-information-section';
import { useStyles } from './process-details-styles';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { checkPermission } from '../../config/permission-utils';
import { getFullModelByProcessId } from '../../store/actions/modeler-actions';
import SendForm from '../process-management/processes/send-form/send-form';
import { useBoolean } from '@fluentui/react-hooks';
import { fetchDropdownDataByType } from '../../store/actions/dropdown-data-actions';
import { Button, mergeClasses } from '@fluentui/react-components';
import {
  AddCircleRegular,
  CheckmarkRegular,
  EditRegular,
  EyeRegular,
  PeopleListRegular,
  SendRegular,
} from '@fluentui/react-icons';
import { StatusTagSplitButton } from './status-tag-split-button';

const dropDownData = [
  GLOBALENUMS.DROPDOWNDATA.RESOURCES,
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS_AND_STAFF_POSITIONS,
  GLOBALENUMS.DROPDOWNDATA.ROLES,
  GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS,
  GLOBALENUMS.DROPDOWNDATA.REQUIREMENTS,
  GLOBALENUMS.DROPDOWNDATA.REQUIREMENT_CHAPTERS,
];

const ProcessDetails = (props: PropsFromRedux) => {
  const {
    ProcessReducer: { selectedProcess },
    ProcessInstanceReducer: { selectedInstance },
    UserReducer: { permissions },
    clearProcessAction,
    clearProcessInstanceAction,
    fetchProcessAction,
    fetchDropdownDataByType,
    searchProcessInstancesAction,
    startProcessModelingAction,
    getFullModelByProcessId,
    makeProcessVersionAction,
    setProcessInstancesFromTaskAction,
  } = props;

  const [isApprovedByModalVisible, { setTrue: showApprovalModal, setFalse: dismissApprovalModal }] = useBoolean(false);
  const [showApprovalStatus, { setTrue: showApprovalStatusModal, setFalse: dismissApprovalStatusModal }] =
    useBoolean(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [isAiPanelOpen, setIsAiPanelOpen] = useState<boolean>(false);
  const [sendVisible, setSendVisible] = useState<boolean>(false);
  const [sendModel, setSendModel] = useState<boolean>(false);
  const { setBreadcrumbButtons, fullScreenMode } = useContext(UtilityContext);
  const [topSectionContent, setTopSectionContent] = useState<JSX.Element>();

  const styles = useStyles();
  const modelerWrapperStyles = mergeClasses(
    styles.modelerWrapper,
    isAiPanelOpen ? styles.modelerWrapperAiPanelOpen : styles.modelerWrapperAiPanelClosed,
  );
  const modellerWrapperFullscreenStyles = mergeClasses(
    styles.modelerWrapperFullscreen,
    isAiPanelOpen ? styles.modelerWrapperAiPanelOpen : styles.modelerWrapperAiPanelClosed,
  );
  const centerSendBtn = mergeClasses(styles.centerBtn, styles.sendBtn);
  const centerHeader = mergeClasses(styles.semiBoldText, styles.fontSizeLarge);
  const centerSubtext = mergeClasses(styles.semiBoldText, styles.fontSizeSmall);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
    return () => {
      setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
      clearProcessAction();
      clearProcessInstanceAction();
    };
  }, []);

  useEffect(() => {
    if (selectedInstance) {
      setTopSectionContent(
        <ProcessRecordModeler
          isDetailsView={true}
          isTaskDetailsView={false}
          onShowHistoryModal={() => setShowHistoryModal(true)}
          setIsAiPanelOpen={setIsAiPanelOpen}
        />,
      );
    }
  }, [selectedInstance]);

  useEffect(() => {
    if (params && params.id) {
      const id = parseInt(params.id);
      clearProcessInstanceAction(() => {
        clearProcessAction(() => {
          fetchProcessAction(id, (processRes: any) => {
            // handle the different process states
            switch (processRes.data.state.type) {
              case GLOBALENUMS.PROCESSSTATUS.NEW: {
                handleStateNew(processRes.data);
                break;
              }
              case GLOBALENUMS.PROCESSSTATUS.IN_RECORDING:
              case GLOBALENUMS.PROCESSSTATUS.IN_MODELING: {
                handleStateInRecordingAndInModeling(processRes.data);
                break;
              }
              case GLOBALENUMS.PROCESSSTATUS.IN_APPROVAL: {
                handleStateInApprovalOrDone(processRes.data);
                break;
              }
              case GLOBALENUMS.PROCESSSTATUS.DONE: {
                handleStateInApprovalOrDone(processRes.data);
                break;
              }
              default: {
                setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
                setTopSectionContent(<></>);
              }
            }
          });
        });
      });
    }
    return () => {
      setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
      setTopSectionContent(undefined);
    };
  }, [params.id]);

  const handleStateNew = (process: Process) => {
    setBreadcrumbButtons &&
      setBreadcrumbButtons({ list: [<StatusTagSplitButton processState={process.state} menuButtons={[]} />] });
    setTopSectionContent &&
      setTopSectionContent(
        <div className={styles.centerSection}>
          <div className={styles.centerTextSection}>
            <div className={centerHeader}>{__('this process has not yet been modeled.')}</div>
            <div className={centerSubtext}>{__('you can make the start or invite your colleagues.')}</div>
          </div>
          <div className={styles.centerBtnSection}>
            <Button
              className={styles.centerBtn}
              appearance="primary"
              onClick={() => onStartModeling(process)}
              icon={<EditRegular />}
            >
              {__('start modeling')}
            </Button>
            <Button className={centerSendBtn} onClick={onSendModelingTask} icon={<SendRegular />}>
              {__('send process modeling')}
            </Button>
          </div>
        </div>,
      );
  };

  const handleStateInRecordingAndInModeling = (process: Process) => {
    if (checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_modelerfullmodel)) {
      getFullModelByProcessId(process.id, (res) => {
        let canStartModeling = true;
        canStartModeling =
          (res?.data?.objects?.length &&
            ![
              GLOBALENUMS.MODELSTATE.LOCKED,
              GLOBALENUMS.MODELSTATE.NO_EDITOR,
              GLOBALENUMS.MODELSTATE.IN_EDITING,
            ].includes(res.data.objects[0].modelState)) ||
          res.data.objects[0].adminUnlocked;
        const hasApprovers = !!res.data.objects[0]?.fromInstance?.approvedBy?.length;
        const menuButtons = getMenuButtonProps(process, canStartModeling, hasApprovers);
        setBreadcrumbButtons &&
          setBreadcrumbButtons({
            list: [<StatusTagSplitButton processState={process.state} menuButtons={menuButtons} />],
          });
      });
    } else {
      const buttons: ButtonProps[] = getMenuButtonProps(process);
      setBreadcrumbButtons &&
        setBreadcrumbButtons({ list: [<StatusTagSplitButton processState={process.state} menuButtons={buttons} />] });
    }

    if (!process.hasModelWithContent && process.state.type === GLOBALENUMS.PROCESSSTATUS.IN_RECORDING) {
      setTopSectionContent(
        <div className={styles.centerTextSection}>
          <div className={centerHeader}>{__('the process is currently being modeled by your colleagues.')}</div>
          <div className={centerSubtext}>{__('however, you can also start modeling it yourself.')}</div>
        </div>,
      );
    } else {
      setInstanceByProcessState(process);
    }
  };

  const handleStateInApprovalOrDone = (process: Process) => {
    const buttons: ButtonProps[] = getMenuButtonProps(process);
    setBreadcrumbButtons &&
      setBreadcrumbButtons({ list: [<StatusTagSplitButton processState={process.state} menuButtons={buttons} />] });
    setInstanceByProcessState(process);
  };

  const getMenuButtonProps = (process: Process, canStartModeling?: boolean, hasApprovers?: boolean): ButtonProps[] => {
    const buttons: ButtonProps[] = [];
    if (process) {
      switch (process.state.type) {
        case GLOBALENUMS.PROCESSSTATUS.IN_RECORDING:
        case GLOBALENUMS.PROCESSSTATUS.IN_MODELING: {
          checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_modelerfullmodel) &&
            canStartModeling &&
            buttons.push({
              icon: <EditRegular />,
              text: __('start modeling'),
              onClick: () => onStartModeling(process),
            });
          checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_processinstance) &&
            canStartModeling &&
            buttons.push({
              icon: <SendRegular />,
              text: __('send process modeling'),
              onClick: onSendModelingTask,
            });
          process.hasRecordingInstances &&
            buttons.push({
              icon: <EyeRegular />,
              text: __('view process recording'),
              onClick: () => navigate(`/process-management/processes/${process.id}/applications`),
            });
          canStartModeling &&
            buttons.push({
              icon: <CheckmarkRegular />,
              text: __('approve'),
              onClick: showApprovalModal,
            });
          hasApprovers &&
            buttons.push({
              icon: <PeopleListRegular />,
              text: __('approvers'),
              onClick: showApprovalStatusModal,
            });
          break;
        }
        case GLOBALENUMS.PROCESSSTATUS.IN_APPROVAL: {
          buttons.push({
            icon: <PeopleListRegular />,
            text: __('approvers'),
            onClick: showApprovalStatusModal,
          });
          break;
        }
        case GLOBALENUMS.PROCESSSTATUS.DONE: {
          buttons.push({
            icon: <PeopleListRegular />,
            text: __('approvers'),
            onClick: showApprovalStatusModal,
          });
          checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_process) &&
            !process.hasVersion &&
            buttons.push({
              icon: <AddCircleRegular />,
              text: __('create new version'),
              onClick: () =>
                makeProcessVersionAction(process, (res) => {
                  if (res.data) {
                    const redirect = `/process-management/processes/${res.data.id}`;
                    navigate(redirect);
                  }
                }),
            });
          break;
        }
        default:
      }
    }
    return buttons;
  };

  const setInstanceByProcessState = (process: Process): string => {
    let instanceFilter = `?source_process__id=${process.id}`;
    switch (process?.state?.type) {
      case GLOBALENUMS.PROCESSSTATUS.IN_RECORDING:
      case GLOBALENUMS.PROCESSSTATUS.IN_MODELING: {
        instanceFilter += `&state__type__in=${[
          GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_MODELING,
          GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_ACCEPTED,
        ]}`;
        break;
      }
      case GLOBALENUMS.PROCESSSTATUS.IN_APPROVAL: {
        instanceFilter += `&get_in_approval=True`;
        break;
      }
      case GLOBALENUMS.PROCESSSTATUS.DONE: {
        instanceFilter += `&get_approved=True`;
        break;
      }
      default:
    }
    searchProcessInstancesAction(instanceFilter, (res) => {
      if (res.data.objects.length) {
        setProcessInstancesFromTaskAction(res.data.objects[0]);
        if (res.data.objects[0].state.type === GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_ACCEPTED) {
          setBreadcrumbButtons &&
            setBreadcrumbButtons({
              list: [<StatusTagSplitButton processState={res.data.objects[0].state} menuButtons={[]} />],
            });
        }
      }
    });
    return instanceFilter;
  };

  const onStartModeling = (process: Process) => {
    startProcessModelingAction(process.id, (res: any) => {
      if (res.data) {
        const redirect = `/process-management/processes/${process.id}/modeling-tool/${res.data.id}`;
        navigate(redirect);
      }
    });
  };

  const onSendModelingTask = () => {
    setSendModel(true);
    setSendVisible(true);
  };

  return (
    <>
      <div className={styles.detailsWrapper}>
        <div className={fullScreenMode ? modellerWrapperFullscreenStyles : modelerWrapperStyles}>
          {topSectionContent}
        </div>
        {selectedProcess && selectedInstance && (
          <ProcessInstanceInformationSection processData={selectedProcess} processInstanceData={selectedInstance} />
        )}
      </div>
      {sendVisible && selectedProcess && (
        <SendForm
          visible={sendVisible}
          setVisible={setSendVisible}
          selected={selectedProcess}
          afterSend={() => navigate('/process-management/processes')}
          sendModel={sendModel}
        />
      )}
      {showHistoryModal && (
        <Modal
          title={__('history')}
          isModalOpen={showHistoryModal}
          hideFooter={true}
          onCancel={() => {
            setShowHistoryModal(false);
          }}
        >
          <History selectedProcess={selectedProcess} />
        </Modal>
      )}
      {selectedInstance && (
        <ApprovedByModal
          selectedInstance={selectedInstance}
          isApprovedByModalVisible={isApprovedByModalVisible}
          handleApprovedByCancel={dismissApprovalModal}
        />
      )}
      {showApprovalStatus && selectedInstance && (
        <Modal
          title={__('change details')}
          isModalOpen={showApprovalStatus}
          hideFooter={true}
          onSave={dismissApprovalStatusModal}
          onCancel={dismissApprovalStatusModal}
        >
          <ApproversList item={selectedInstance} />
        </Modal>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ProcessReducer, ProcessInstanceReducer, UserReducer }: RootState) => ({
  ProcessReducer,
  ProcessInstanceReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  clearProcessInstanceAction,
  fetchProcessAction,
  fetchDropdownDataByType,
  clearProcessAction,
  searchProcessInstancesAction,
  startProcessModelingAction,
  getFullModelByProcessId,
  makeProcessVersionAction,
  setProcessInstancesFromTaskAction,
});
export default connector(ProcessDetails);
