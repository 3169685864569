import Environment from '../config.json';

export function IsProduction(): boolean {
  return process.env.REACT_APP_NODE_ENV == 'production' ? true : false;
}

export function GetAppId(): string {
  return process.env.REACT_APP_AZURE_APP_ID === undefined
    ? Environment.REACT_APP_AZURE_APP_ID
    : process.env.REACT_APP_AZURE_APP_ID;
}

export function GetRedirectUri(): string {
  return process.env.REACT_APP_REDIRECT_URI === undefined
    ? Environment.REACT_APP_REDIRECT_URI
    : process.env.REACT_APP_REDIRECT_URI;
}

export function GetApiScope(): string {
  return process.env.REACT_APP_API_SCOPE === undefined
    ? Environment.REACT_APP_API_SCOPE
    : process.env.REACT_APP_API_SCOPE;
}

export function GetApiUrl(): string {
  return process.env.REACT_APP_API_URL === undefined ? Environment.REACT_APP_API_URL : process.env.REACT_APP_API_URL;
}

export function IsTeamsApp(): boolean {
  const value =
    process.env.REACT_APP_ISTEAMS === undefined ? Environment.REACT_APP_ISTEAMS : process.env.REACT_APP_ISTEAMS;
  if (value == 'true') return true;
  else return false;
}

export function PendoEnabled(): boolean {
  const value =
    process.env.REACT_APP_ENABLE_PENDO === undefined
      ? Environment.REACT_APP_ENABLE_PENDO
      : process.env.REACT_APP_ENABLE_PENDO;
  if (value == 'true') return true;
  else return false;
}

export function GetWSApiUrl(): string {
  return process.env.REACT_APP_WS_API_URL === undefined
    ? Environment.REACT_APP_WS_API_URL
    : process.env.REACT_APP_WS_API_URL;
}

export function GetAppInsightsKey(): string {
  return process.env.REACT_APP_INSTRUMENTATION_KEY === undefined
    ? Environment.REACT_APP_INSTRUMENTATION_KEY
    : process.env.REACT_APP_INSTRUMENTATION_KEY;
}

export function GetAzureMapsKey(): string {
  return process.env.REACT_APP_AZURE_MAPS_KEY === undefined
    ? Environment.REACT_APP_AZURE_MAPS_KEY
    : process.env.REACT_APP_AZURE_MAPS_KEY;
}
export function IsTenantCreationAllowed(): boolean {
  const value =
    process.env.REACT_APP_TENANT_CREATION_ALLOWED === undefined
      ? Environment.REACT_APP_TENANT_CREATION_ALLOWED
      : process.env.REACT_APP_TENANT_CREATION_ALLOWED;
  if (value == 'true') return true;
  else return false;
}
