import { useCallback, useEffect, useState } from 'react';
import { MinorPermission, PermissionModalListItem } from '../ts/interfaces';
import { Providers } from '@microsoft/mgt-element';

const userPermissions = [
  {
    permissionType: 'access to your colleagues',
    permissions: [
      {
        permission: 'User.Read.All',
        type: 'userReadAll',
        infoText: 'displaying the profile of people',
        granted: false,
        checked: true,
      },
      {
        permission: 'People.Read',
        type: 'peopleRead',
        infoText: 'suggesting people in search fields',
        granted: false,
        checked: true,
      },
      {
        permission: 'Group.Read.All',
        type: 'groupReadAll',
        infoText: 'displaying Groups in search fields',
        granted: false,
        checked: false,
      },
    ],
  },
  {
    permissionType: 'access to SharePoint Online',
    permissions: [
      {
        permission: 'Sites.Read.All',
        type: 'sitesReadAll',
        infoText: 'access to SharePoint Online document libraries',
        granted: false,
        checked: true,
      },
      {
        permission: 'Sites.ReadWrite.All',
        type: 'sitesReadWriteAll',
        infoText: 'upload files to SharePoint Online',
        granted: false,
        checked: false,
      },
    ],
  },
  {
    permissionType: 'sending and receiving notifications',
    permissions: [
      {
        permission: 'Mail.Send',
        type: 'mailSend',
        infoText: 'automatic mail notifications for new users',
        granted: false,
        checked: true,
      },
      {
        permission: 'TeamsActivity.Send',
        type: 'teamsActivitySend',
        infoText: 'sending notifications in teams',
        granted: false,
        checked: true,
      },
    ],
  },
];

export const useCheckUserPermissions = () => {
  const [permissionGroups, setPermissionGroups] = useState<PermissionModalListItem[]>(userPermissions);
  const [openPermissionsModal, setOpenPermissionsModal] = useState<boolean>(false);

  const checkPermissions = useCallback(async () => {
    const updatedPermissions = [...permissionGroups];

    const checkPermission = async (permission: MinorPermission) => {
      const loginResponse = await Providers.globalProvider.getAccessTokenForScopes(permission.permission);
      if (loginResponse !== null) {
        permission.granted = true;
        permission.checked = true;
      }
      return permission.granted;
    };

    const promises = updatedPermissions.flatMap((group) =>
      group.permissions.map((permission) => checkPermission(permission)),
    );

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Check if any of the specific permissions are missing
    const hasMissingPermission = updatedPermissions
      .flatMap((group) => group.permissions)
      .some((permission) => !permission.granted);
    setOpenPermissionsModal(hasMissingPermission);
    setPermissionGroups(updatedPermissions);
  }, [permissionGroups]);

  useEffect(() => {
    checkPermissions();
  }, []);

  return { permissionGroups, openPermissionsModal, setOpenPermissionsModal };
};
