import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  updateEmployeeAction,
  selectEmployeeByIdAction,
  fetchEmployeeAction,
  addEmployeeAction,
} from '../../../store/actions/employee-actions';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { Employee, EmployeeOrgsType, Department, Role, WorkingGroup, User } from '../../../ts/interfaces';
import { RootState } from '../../../config/store';
import { useForm } from 'react-hook-form';
import { IDropdownOption, Label } from '@fluentui/react';
import { Modal, DropdownWithSearch, PeoplePicker } from 'modeling-tool';
import { renderSelectDropDown } from '../../../utils/process-management';

interface EmployeeFormProps extends PropsFromRedux {
  visible: boolean;
  setVisible(visible: boolean): void;
}

interface EmployeeFormValues {
  id?: number;
  username: string;
  department?: number[];
  departmentsHead?: number[];
  role?: number[];
  locations?: string[];
  workingGroup?: string[];
  isEmployee?: boolean;
  createdBy?: User;
}

const EmployeeForm = (props: EmployeeFormProps) => {
  const {
    EmployeeReducer: { selectedEmployee },
    DropdownDataReducer: { orgUnits: departments, workingGroups, roles, locations },
    visible,
    addEmployeeAction,
    fetchEmployeeAction,
    selectEmployeeByIdAction,
    setVisible,
    updateEmployeeAction,
  } = props;
  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (visible && !selectedEmployee) {
      reset({});
      return;
    }
    if (selectedEmployee) {
      const employeeFormValues = prepareEmployeeFormValues(selectedEmployee);
      reset(employeeFormValues);
    }
  }, [visible]);

  const prepareEmployeeFormValues = (selectedEmployee: Employee) => {
    const { departments, departmentsHead, roles, workingGroup, locations } = selectedEmployee;
    const employeeFormValues: EmployeeFormValues = {
      ...selectedEmployee,
      department: departments?.map((department: EmployeeOrgsType) => department[0]),
      departmentsHead: departmentsHead?.map((departmentsHead: EmployeeOrgsType) => departmentsHead[0]),
      role: roles?.map((role: EmployeeOrgsType) => role[0]),
      workingGroup: workingGroup?.map((workingGroup: WorkingGroup) => workingGroup.resourceUri),
      locations: locations?.map((location: any) => location.resourceUri),
    };
    return employeeFormValues;
  };

  const updateEmployeeDetails = (values: EmployeeFormValues) => {
    updateEmployeeAction(values, () => {
      setShowLoading(false);
      setVisible(false);
      selectEmployeeByIdAction(-1);
      fetchEmployeeAction();
    });
  };

  const getDepartmentDropDownOptions = () => {
    const options = departments?.map((department: Department) => {
      return { key: department.id, text: department.departmentName } as IDropdownOption;
    });
    return options;
  };

  const getRolesDropDownOptions = () => {
    const options = roles?.map((role: Role) => {
      return { key: role.id, text: role.roleName } as IDropdownOption;
    });
    return options;
  };

  const onSave = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);

        let values = data as EmployeeFormValues;
        if (selectedEmployee?.id) {
          values = { ...values, id: selectedEmployee.id };
          delete values.createdBy;
          updateEmployeeDetails(values);
        } else {
          values = { ...values, isEmployee: true };
          addEmployeeAction(values, () => {
            setShowLoading(false);
          });
          setVisible(false);
          selectEmployeeByIdAction(-1);
        }
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const hideDialog = () => {
    selectEmployeeByIdAction(-1);
    setVisible(false);
  };

  return (
    <Modal
      title={__('add employee')}
      isModalOpen={visible}
      onSave={onSave}
      enableProgress={showLoading}
      onCancel={() => hideDialog()}
    >
      <form>
        <Label required styles={{ root: { fontWeight: 400 } }}>
          {__('employee')}
        </Label>
        <PeoplePicker
          selectionMode="single"
          name={'username'}
          control={control}
          rules={{ required: __('This field is required') }}
        />
        <DropdownWithSearch
          options={getDepartmentDropDownOptions()}
          label={__('head of')}
          control={control}
          name={'departmentsHead'}
          placeholder={__('please select department')}
          multiSelect
          labelTitle={__('head of')}
        />
        <DropdownWithSearch
          options={getDepartmentDropDownOptions()}
          label={__('employee')}
          control={control}
          name={'department'}
          placeholder={__('please select department')}
          multiSelect
          labelTitle={__('departments')}
        />
        <DropdownWithSearch
          options={getRolesDropDownOptions()}
          control={control}
          name={'role'}
          placeholder={__('please select role')}
          multiSelect
          labelTitle={__('roles')}
        />
        <DropdownWithSearch
          options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.LOCATIONS, locations)}
          control={control}
          name={'locations'}
          placeholder={__('please select a location')}
          multiSelect
          labelTitle={__('locations')}
        />
        <DropdownWithSearch
          options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS, workingGroups)}
          control={control}
          name={'workingGroup'}
          placeholder={__('please select working group')}
          multiSelect
          labelTitle={__('working groups')}
        />
      </form>
    </Modal>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ EmployeeReducer, DropdownDataReducer }: RootState) => ({
  EmployeeReducer,
  DropdownDataReducer,
});
const connector = connect(mapStateToProps, {
  addEmployeeAction,
  fetchEmployeeAction,
  selectEmployeeByIdAction,
  updateEmployeeAction,
});
export default connector(EmployeeForm);
