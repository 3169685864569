import { makeStyles, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  blinkHint: {
    color: tokens.colorNeutralForeground2BrandHover,
    minWidth: '100px',
    animationIterationCount: 3,
    animationDuration: '1s',
    animationName: {
      from: { opacity: 0.25 },
      to: { opacity: 1 },
    },
  },
});
