import { __ } from "modeling-tool";
import { fetchEmployeeMetaDataFromMS } from "../../store/actions/employee-actions";
import { Employee } from "../../ts/interfaces";

export const updateEmployeesMetadataFromMS = (employees: Employee[], updateLocalEmployeesCallBack: (employees: Employee[]) => void) => {
    // for each employee, fetch the metadata from MS Graph
    const fetchPromises: Promise<Employee>[] = employees.map((employee) => {
        return new Promise((resolve, reject) => {
            fetchEmployeeMetaDataFromMS(
                employee.username,
                (data) => {
                    if (data.responses && Array.isArray(data.responses) && data.responses.length > 0) {
                        // sort the responses by id
                        data.responses.sort((a: any, b: any) => a.id - b.id);

                        const metadata =
                            data.responses[0].status === 200
                                ? {
                                    id: data.responses[0].body.id,
                                    displayName: data.responses[0].body.displayName,
                                    email: data.responses[0].mail,
                                    jobTitle: data.responses[0].body.jobTitle,
                                    businessPhones: data.responses[0].body.businessPhones,
                                    personImage: data.responses[1].body,
                                }
                                : {
                                    id: employee.id,
                                    displayName: __('user not found'),
                                    email: 'N/A',
                                    jobTitle: 'N/A',
                                    businessPhones: 'N/A',
                                    personImage: data.responses[1].body,
                                };
                        const currentPerson: Employee = {
                            ...employee,
                            displayName: metadata.displayName,
                            email: metadata.email,
                            jobTitle: metadata.jobTitle,
                            businessPhones: metadata.businessPhones,
                            personImage: metadata.personImage,
                        };
                        resolve(currentPerson);
                    } else {
                        reject(new Error('Invalid response format'));
                    }
                },
                (error) => {
                    console.error('employee metadata error', error);
                    reject(error);
                },
            );
        });
    });

    // Wait for all metadata fetches to complete
    Promise.all(fetchPromises)
        .then((results) => {
            results = results.sort((a, b) => {
                const nameA = a.displayName || '';
                const nameB = b.displayName || '';
                return nameA.localeCompare(nameB);
            });
            updateLocalEmployeesCallBack(results);
        })
        .catch((error) => {
            console.error('Error fetching employee metadata', error);
        });
};


