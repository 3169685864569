import {
  DirectionalHint,
  DocumentCard,
  getId,
  Icon,
  List,
  ScrollablePane,
  Stack,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { Card, GLOBALENUMS } from 'modeling-tool';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { checkPermission } from '../../../config/permission-utils';
import { RootState } from '../../../config/store';
import { getDateFormatted } from '../../../config/utils';
import { __, PeopleWithPopover } from 'modeling-tool';
import {
  deleteWorkingGroupAction,
  fetchWorkingGroupsAction,
  selectWorkingGroupByIdAction,
} from '../../../store/actions/working-group-actions';
import { Location, WorkingGroup } from '../../../ts/interfaces';
import { cardStyles, listClassStyles, stackItemStyles, toolTipStyles } from './work-groups-styles';
import { useStyleProps } from '../../../hooks';

interface WorkingGroupsCardProps extends PropsFromRedux {
  status: string;
  setModalVisible(visible: boolean): void;
}
const WorkingGroupsCard = (props: WorkingGroupsCardProps) => {
  const {
    WorkingGroupReducer: { workinggroups },
    UserReducer,
    status,
    setModalVisible,
    fetchWorkingGroupsAction,
    deleteWorkingGroupAction,
    selectWorkingGroupByIdAction,
  } = props;

  const styleProps = useStyleProps();
  const styles = cardStyles(styleProps);

  const onWorkingGroupEdit = (record: WorkingGroup) => {
    selectWorkingGroupByIdAction(record.id);
    setModalVisible(true);
  };

  const onDeleteWorkingGroup = (workingGroup?: WorkingGroup) => {
    deleteWorkingGroupAction(workingGroup);
    fetchWorkingGroupsAction();
  };

  const RenderWorkingGroupCard = (workingGroup: WorkingGroup) => {
    const workingGroupsString: string =
      workingGroup.groupLocations?.map((location: Location) => location.locationName).join(`, `) || '';
    const members: string = workingGroup.employees.map((member) => member.username).join(',');
    const rndId = parseInt(getId());
    return (
      <>
        <div className={styles.containerCard}>
          <Card
            title={workingGroup?.title}
            onEdit={() => onWorkingGroupEdit(workingGroup)}
            canEdit={checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.change_workinggroup)}
            onDelete={() => onDeleteWorkingGroup(workingGroup)}
            canDelete={checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.delete_workinggroup)}
            cardStyles={styles.card}
            btnsStyles={styles.cardButtons}
            showSeparator={true}
            titleStyles={styles.title}
          >
            <div className={styles.bodyCard}>
              <Stack wrap horizontal>
                <Stack.Item grow={10}>
                  {__('members')}
                  <div className={styles.cardParticipants}>
                    {members && members.length ? (
                      <PeopleWithPopover employees={members} field={'members'} id={rndId} />
                    ) : (
                      ''
                    )}
                  </div>
                </Stack.Item>
                <Stack.Item grow={-1}>
                  {__('created date')}
                  <div className={styles.cardDate}>
                    {workingGroup?.dateCreated && getDateFormatted(workingGroup?.dateCreated)}
                  </div>
                </Stack.Item>
              </Stack>
              <Stack horizontal styles={stackItemStyles}>
                <TooltipHost
                  styles={toolTipStyles}
                  content={workingGroupsString}
                  directionalHint={DirectionalHint.bottomCenter}
                >
                  <div className={styles.location}>
                    {workingGroupsString && workingGroupsString.length && (
                      <Icon iconName="POISolid" className={styles.icon} />
                    )}
                    {workingGroupsString}
                  </div>
                </TooltipHost>
              </Stack>
            </div>
          </Card>
        </div>
      </>
    );
  };

  const onRenderCellActive = React.useCallback((workingGroup?: WorkingGroup) => {
    return workingGroup?.status === GLOBALENUMS.WORKINGGROUPSTATUS.ACTIVE ? (
      RenderWorkingGroupCard(workingGroup)
    ) : (
      <></>
    );
  }, []);

  const onRenderCellInActive = React.useCallback((workingGroup?: WorkingGroup) => {
    return workingGroup?.status === GLOBALENUMS.WORKINGGROUPSTATUS.INACTIVE ? (
      RenderWorkingGroupCard(workingGroup)
    ) : (
      <></>
    );
  }, []);

  return (
    <DocumentCard className={styles.globalCardStyles}>
      <div className={styles.globalstatus}>{__(status)}</div>
      <div className={styles.wrapper}>
        <ScrollablePane scrollContainerFocus={true}>
          <List
            items={workinggroups}
            className={listClassStyles()}
            onRenderCell={status === GLOBALENUMS.WORKINGGROUPSTATUS.ACTIVE ? onRenderCellActive : onRenderCellInActive}
          />
        </ScrollablePane>
      </div>
    </DocumentCard>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ WorkingGroupReducer, UserReducer }: RootState) => ({
  WorkingGroupReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  fetchWorkingGroupsAction,
  selectWorkingGroupByIdAction,
  deleteWorkingGroupAction,
});
export default connector(WorkingGroupsCard);
