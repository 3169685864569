import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { clearProcessAction, fetchProcessAction } from '../../store/actions/process-actions';
import {
  searchProcessInstancesAction,
  updateProcessInstanceAction,
  selectProcessInstanceAction,
  clearProcessInstancesAction,
} from '../../store/actions/process-instance-actions';
import { ProcessRecordModeler } from '../../components';
import { useAlert } from 'react-alert';
import './process-applications.less';
import { setNewProcessInstanceStateAction } from '../../store/actions/process-state-actions';
import { useLocation, useParams, useNavigate } from 'react-router';

const ProcessApplications = (props: PropsFromRedux) => {
  const {
    UserReducer: { username },
    ProcessInstanceReducer: { selectedInstance },
    clearProcessAction,
    fetchProcessAction,
    searchProcessInstancesAction,
    updateProcessInstanceAction,
    selectProcessInstanceAction,
    clearProcessInstancesAction,
    setNewProcessInstanceStateAction,
  } = props;
  const alert = useAlert();

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (username) {
      clearProcessInstancesAction(() => {
        getInstances(parseInt(params.id as string));
      });
    }
  }, [location.search, username]);

  useEffect(() => {
    if (params && params.id) {
      clearProcessAction(() => {
        fetchProcessAction(parseInt(params.id as string));
      });
    }
  }, []);

  const getInstances = (id?: number) => {
    selectProcessInstanceAction({});
    let url = `${location.search}${location.search === '' ? `?source_process=${id}` : `&source_process=${id}`}`;
    url += `&state__type__in=${[
      GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING,
      GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_REVIEW,
    ]}`;
    searchProcessInstancesAction(url);
  };

  const onAccept = () => {
    if (selectedInstance) {
      setNewProcessInstanceStateAction(
        selectedInstance,
        GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_ACCEPTED,
        (res: any) => {
          clearProcessInstancesAction(() => {
            getInstances(parseInt(params.id as string));
            alert.success(__('process instance was accepeted successfully, new instance created from it!'));
            if (res.data.sourceProcess) {
              navigate(`/process-management/processes/${res.data.sourceProcess.id}`);
            }
          });
        },
      );
    }
  };

  const onSendBack = (rejectionMessage: string) => {
    if (!selectedInstance) return;
    setShowLoading(true);
    selectedInstance.message = rejectionMessage;
    setNewProcessInstanceStateAction(
      selectedInstance,
      GLOBALENUMS.PROCESSSTATUS.INSTANCE_REJECTED,
      (msg, withError) => {
        if (withError) {
          setShowLoading(false);
          alert.error(`${__('Error')} ${msg.response.status}`);
        } else {
          setShowLoading(false);
          alert.success(__('task was send back'));
        }
      },
      (error) => {
        console.log(error);
        setShowLoading(false);
        alert.error(`${__('Error')} ${error?.response?.status}`);
      },
    );
    updateProcessInstanceAction(
      {
        id: selectedInstance?.id,
        message: rejectionMessage,
        isNew: false,
      },
      (msg, withError) => {
        if (withError) {
          setShowLoading(false);
          alert.error(`${__('Error')} ${msg.response.status}`);
        } else {
          setShowLoading(false);
          alert.success(__('task was send back'));
        }
      },
    );
  };

  return (
    <>
      <ProcessRecordModeler onAccept={onAccept} onSendBack={onSendBack} enableLoading={showLoading} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ProcessReducer, UserReducer, ProcessInstanceReducer }: RootState) => ({
  ProcessReducer,
  UserReducer,
  ProcessInstanceReducer,
});
const connector = connect(mapStateToProps, {
  clearProcessAction,
  fetchProcessAction,
  searchProcessInstancesAction,
  updateProcessInstanceAction,
  selectProcessInstanceAction,
  clearProcessInstancesAction,
  setNewProcessInstanceStateAction,
});
export default connector(ProcessApplications);
