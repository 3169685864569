import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
  title: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    paddingBottom: '8px',
  },
  titleList: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
  },
  dataArea: {
    marginBottom: '24px',
  },
});
