import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  totalPriceDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  totalPriceText1: {
    fontSize: '24px',
    fontWeight: '600',
  },
  totalPriceText2: {
    fontSize: '24px',
    fontWeight: '600',
    marginLeft: 'auto',
  },
  termsDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '30px',
  },
  paymentButtonsDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
  },
  payOnAccountSpan: {
    fontSize: '24px',
    fontWeight: '600',
  },
  payOnlineSpan: {
    fontSize: '24px',
    fontWeight: '600',
  },
  billingdataDiv: {
    marginTop: '20px',
  },
  paymentButton: {
    ...shorthands.padding('5px', '12px', '5px', '12px'),
    '&:disabled': {
      backgroundColor: '#F0F0F0',
      '&:hover': {
        backgroundColor: '#F0F0F0',
      },
    },
  },
  billingDataTextDiv: {
    fontSize: '20px',
    fontWeight: '600',
  },
});
