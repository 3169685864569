import { BasicDialog, GLOBALENUMS, __ } from 'modeling-tool';
import { Checkbox, MessageBar, MessageBarType } from '@fluentui/react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { useStyles } from './licence-payment-styles';
import { annualLicensePriceDetails, monthlyLicensePriceDetails } from '../checkout-constants';
import React, { useEffect } from 'react';
import {
  payOnlineStripeAction,
  payOnlineStripeLicenseExpiryAction,
  updatePayOnlineStripeCheckOutAction,
  payOnAccountAction,
  payOnAccountActionLicenseExpiryAction,
  updatePayOnAccountActionCheckOutAction,
} from '../../../../store/actions/licence-check-out-actions';
import { loadStripe } from '@stripe/stripe-js';
import { useBoolean } from '@fluentui/react-hooks';
import { useLocation } from 'react-router-dom';
import { BillingData } from '../billing-data';
import { useForm } from 'react-hook-form';
import { AutoCompletePlaceAddress } from '../../../../ts/interfaces';
import { Button, DialogTrigger } from '@fluentui/react-components';
import { DocumentBulletListRegular, PaymentRegular } from '@fluentui/react-icons';

interface LicencePaymentProps {
  isAnnualBillingEnabled: boolean;
  isNormalPlan: boolean;
}

interface CustomerBillingDataForm {
  name: string;
  address_line_1: string;
  address_line_2: string;
  postalCode: string;
  city: string;
  state: string;
  country_code: string;
  vat_id: string;
}

const LicencePayment = (props: PropsFromRedux & LicencePaymentProps) => {
  const {
    LicenceCheckOutReducer: { licenceCheckOuts, newPlanLicenceCheckOuts, paymentMethodType },
    isAnnualBillingEnabled,
    isNormalPlan,
    payOnlineStripeAction,
    payOnlineStripeLicenseExpiryAction,
    updatePayOnlineStripeCheckOutAction,
    payOnAccountAction,
    payOnAccountActionLicenseExpiryAction,
    updatePayOnAccountActionCheckOutAction,
    UserReducer: { isLicenseTest },
  } = props;

  const classes = useStyles();
  const billingDetails = isAnnualBillingEnabled ? annualLicensePriceDetails : monthlyLicensePriceDetails;
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [showError, { setTrue: viewError, setFalse: dismissError }] = useBoolean(false);
  const termsAndConditionsUrl =
    'https://uploads-ssl.webflow.com/61b8746e0e120e69f52883cf/626a7e50b4419eab8ac6d266_220501%20aiio%20Allgemeine%20Auftragsbedingungen.pdf';
  const baseURL = window.location.origin;
  const successURL = `${baseURL}/settings?success=true`;
  const [selectedAddress, setSelectedAddress] = React.useState<AutoCompletePlaceAddress | null>(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    formState: { isValid },
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const returnTotalPrice = (): string => {
    let totalPrice: number = 0;

    if (isNormalPlan && isLicenseTest) {
      licenceCheckOuts.forEach((item) => {
        totalPrice += item.cartLicencesCount * billingDetails[item.groupName as keyof typeof billingDetails];
      });
    } else if (isNormalPlan) {
      licenceCheckOuts.forEach((item) => {
        totalPrice +=
          ((item?.totalLicencesCount ?? 0) < 0 ? 0 : item?.totalLicencesCount ?? 0) *
          billingDetails[item.groupName as keyof typeof billingDetails];
      });
    } else {
      newPlanLicenceCheckOuts.forEach((item) => {
        totalPrice += item.cartLicencesCount * billingDetails[item.groupName as keyof typeof billingDetails];
      });
    }

    return totalPrice.toFixed(2);
  };

  const onCheckBoxChange = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setIsChecked(!!checked);
  }, []);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        dismissError();
      }, 3000);
    }
  }, [showError]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const canceledParam = queryParams.get('canceled');

    if (canceledParam == 'true') {
      viewError();
    }
  }, [location]);

  function returnPayloadLineItems() {
    const line_items: {
      price: string;
      quantity: number;
    }[] = [];

    if (isNormalPlan) {
      licenceCheckOuts.forEach((item) => {
        if (item.cartLicencesCount != 0) {
          line_items.push({
            price: item.groupName,
            quantity: item.cartLicencesCount,
          });
        }
      });
    } else {
      newPlanLicenceCheckOuts.forEach((item) => {
        if (item.cartLicencesCount != 0) {
          line_items.push({
            price: item.groupName,
            quantity: item.cartLicencesCount,
          });
        }
      });
    }

    return line_items;
  }

  // Handles the pay online button click and navigates to the stripe checkout page.
  function onPayOnlineButtonClick(customerBillingData?: CustomerBillingDataForm) {
    if (isNormalPlan) {
      const payload = {
        payment_type: isAnnualBillingEnabled ? 'yearly' : 'monthly',
        line_items: returnPayloadLineItems(),
        customer_info: customerBillingData,
      };

      payOnlineStripeAction(
        payload,
        async (result) => {
          const stripe = await loadStripe(result.data.stripePublicKey);

          if (stripe) {
            const { error } = await stripe.redirectToCheckout({
              sessionId: result.data.sessionId,
            });

            if (error) {
              console.error(error);
            }
          }
        },
        (error) => {
          if (error.response.data.error_message) {
            const err = JSON.parse(error.response.data.error_message);

            if (err.code == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED) {
              payOnlineStripeLicenseExpiryAction(
                payload,
                async (result) => {
                  const stripe = await loadStripe(result.data.stripePublicKey);

                  if (stripe) {
                    const { error } = await stripe.redirectToCheckout({
                      sessionId: result.data.sessionId,
                    });

                    if (error) {
                      console.error(error);
                    }
                  }
                },
                (error) => {
                  console.log('error', error);
                  viewError();
                },
              );
            } else {
              viewError();
            }
          } else {
            viewError();
          }
        },
      );
    } else {
      const payload = {
        line_items: returnPayloadLineItems(),
        customer_info: customerBillingData,
      };

      updatePayOnlineStripeCheckOutAction(
        payload,
        async () => {
          window.location.href = successURL;
        },
        (error) => {
          console.log('error', error);
          viewError();
        },
      );
    }
  }

  // handles the pay on account button click and backend sends an email with bank details.
  function onPayOnAccountButtonClick(customerBillingData?: CustomerBillingDataForm) {
    if (isNormalPlan) {
      const payload = {
        payment_type: isAnnualBillingEnabled ? 'yearly' : 'monthly',
        line_items: returnPayloadLineItems(),
        customer_info: customerBillingData,
      };

      payOnAccountAction(
        payload,
        async () => {
          window.location.href = successURL;
        },
        (error) => {
          if (error.response.data.error_message) {
            const err = JSON.parse(error.response.data.error_message);

            if (err.code == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED) {
              payOnAccountActionLicenseExpiryAction(
                payload,
                async () => {
                  window.location.href = successURL;
                },
                (error) => {
                  console.log('error', error);
                  viewError();
                },
              );
            } else {
              viewError();
            }
          } else {
            viewError();
          }
        },
      );
    } else {
      const payload = {
        payment_type: isAnnualBillingEnabled ? 'yearly' : 'monthly',
        line_items: returnPayloadLineItems(),
        customer_info: customerBillingData,
      };

      updatePayOnAccountActionCheckOutAction(
        payload,
        async () => {
          window.location.href = successURL;
        },
        (error) => {
          console.log('error', error);
          viewError();
        },
      );
    }
  }

  function handlePaymentButtonClick(isPayOnline: boolean) {
    if (isLicenseTest) {
      handleSubmit(
        (data) => {
          const customerBillingData: CustomerBillingDataForm = {
            name: data.companyName,
            address_line_1: data.street,
            address_line_2: data.addressLine2,
            postalCode: data.postalCode,
            city: data.city,
            state: data.state,
            country_code: selectedAddress?.countryCode ?? '',
            vat_id: data.vatId,
          };

          if (isPayOnline) {
            onPayOnlineButtonClick(customerBillingData);
          } else {
            onPayOnAccountButtonClick(customerBillingData);
          }
        },
        (err) => {
          console.log('error at handlePaymentButtonClick in license-payments.tsx', err);
        },
      )();
    } else {
      if (isPayOnline) {
        onPayOnlineButtonClick();
      } else {
        onPayOnAccountButtonClick();
      }
    }
  }

  const renderBody = (): JSX.Element => {
    return (
      <>
        {showError && (
          <MessageBar
            delayedRender={false}
            messageBarType={MessageBarType.error}
            styles={{
              root: {
                position: 'absolute',
                top: '10%',
                left: '30%',
                width: 'fitContentToBounds',
              },
            }}
          >
            {
              <div>
                {__('Sadly this didnt work. Please try again. If the problem persists, contact our customer care.')}
              </div>
            }
          </MessageBar>
        )}
        <div className={classes.totalPriceDiv}>
          <span className={classes.totalPriceText1}>{__('Total price')}</span>
          <span className={classes.totalPriceText2}> {returnTotalPrice()} €</span>
        </div>
        {!isNormalPlan || isLicenseTest ? (
          <>
            <div className={classes.termsDiv}>
              <Checkbox checked={isChecked} onChange={onCheckBoxChange} />
              <span>
                {__('I accept the')}{' '}
                <a target="_blank" href={termsAndConditionsUrl} style={{ color: '#0D2FA9' }}>
                  {__('terms and conditions.')}
                </a>
              </span>
            </div>
            {isLicenseTest && isChecked ? (
              <div className={classes.billingdataDiv}>
                <div className={classes.billingDataTextDiv}>{__('Billing data')}</div>
                <BillingData
                  formControl={control}
                  formSetValue={setValue}
                  formResetField={resetField}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                />
              </div>
            ) : null}
            <div className={classes.paymentButtonsDiv}>
              {isLicenseTest || paymentMethodType === 'send_invoice' ? (
                <span className={classes.payOnAccountSpan} style={{ marginRight: isLicenseTest ? '20px' : '0px' }}>
                  <BasicDialog
                    openDialogElement={
                      <Button
                        className={classes.paymentButton}
                        appearance="primary"
                        disabled={
                          !isChecked ||
                          !isValid ||
                          (isNormalPlan
                            ? !licenceCheckOuts.some((obj) => obj.cartLicencesCount != 0)
                            : !newPlanLicenceCheckOuts.some((obj) => obj.cartLicencesCount != 0))
                        }
                        onClick={() => {
                          setIsDialogOpen(true);
                        }}
                        icon={<DocumentBulletListRegular />}
                      >
                        {__('Pay on account')}
                      </Button>
                    }
                    dialogTitle={__('Pay on account')}
                    dialogContent={
                      <div>
                        <p>{__('Click on "Buy now" to set up your aiio subscription.')}</p>
                        <p>
                          {__(
                            'You will then receive your invoices as a PDF by e-mail based on the billing cycle you chose.',
                          )}
                        </p>
                      </div>
                    }
                    dialogActionsContent={
                      <>
                        <DialogTrigger>
                          <Button
                            appearance="primary"
                            onClick={() => {
                              setIsDialogOpen(false);
                              handlePaymentButtonClick(false);
                            }}
                          >
                            {__('Buy now')}
                          </Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                          <Button
                            appearance="secondary"
                            onClick={() => {
                              setIsDialogOpen(false);
                            }}
                          >
                            {__('cancel')}
                          </Button>
                        </DialogTrigger>
                      </>
                    }
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                  />
                </span>
              ) : null}
              {isLicenseTest || paymentMethodType != 'send_invoice' ? (
                <span className={classes.payOnlineSpan}>
                  <Button
                    className={classes.paymentButton}
                    appearance="primary"
                    disabled={
                      !isChecked ||
                      !isValid ||
                      (isNormalPlan
                        ? !licenceCheckOuts.some((obj) => obj.cartLicencesCount != 0)
                        : !newPlanLicenceCheckOuts.some((obj) => obj.cartLicencesCount != 0))
                    }
                    onClick={() => handlePaymentButtonClick(true)}
                    icon={<PaymentRegular />}
                  >
                    {__('Pay online')}
                  </Button>
                </span>
              ) : null}
            </div>
          </>
        ) : null}
      </>
    );
  };

  return renderBody();
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ LicenceCheckOutReducer, UserReducer }: RootState) => ({
  LicenceCheckOutReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  payOnlineStripeAction,
  payOnlineStripeLicenseExpiryAction,
  updatePayOnlineStripeCheckOutAction,
  payOnAccountAction,
  payOnAccountActionLicenseExpiryAction,
  updatePayOnAccountActionCheckOutAction,
});
export default connector(LicencePayment);
