import { Department, Role } from '../../ts/interfaces';
import { __ } from 'modeling-tool';
import { IColumn } from '@fluentui/react';

export const extractNumericIdFromUrl = (url: string | undefined) => {
  const parts = url?.split('/');
  const numericId = parts?.[parts.length - 2];
  return numericId ? parseInt(numericId, 10) : undefined;
};

export const mapUriListToIdList = (uriList?: string[]): number[] => {
  const resultList: number[] = [];
  uriList?.forEach((uri: string) => {
    const regex = /\/api\/v1\/.*\/(\d+)\//;
    const matches = uri.match(regex);
    if (matches) {
      resultList.push(parseInt(matches[1]));
    }
  });
  return resultList;
};

export const mapRaciFields = (process: any) => {
  let { accountable, consulted, informed, responsible } = process;

  // here a RACIField is overwritten bei {departments:string[]; roles:string[]; employees[string]}. Should be improved to be more readable
  const fields = [accountable, consulted, informed, responsible];
  [accountable, consulted, informed, responsible] = fields.map((field) => {
    if (field) {
      return {
        departments: field.departments && field.departments.map((department: Department) => department.resourceUri),
        roles: field.roles && field.roles.map((role: Role) => role.resourceUri),
        employees: field.employees,
      };
    } else {
      return null;
    }
  });
  return [accountable, consulted, informed, responsible];
};

export const updateColumnsWithLanguage = (cols: IColumn[]) => {
  return cols.map((col: IColumn) => ({
    ...col,
    name: __(col.name),
  }));
};

export const onRenderQuery = (params: any) => {
  let query = '?';
  for (const index in params) {
    for (const key in params[index]) {
      const obj = params[index];
      const value = obj[key];
      query += `${key}=${value}&`;
    }
  }
  return query;
};