import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dropdown } from '../..';
import { Modal, PeoplePicker, DropdownWithSearch, GLOBALENUMS, toCamel, TextField } from 'modeling-tool';
import { RootState } from '../../../config/store';
import { getRACIFieldsObject } from '../../../config/utils';
import { __ } from 'modeling-tool';
import { updateProcessInstanceAction } from '../../../store/actions/process-instance-actions';
import { Department, ProcessInstance, ProcessInstanceFormValues, Role, WorkingGroup } from '../../../ts/interfaces';
import { useForm } from 'react-hook-form';
import { IDropdownOption, Stack, Icon, Dropdown as FluentDropDown } from '@fluentui/react';
import { getStyles } from './approved-by-modal-styles';
import { useAlert } from 'react-alert';
import { setNewProcessInstanceStateAction } from '../../../store/actions/process-state-actions';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';
import { useNavigate } from 'react-router';

enum OptionsKey {
  PERSON = 'person',
  ROLE = 'role',
  DEPARTMENT = 'department',
}

interface ApprovedByModalProps extends PropsFromRedux {
  selectedInstance: ProcessInstance;
  isApprovedByModalVisible: boolean;
  instanceFormValues?: ProcessInstanceFormValues;
  handleApprovedByCancel(): void;
}

export interface ApprovedByFormValues {
  approvedBy: string;
  userMajority: string;
  approvedByDepartments?: string[];
  departmentMajority: string;
  approvedByRoles?: string[];
  roleMajority: string;
  approvedByWorkingGroups?: string[];
  workingGroupMajority: string;
}

const dropDownData = [GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, GLOBALENUMS.DROPDOWNDATA.ROLES];

const ApprovedByModal = (props: ApprovedByModalProps) => {
  const {
    DropdownDataReducer: { orgUnits, roles },
    selectedInstance,
    isApprovedByModalVisible,
    instanceFormValues,
    setNewProcessInstanceStateAction,
    fetchDropdownDataByType,
    handleApprovedByCancel,
  } = props;

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const navigate = useNavigate();
  const styles = getStyles();
  const alert = useAlert();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<OptionsKey>(OptionsKey.PERSON);

  useEffect(() => {
    if (isApprovedByModalVisible) {
      fetchDropdownDataByType(dropDownData);
      const { sourceProcess } = selectedInstance;
      const values = {
        approvedBy: (sourceProcess?.approvedBy && sourceProcess?.approvedBy.employees) || null,
        approvedByDepartments:
          (sourceProcess?.approvedBy &&
            sourceProcess.approvedBy.departments?.map((item: Department) => item.resourceUri)) ||
          [],
        approvedByRoles:
          (sourceProcess?.approvedBy && sourceProcess.approvedBy.roles?.map((item: Role) => item.resourceUri)) || [],
        approvedByWorkingGroups:
          (sourceProcess?.approvedBy &&
            sourceProcess.approvedBy.workingGroups?.map((item: WorkingGroup) => item.resourceUri)) ||
          [],
      };
      reset(values);
    }
  }, [isApprovedByModalVisible]);

  const handleApprovedByOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onApprove(data as ApprovedByFormValues);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleCancelApprovedBy = () => {
    handleApprovedByCancel();
    setSelectedOption(OptionsKey.PERSON);
  };

  const approvalSuccess = () => {
    alert.success(__('process instance was approved successfully!'));
    navigate('/process-management');
  };

  const onApprove = (values: ApprovedByFormValues) => {
    let instanceValues = {};
    if (selectedInstance.state.type !== GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_MODELING && instanceFormValues) {
      const raciValues = getRACIFieldsObject(instanceFormValues, selectedInstance);
      instanceValues = { ...instanceFormValues, ...raciValues };
    }
    const approvableInstance = { ...selectedInstance, ...instanceValues, requestApprovalFormData: values };
    switch (selectedInstance.state.type) {
      case GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_MODELING: {
        setNewProcessInstanceStateAction(
          approvableInstance,
          GLOBALENUMS.PROCESSSTATUS.INSTANCE_MODELING_IN_APPROVAL,
          () => {
            approvalSuccess();
          },
        );
        break;
      }
      case GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED: {
        setNewProcessInstanceStateAction(
          approvableInstance,
          GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_APPROVAL,
          () => {
            approvalSuccess();
          },
        );
        break;
      }
      default:
    }
  };

  const renderMajoritySelector = () => {
    return [
      { key: `${GLOBALENUMS.MAJORITY.SIMPLE}`, text: __('simple majority') } as IDropdownOption,
      { key: `${GLOBALENUMS.MAJORITY.ABSOLUTE}`, text: __('total consent') } as IDropdownOption,
      { key: `${GLOBALENUMS.MAJORITY.SINGLE}`, text: __('single consent') } as IDropdownOption,
    ];
  };

  const renderDepartments = () => {
    return orgUnits.map(
      (department: Department) => ({ key: department.resourceUri, text: department.departmentName }) as IDropdownOption,
    );
  };

  const renderRoles = () => {
    return roles.map((role: Role) => ({ key: role.resourceUri, text: role.roleName }) as IDropdownOption);
  };

  const dropdownOptions: IDropdownOption[] = [
    { key: `${OptionsKey.PERSON}`, text: __('person') },
    { key: `${OptionsKey.ROLE}`, text: __('role') },
    { key: `${OptionsKey.DEPARTMENT}`, text: __('department') },
  ];

  const renderSelectArea = (options: () => IDropdownOption<any>[], field: string, isUser: boolean) => {
    field = field.charAt(0).toUpperCase() + field.slice(1);
    return (
      <>
        <div className={styles.approversRowDiv}>
          <div className={styles.inputFieldDiv}>
            {!isUser && (
              <DropdownWithSearch
                options={options()}
                control={control}
                name={`approvedBy${toCamel(field)}s`}
                placeholder={__(`choose ${field}`)}
                multiSelect
              />
            )}
            {isUser && <PeoplePicker selectionMode="multiple" control={control} name={'approvedBy'} />}
          </div>
        </div>
      </>
    );
  };

  const renderDropdown = () => {
    switch (selectedOption) {
      case OptionsKey.PERSON:
        return renderSelectArea(renderDepartments, GLOBALENUMS.ORGANIZATIONENTITY.EMPLOYEE.toLowerCase(), true);
      case OptionsKey.DEPARTMENT:
        return renderSelectArea(renderDepartments, GLOBALENUMS.ORGANIZATIONENTITY.DEPARTMENT.toLowerCase(), false);
      case OptionsKey.ROLE:
        return renderSelectArea(renderRoles, GLOBALENUMS.ORGANIZATIONENTITY.ROLE.toLowerCase(), false);
      default:
        return null;
    }
  };

  return (
    <>
      {selectedInstance && (
        <>
          <form>
            <Modal
              title={__('process approval')}
              isModalOpen={isApprovedByModalVisible}
              onSave={handleApprovedByOk}
              onCancel={handleCancelApprovedBy}
              enableProgress={showLoading}
            >
              <>
                <TextField
                  name={'changesMade'}
                  label={__('changes made')}
                  control={control}
                  multiline
                  rows={5}
                ></TextField>
                <div className={styles.approversRowDiv}>
                  <div className={styles.dropdownDiv1}>
                    <div style={{ paddingBottom: '4px' }}>{__('who should approve the process')}</div>
                    <FluentDropDown
                      options={dropdownOptions}
                      onChange={(event, item) => setSelectedOption(item?.key as OptionsKey)}
                      defaultSelectedKey={selectedOption}
                      className={styles.dropDownStyles}
                    />
                  </div>
                  <div className={styles.dropdownDiv2}>
                    <div style={{ paddingBottom: '4px' }}>{__('how should the process be approved')}</div>
                    <Dropdown
                      options={renderMajoritySelector()}
                      control={control}
                      name={'majority'}
                      defaultValue={`${GLOBALENUMS.MAJORITY.SIMPLE}`}
                      className={styles.dropDownStyles}
                    />
                  </div>
                </div>
                <div className={styles.inputFieldDiv}>{renderDropdown()}</div>
                <Stack horizontal tokens={{ childrenGap: 10, padding: 5 }}>
                  <Stack.Item>
                    <Icon iconName="info" />
                  </Stack.Item>
                  <Stack.Item className={styles.notifiMsg}>
                    {__('if you do not select approvers, you automatically approve the process')}
                  </Stack.Item>
                </Stack>
              </>
            </Modal>
          </form>
        </>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, DropdownDataReducer }: RootState) => ({
  UserReducer,
  DropdownDataReducer,
});
const connector = connect(mapStateToProps, {
  updateProcessInstanceAction,
  setNewProcessInstanceStateAction,
  fetchDropdownDataByType,
});
export default connector(ApprovedByModal);
