import React, { useState, useEffect } from 'react';
import { Process, Department, Role, WorkingGroup, ProcessReminder } from '../../../../ts/interfaces';
import { __ } from 'modeling-tool';
import { useForm } from 'react-hook-form';
import {
  Label,
  Stack,
  IDropdownOption,
  defaultDatePickerStrings,
  DefaultButton,
  PrimaryButton,
  IStackTokens,
  Checkbox,
} from '@fluentui/react';
import { DatePicker, TimePicker } from '../../../../components';
import { Modal, DropdownWithSearch, PeoplePicker, Popover, GLOBALENUMS } from 'modeling-tool';
import moment from 'moment';
import { getUtcDate } from '../../../../config/utils';
import { RootState } from '../../../../config/store';
import { connect, ConnectedProps } from 'react-redux';
import { fetchDropdownDataByType } from '../../../../store/actions/dropdown-data-actions';
import { selectProcessAction } from '../../../../store/actions/process-actions';
import {
  addProcessReminderAction,
  updateProcessReminderAction,
  selectProcessReminderAction,
  fetchProcessReminderAction,
  deleteProcessReminderAction,
} from '../../../../store/actions/process-reminder-actions';

interface IProcessReminderForm {
  visible: boolean;
  setVisible: (arg: boolean) => void;
  selectedProcess?: Process;
  selectedReminder?: ProcessReminder;
}
const ProcessReminderForm = (props: PropsFromRedux & IProcessReminderForm) => {
  const {
    DropdownDataReducer: { orgUnits, roles, workingGroups },
    visible,
    setVisible,
    selectedProcess,
    selectedReminder,
    fetchDropdownDataByType,
    addProcessReminderAction,
    updateProcessReminderAction,
    selectProcessAction,
    selectProcessReminderAction,
    fetchProcessReminderAction,
    deleteProcessReminderAction,
  } = props;

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>();

  const dropDownData = [
    GLOBALENUMS.DROPDOWNDATA.ORG_UNITS,
    GLOBALENUMS.DROPDOWNDATA.ROLES,
    GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS,
  ];

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
  }, []);

  const buttonStackTokens: IStackTokens = { childrenGap: 20 };

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    if (selectedReminder && selectedReminder.id) {
      reset();
      const data = {
        ...selectedReminder,
        startDate: moment.utc(selectedReminder.startDate, 'YYYY-MM-DD').local().toDate(),
        deadline: moment.utc(selectedReminder.deadline, 'YYYY-MM-DD').local().toDate(),
        startDateTime: moment.utc(selectedReminder.startDate, 'YYYY-MM-DD HH:mm').local().toDate(),
        deadlineTime: moment.utc(selectedReminder.deadline, 'YYYY-MM-DD HH:mm').local().toDate(),
        department:
          (selectedReminder.department && selectedReminder.department.map((item: Department) => item.resourceUri)) ||
          null,
        workingGroup:
          (selectedReminder.workingGroup &&
            selectedReminder.workingGroup.map((item: WorkingGroup) => item.resourceUri)) ||
          null,
        role: (selectedReminder.role && selectedReminder.role.map((item: Role) => item.resourceUri)) || null,
      };
      selectedReminder.reminderMe && setIsChecked(selectedReminder.reminderMe);
      reset(data);
      setFormValues(data);
      setVisible(true);
    } else {
      reset({ startDateTime: moment('1:00', 'HH:mm').toDate(), deadlineTime: moment('1:00', 'HH:mm').toDate() });
    }
  }, [selectedReminder]);

  const handleReminderOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleReminderCancel = () => {
    setVisible(false);
    reset();
    selectProcessAction(false);
    selectProcessReminderAction(false);
    setIsChecked(false);
  };

  const onFinish = (values: any) => {
    if (selectedProcess && selectedProcess.id) {
      const { resourceUri } = selectedProcess;
      let startDate = '';
      let deadline = '';
      if (values['startDate']) {
        startDate = `${moment(values['startDate']).startOf('day').format('YYYY-MM-DD')} ${moment(
          values['startDateTime'],
        ).format('HH:mm')}`;
        delete values['startDateTime'];
      }
      if (values['deadline']) {
        deadline = `${moment(values['deadline']).startOf('day').format('YYYY-MM-DD')} ${moment(
          values['deadlineTime'],
        ).format('HH:mm')}`;
        delete values['deadlineTime'];
      }
      const formValues = {
        ...values,
        reminderMe: selectedOption,
        process: resourceUri,
      };
      setFormValues(formValues);
      if (selectedReminder && selectedReminder.id) {
        values = {
          ...values,
          startDate: getUtcDate(moment(startDate).toDate()),
          deadline: getUtcDate(moment(deadline).toDate()),
          reminderMe: selectedOption,
          process: resourceUri,
          id: selectedReminder.id,
        };
        updateProcessReminderAction(values, () => {
          setShowLoading(false);
          setVisible(false);
          reset();
          selectProcessAction(false);
          selectProcessReminderAction(false);
          setIsChecked(false);
          fetchProcessReminderAction();
        });
      } else {
        values = {
          ...values,
          startDate: getUtcDate(moment(startDate).toDate()),
          deadline: getUtcDate(moment(deadline).toDate()),
          reminderMe: selectedOption,
          process: resourceUri,
        };
        addProcessReminderAction(values, (response: any) => {
          values = {
            id: response.data.id,
            ...values,
            startDate: getUtcDate(moment(startDate).toDate()),
            deadline: getUtcDate(moment(deadline).toDate()),
            reminderMe: selectedOption,
            process: resourceUri,
          };
          updateProcessReminderAction(values, () => {
            setShowLoading(false);
            setVisible(false);
            reset();
            selectProcessAction(false);
            selectProcessReminderAction(false);
            setIsChecked(false);
            fetchProcessReminderAction();
          });
        });
      }
    }
  };

  const onChange = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setIsChecked(!!checked);
    checked ? setSelectedOption(true) : setSelectedOption(false);
  }, []);

  const getDepartmentDropDownOptions = () => {
    const options = orgUnits.map((department: Department) => {
      return { key: department.resourceUri, text: department.departmentName } as IDropdownOption;
    });
    return options;
  };

  const getRolesDropDownOptions = () => {
    const options = roles.map((role: Role) => {
      return { key: role.resourceUri, text: role.roleName } as IDropdownOption;
    });
    return options;
  };

  const getWorkingGroupsDropDownOptions = () => {
    const options = workingGroups.map((workinggroup: WorkingGroup) => {
      return { key: workinggroup.resourceUri, text: workinggroup.title } as IDropdownOption;
    });
    return options;
  };
  const handleDelete = () => {
    if (selectedReminder && selectedReminder.id) {
      deleteProcessReminderAction(selectedReminder.id, () => {
        setShowLoading(false);
        setVisible(false);
        selectProcessAction(false);
        selectProcessReminderAction(false);
        setIsChecked(false);
        fetchProcessReminderAction();
      });
    }
  };
  const renderModalFooter = () => {
    const text = __('are you sure you want delete this reminder?');
    const btns = [];
    btns.push(
      <Stack.Item>
        <Popover
          title={__('delete process reminder')}
          content={text}
          target={'delete-reminder'}
          onOk={() => handleDelete()}
          enableConfirm={true}
        >
          <DefaultButton id="delete-reminder" text={__('delete')} />
        </Popover>
      </Stack.Item>,
    );
    btns.push(
      <Stack.Item>
        <DefaultButton onClick={handleReminderCancel} text={__('cancel')} />
      </Stack.Item>,
    );
    btns.push(
      <Stack.Item>
        <PrimaryButton onClick={handleReminderOk} text={__('set')} />
      </Stack.Item>,
    );
    return (
      <Stack horizontal tokens={buttonStackTokens}>
        {btns}
      </Stack>
    );
  };
  return (
    <>
      <Modal
        title={__('process reminder')}
        isModalOpen={visible}
        onSave={handleReminderOk}
        onCancel={handleReminderCancel}
        enableProgress={showLoading}
        customFooter={renderModalFooter()}
        hideFooter={true}
      >
        <form>
          <Label required>{__('Start Date')}</Label>
          <Stack horizontal>
            <Stack.Item style={{ width: '50%' }}>
              <DatePicker
                placeholder={__('Select a date')}
                ariaLabel={'start date'}
                strings={defaultDatePickerStrings}
                name="startDate"
                control={control}
                isRequired={true}
                rules={{ required: __('This field is required') }}
              />
            </Stack.Item>
            <Stack.Item style={{ width: '50%' }}>
              <TimePicker name="startDateTime" control={control} required={true}></TimePicker>
            </Stack.Item>
          </Stack>
          <Label required>{__('Deadline')}</Label>
          <Stack horizontal>
            <Stack.Item style={{ width: '50%' }}>
              <DatePicker
                placeholder={__('Select a date')}
                ariaLabel={'deadline'}
                strings={defaultDatePickerStrings}
                name="deadline"
                control={control}
                isRequired={true}
                rules={{ required: __('This field is required') }}
              />
            </Stack.Item>
            <Stack.Item style={{ width: '50%' }}>
              <TimePicker name="deadlineTime" control={control} required={true}></TimePicker>
            </Stack.Item>
          </Stack>
          <Label>{__('Sent Reminder to')}</Label>
          <PeoplePicker selectionMode="multiple" control={control} name={'employees'} />
          <Label>{'- ' + __('or') + ' -'}</Label>
          <DropdownWithSearch
            options={getDepartmentDropDownOptions()}
            label={__('departments')}
            control={control}
            name={'department'}
            placeholder={__('enter department')}
            multiSelect
          />
          <Label>{'- ' + __('or') + ' -'}</Label>
          <DropdownWithSearch
            options={getRolesDropDownOptions()}
            control={control}
            name={'role'}
            placeholder={__('enter role')}
            multiSelect
          />
          <Label>{'- ' + __('or') + ' -'}</Label>
          <DropdownWithSearch
            options={getWorkingGroupsDropDownOptions()}
            control={control}
            name={'workingGroup'}
            placeholder={__('enter working group')}
            multiSelect
          />
          <Label>{__('')}</Label>
          <Checkbox label={__('send me a reminder too')} checked={isChecked} onChange={onChange} />
        </form>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ DropdownDataReducer }: RootState) => ({
  DropdownDataReducer,
});

const connector = connect(mapStateToProps, {
  fetchDropdownDataByType,
  addProcessReminderAction,
  updateProcessReminderAction,
  selectProcessAction,
  selectProcessReminderAction,
  fetchProcessReminderAction,
  deleteProcessReminderAction,
});

export default connector(ProcessReminderForm);
